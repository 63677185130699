import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {QuestionnaireComponent} from './questionnaire/questionnaire/questionnaire.component';
import {ResultsComponent} from './results/results/results.component';
const appRoutes: Routes = [
  {
    path: '',
    component: QuestionnaireComponent,
  },
  {
    path: 'results',
    component: ResultsComponent,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
