import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Chart } from 'chart.js';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-win-loss-perception',
  templateUrl: './win-loss-perception.component.html',
  styleUrls: ['./win-loss-perception.component.scss']
})
export class WinLossPerceptionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() lambda: number;
  @Input() sigma: number;
  @Input() averageLambda = -2.2;
  @Input() averageSigma = 0.8;
  @Input() labels: any;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @ViewChild('lineChart', { static: true }) private chartRef;
  chart: any;
  private $destroy: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  ngOnInit() {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.chart) {
      return;
    }
    if ('labels' in changes) {
      this.updateChartLabels(changes.labels.currentValue);
    }
  }

  private load() {
    this.chart = this.initChart(
      this.colors,
      this.labels
    );
  }

  private updateChartLabels(labels: any) {
    this.chart.data.datasets[0].label = labels.headers.value_function;
    this.chart.data.datasets[1].label = labels.headers.mean_value_function;
    this.chart.data.datasets[2].label = labels.headers.x_equals_y;

    this.chart.options.scales.xAxes[0].scaleLabel.labelString =
      labels.axes.investissement_value;
    this.chart.options.scales.yAxes[0].scaleLabel.labelString =
      labels.axes.perception_investissement_value;

    this.chart.update();
  }

  private initChart(colors: any, labels: any) {
    const data = this.computeChartValues();
    const headers = [
      labels.headers.value_function,
      labels.headers.mean_value_function,
      labels.headers.x_equals_y,
    ];
    const axes = [
      labels.axes.investissement_value,
      labels.axes.perception_investissement_value,
    ];
    const textColor = colors.primaryText;

    return new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        labels: data.xAxis.map(el => el.toString()),
        datasets: [
          {
            data: data.yAxis.user,
            label: headers[0],
            borderColor: '#a1004e',
            backgroundColor: '#a1004e',
            fill: false,
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            data: data.yAxis.average,
            label: headers[1],
            borderColor: '#6c3477',
            backgroundColor: '#6c3477',
            fill: false,
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            data: data.yAxis.linear,
            label: headers[2],
            fill: false,
            borderWidth: 2,
            pointRadius: 0,
            borderColor: '#999',
            borderDash: [5, 5]
          },
        ],
      },
      options: {
        legend: {
          display: true,
          position: 'top'
        },
        tooltips: {
          enabled: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: textColor,
                minRotation: 0,
                maxRotation: 0,
              },
              gridLines: {
                lineWidth: [4, 1, 1, 1, 1, 2],
                color: [textColor],
              },
              scaleLabel: {
                display: true,
                labelString: axes[0],
                fontColor: textColor,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: textColor,
              },
              scaleLabel: {
                display: true,
                labelString: axes[1],
                fontColor: textColor,
              },
            },
          ],
        },
        responsive: true,
      },
    });
  }

  computeChartValues() {
    const xAxis = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];
    const yAxis = { user: [], average: [], linear: [] };
    for (const x of xAxis) {
      let yValue = { user: 0, average: 0, linear: 0 };
      if (x < 0) {
        yValue = {
          user: -this.lambda * Math.pow(-x, this.sigma),
          average: this.averageLambda * Math.pow(-x, this.averageSigma),
          linear: x,
        };
      } else if (x > 0) {
        yValue = {
          user: Math.pow(x, this.sigma),
          average: Math.pow(x, this.averageSigma),
          linear: x,
        };
      }

      yAxis.user.push(yValue.user);
      yAxis.average.push(yValue.average);
      yAxis.linear.push(yValue.linear);
    }
    return {
      xAxis,
      yAxis,
    };
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.unsubscribe();
  }

}
