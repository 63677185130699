import { HttpParams } from '@angular/common/http';
import ppPreviousResults from '../assets/static/prefill_mock/pp.json';

export class SessionInfo {

  public static clientId: string = '';
  public static startingTime: string = '';
  public static endingTime: string = '';
  public static language: string = '';
  public static country: string = '';
  public static cesoType: string = '';
  public static sessionType: string = '';
  public static luxtrustId: string = '';
  public static luxtrustBankerId: string = '';
  public static currency: string='';
  public static currencySymbol: string='';
  public static debug: boolean = false;
  public static previousResults: Map<string, string> = null;

  public static Start() {
    SessionInfo.clientId = SessionInfo.getParameterInUrl('clientId');
    SessionInfo.language = SessionInfo.getParameterInUrl('lang');
    SessionInfo.country = SessionInfo.getParameterInUrl('country');
    SessionInfo.cesoType = SessionInfo.getParameterInUrl('cesoType');
    SessionInfo.sessionType = SessionInfo.getParameterInUrl('sessionType');
    SessionInfo.luxtrustId = SessionInfo.getParameterInUrl('ltId');
    SessionInfo.luxtrustBankerId = SessionInfo.getParameterInUrl('ltBankerId');
    SessionInfo.debug = SessionInfo.getParameterInUrl('debug') === "true";
    SessionInfo.startingTime = new Date().toLocaleString('fr-FR');
    SessionInfo.currency = SessionInfo.country == "ch" ? "CHF" : "EUR";
    SessionInfo.currencySymbol = SessionInfo.country == "ch" ? "CHF" : "€";

    if(SessionInfo.debug) {
      SessionInfo.previousResults = new Map(Object.entries(ppPreviousResults));
    } 
  }

  public static Snapshot() {
    return {
      'url':  window.location.href,
      'clientId': SessionInfo.clientId,
      'cesoType': SessionInfo.cesoType,
      'startDateTime': SessionInfo.startingTime,
      'luxtrustId': SessionInfo.luxtrustId,
      'luxtrustBankerId': SessionInfo.luxtrustBankerId,
      'SnapshotDateTime': new Date().toLocaleString('fr-FR')
    };
  }

  private static getParameterInUrl(paramName: string) {
    const url = window.location.href;
    let paramValue = '';
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
      return paramValue;
    }
    return 'unknown';
  }

}

