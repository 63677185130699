import { Answer } from '../answers/answer';
import { Question } from "../questions/question";
import { QuestionnaireItem } from './questionnaire-item';
import { SingleValueAnswer } from '../answers/single-value-answer';

export class QuestionnaireHistory {

	public itemsHistory : QuestionnaireItem[] = [];

	public constructor(){
	}

	public AddItem(item: QuestionnaireItem) {
		this.itemsHistory.push(item);
	}

	public Length() {
		return this.itemsHistory.length;
	}

	public Pop() {
		return this.itemsHistory.pop();
	}

  public Last() {
    return this.itemsHistory[this.itemsHistory.length - 1];
  }

  public CanFindAnswer(answerId: string) {
    return (this.FindAnswer(answerId) !== undefined);
  }

  public FindQuestionnaireItemById(id: string) {
    let relevantItem = this.itemsHistory.find(item => item.questionnaireItemId === id);
    return relevantItem;
  }

  public FindQuestionnaireItemByKind(kind: string) {
    let relevantItem = this.itemsHistory.filter(item => item.questions[0].kind === kind);
    return relevantItem;
  }

	public FindValueForAnswer(answerIdOrValue : string | number) : number {
		// lacking of a better solution for now
    if (typeof answerIdOrValue === "number") {
      return answerIdOrValue as number;
    } else {
		const relevantAnswer = <SingleValueAnswer> this.FindAnswer(answerIdOrValue as string);
		return relevantAnswer ? relevantAnswer.value : 0;
    }
  }

	public FindAnswer(answerId: string) : Answer {
		const nestedAnswers = this.itemsHistory.map(item => item.answers);
		const answers = [].concat(...nestedAnswers);
		return answers.find(answer => answer.id == answerId);
	}

  public FindQuestionAnswerWithNextId(nextId: string) {
    return this.FlattenedQuestionAnswer().find(qa =>
      this._findMatchingId(qa[0], qa[1]) === nextId);
  }

  public FindQuestionAnswerWithAnswerId(answerId: string) {
    return this.FlattenedQuestionAnswer().find(qa =>
      qa[1].id === answerId);
  }

  public FlattenedQuestionAnswer() : [Question, Answer][] {
		const nestedAnswers = this.itemsHistory.map(item => item.answers);
		const answers = [].concat(...nestedAnswers);

		const nestedQuestions = this.itemsHistory.map(item => item.questions);
		const questions = [].concat(...nestedQuestions);

    return this._zipQuestionAnswer(questions, answers);
  }

  private _findMatchingId(question : Question, answer : Answer) {
    let res = question.getNextQuestionIds(answer)[0];
    return res;

  }

  private _zipQuestionAnswer(questions:Question[],answers:Answer[]) : [Question, Answer][]{
    return questions.map((q,i) => [q,answers[i]]);
  }


}
