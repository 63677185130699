import { Questionnaire } from './questionnaire';
import { QuestionnaireHistory } from './questionnaire-history';
import { SessionInfo } from "../session-info";

import { QuestionnaireResults } from "../results/questionnaire-results";
import { QuestionnaireItem } from "./questionnaire-item";
import { QuestionnaireBuilder } from "./questionnaire-builder";
import { Injectable } from "@angular/core";


@Injectable()
export class QuestionnaireService {
  private readonly questionnaireJson: any;
  public questionnaire: Questionnaire;
  public history: QuestionnaireHistory = new QuestionnaireHistory();
  constructor() {

    this.questionnaireJson = new QuestionnaireBuilder().build();
    this.questionnaire = new Questionnaire(this.questionnaireJson);
  }

  replayHistory() {
    const newQuestionnaire = new Questionnaire(this.questionnaireJson);
    const newQuestionnaireHistory = new QuestionnaireHistory();
    newQuestionnaireHistory.AddItem(newQuestionnaire.currentItem);

    this.history.itemsHistory.slice(0, -1).forEach((questionnaireItem) => {
      newQuestionnaire.nextItem(
        questionnaireItem.answers,
        this.history
      );
      newQuestionnaireHistory.AddItem(newQuestionnaire.currentItem);
    });

    const currentItem = this.history.Pop();
    newQuestionnaire.currentItem.answers = currentItem.answers;

    this.questionnaire = newQuestionnaire;
    this.history.AddItem(currentItem);
  }


  buildQuestionnaireResults() {
    return new QuestionnaireResults(this.history);
  }

}
