<div>
  <ui-title
    class="d-block text-center mb-5 mt-2 pt-2"
    [render]="'title'"
    [size]="'large'"
    [text]="title"
    [color]="colors?.primaryText"
  >
  </ui-title>
    <!--
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="line_chart">
        <canvas width="90%" height="100%" #lineChart>
          {{ chart }}
        </canvas>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <aside>
        <ui-text
          class="d-block mb-5"
          [text]="labels?.chart_description | translate"
          [render]="'text'"
          [color]="colors?.primaryText">
        </ui-text>
      </aside>
    </div>
  </div>
    -->
</div>
