import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-expandable-summary-with-icon',
  templateUrl: './expandable-summary-with-icon.component.html',
  styleUrls: ['./expandable-summary-with-icon.component.scss']
})
export class ExpandableSummaryWithIconComponent implements OnInit {
  @Input() name: string;
  @Input() imagePath?: string;
  @Input() detailsText: string;
  @Input() collapsable = false;
  @Input() collapsed = false;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  constructor() { }

  ngOnInit() {
  }

  get assetsUrl() {
    return this.configuration.get('assets-url');
  }
}
