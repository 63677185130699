import { IProgressBar } from 'neuroprofiler-ui-kit';

export class ProgressBar implements IProgressBar {

  public constructor(
    private sectionKey: string,
    private sectionKeyIndex: number,
    private percentageInSection: number,
    public sectionKeys: string[],
    public inactivesPictures : string[],
    public activePictures : string[],
    public completedPictures : string[],
    public textKeys: string[],
    public maxNQuestions: number
  ){}

  public Update(sectionKey: string, sectionPosition: number, remainingItems: number){
    this.sectionKey = sectionKey;
    this.sectionKeyIndex = this.sectionKeys.indexOf(sectionKey);
    this.percentageInSection = sectionPosition / (sectionPosition + remainingItems);
  }


  public GetCurrentPictures() {
    const nSections = this.sectionKeys.length;
    let res = new Array<string>(nSections);
    for(var i = 0; i < nSections; i++) {
      if (i < this.sectionKeyIndex) {
        res[i] = this.completedPictures[i];
      } else if (i === this.sectionKeyIndex) {
        res[i] = this.activePictures[i];
      } else {
        res[i] = this.inactivesPictures[i];
      }
    }
    return res;
  }

  public GetCurrentSectionIndex() {
    return this.sectionKeyIndex;
  }

  public GetCurrentTextKey() {
    return this.textKeys[this.sectionKeyIndex];
  }

  public GetPercentageInSection() {
    return this.percentageInSection;
  }

}
