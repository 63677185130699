import { ISingleOptionAnswer } from 'neuroprofiler-ui-kit';

export class SingleOptionAnswer implements ISingleOptionAnswer {

	public constructor(
		public id: string,
		public kind: string,
    public optionId: string,
    public skipped: boolean){}

}
