import { Component, OnInit, Input } from '@angular/core';
import { RiskQuestion } from '../risk-question';
import { SingleOptionAnswer } from '../../answers/single-option-answer';
import { RiskOption } from "../../options/risk-option";

@Component({
  selector: 'app-risk-question',
  templateUrl: './risk-question.component.html',
  styleUrls: ['./risk-question.component.scss']
})
export class RiskQuestionComponent implements OnInit {

	@Input() question: RiskQuestion;
	@Input() answer: SingleOptionAnswer;
  constructor() { }

  ngOnInit() {
  }

	updateAnswer(option : RiskOption) { 
		this.answer.optionId = option.optionId;
	}

	isOptionSelected(option : RiskOption) {
		return (option.optionId === this.answer.optionId);
	}
}
