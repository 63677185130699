import { IIndoScoreCalculator } from "./i-indo-score-calculator";
import { QuestionnaireHistory } from "../questionnaire/questionnaire-history";
import { RiskQuestion } from "../questions/risk-question";
import { SingleOptionAnswer } from "../answers/single-option-answer";
import { SingleValueAnswer } from "../answers/single-value-answer";
import { WealthCompositionIndoAnswer } from "../answers/wealth-composition-indo-answer";
import { IndoScoreCalculator } from "./indo-score-calculator";

export class IndoScoreCalculatorMP extends IndoScoreCalculator implements IIndoScoreCalculator {

  private _balanceSheetScoreContribution: number;
  private _percentageFinancialAssetsScoreContribution: number;
  private _percentageTrustedWealthScoreContribution: number;
  private _debtRatioScoreContribution: number;
  private _financialObjectiveScoreContribution: number;
  private _investmentHorizonScoreContribution: number; 
  private _lossReactionScoreContribution: number;
  private _liquidAssetPartScoreContribution: number;
  private _lossAversionScoreContribution: number;

  constructor(private _questionnaireHistory: QuestionnaireHistory){
    super();

    this._evaluateBalanceSheetContribution();
    this._evaluatePercentageFinancialAssetsContribution();
    this._evaluatePercentageTrustedWealthContribution();
    this._evaluateDebtRatioContribution();
    this._evaluateFinancialObjectiveContribution();
    this._evaluateInvestmentHorizonContribution();
    this._evaluateLossReactionContribution();
    this._evaluateLiquidAssetPartContribution();
    this._evaluateLossAversionContribution();

    this._evaluateScore();
    this._evaluateRiskProfile();
  }


  public get lossAversionScoreContribution() {
    return this._lossAversionScoreContribution;
  }
 
  
  public getScoreContributionsAsJSON() {

    return {
      balanceScoreContribution:
        this._balanceSheetScoreContribution,
      percentageFinancialAssetsScoreContribution:
        this._percentageFinancialAssetsScoreContribution,
      percentageTrustedWealthScoreContribution:
        this._percentageTrustedWealthScoreContribution,
      debtRatioScoreContribution:
        this._debtRatioScoreContribution,
      financialObjectiveScoreContribution:
        this._financialObjectiveScoreContribution,
      investmentHorizonScoreContribution:
        this._investmentHorizonScoreContribution,
      lossReactionScoreContribution:
        this._lossReactionScoreContribution,
      liquidAssetPartScoreContribution:
        this._liquidAssetPartScoreContribution,
      lossAversionScoreContribution:
        this._lossAversionScoreContribution
    }
  }

	public getScoreContributionsAsPairsArray() {
    const scoreContributions = this.getScoreContributionsAsJSON();
    return Object.keys(scoreContributions).map((key: string) => {
      return [key, scoreContributions[key]]
    })
    // return Object.entries(this.getScoreContributionsAsJSON());
  }

  // Also known as Q1
  private _evaluateBalanceSheetContribution() {

    const balanceSheetTotalAnswer = <SingleOptionAnswer> this.
      _questionnaireHistory.FindAnswer("balance_sheet_total");

    const balanceSheetTotalOptionIdToValue = new Map();
    balanceSheetTotalOptionIdToValue.set("less_than_1m",0);
    balanceSheetTotalOptionIdToValue.set("between_1m_and_10m",5);
    balanceSheetTotalOptionIdToValue.set("between_10m_and_20m",10);
    balanceSheetTotalOptionIdToValue.set("more_than_20m",20);

    let score = balanceSheetTotalOptionIdToValue.get(balanceSheetTotalAnswer.optionId);

    this._balanceSheetScoreContribution = score;
  }

  // Also known as Q2
  private _evaluatePercentageFinancialAssetsContribution() {
    const financialPartOfWealthAnswer = <SingleOptionAnswer> this.
      _questionnaireHistory.
      FindAnswer("financial_part_of_wealth");

    const financialPartOfWealthMap = new Map();
    financialPartOfWealthMap.set("less_than_10_percent",10);
    financialPartOfWealthMap.set("between_10_50_percent",5);
    financialPartOfWealthMap.set("between_50_80_percent",0);
    financialPartOfWealthMap.set("more_than_80_percent",-5);

    this._percentageFinancialAssetsScoreContribution =
      financialPartOfWealthMap.get(financialPartOfWealthAnswer.optionId);

    return this._percentageFinancialAssetsScoreContribution;
  }

  // Also known as Q2 bis
  private _evaluatePercentageTrustedWealthContribution() {

    const percentageTrustedAnswer = <SingleOptionAnswer> this.
      _questionnaireHistory.
      FindAnswer("trusted_wealth_as_percentage_financial_wealth");

    const percentageTrustedAnswerMap = new Map();
    percentageTrustedAnswerMap.set("less_than_10_percent",10);
    percentageTrustedAnswerMap.set("between_10_50_percent",5);
    percentageTrustedAnswerMap.set("between_50_80_percent",0);
    percentageTrustedAnswerMap.set("more_than_80_percent",-5);

    this._percentageTrustedWealthScoreContribution =
      percentageTrustedAnswerMap.get(percentageTrustedAnswer.optionId);
    
    return this._percentageTrustedWealthScoreContribution;

  }

  // Also known as Q3
  private _evaluateDebtRatioContribution() {

    const debtRatioAnswer = <SingleValueAnswer>this.
      _questionnaireHistory.FindAnswer("debt_ratio");

    const thresholds = [10, 50, 81];
    const y = debtRatioAnswer.value;
    const equivalentAnswerId = IndoScoreCalculator.valueToBucketIndex(thresholds, y);
    const debtRatioAnswerMap = [20, 10, 0, -10];

    this._debtRatioScoreContribution = debtRatioAnswerMap[equivalentAnswerId];
  }

  // Also known as Q4
  private _evaluateFinancialObjectiveContribution() {

    const financialObjectiveAnswer = <SingleOptionAnswer>this.
      _questionnaireHistory.FindAnswer("main_financial_objective");

    const financialObjectiveAnswerMap = new Map();
    financialObjectiveAnswerMap.set("preserving_and_passing_on_assets",0);
    financialObjectiveAnswerMap.set("increasing_value_of_assets",10);
    financialObjectiveAnswerMap.set("pursuit_of_returns",20);

    this._financialObjectiveScoreContribution =
      financialObjectiveAnswerMap.get(financialObjectiveAnswer.optionId);
  }

  // Also known as Q5
  private _evaluateInvestmentHorizonContribution() {

    const investmentHorizonAnswer = <SingleOptionAnswer> this.
      _questionnaireHistory.FindAnswer("investment_horizon");

    const investmentHorizonAnswerMap = new Map();
    investmentHorizonAnswerMap.set("1year",0);
    investmentHorizonAnswerMap.set("1_3years",5);
    investmentHorizonAnswerMap.set("3_5years",10);
    investmentHorizonAnswerMap.set("5_years_or_more",20);

    this._investmentHorizonScoreContribution =
      investmentHorizonAnswerMap.get(investmentHorizonAnswer.optionId);
  }

  // Also known as Q6
  private _evaluateLossReactionContribution() {

    const lossReactionAnswer = <SingleOptionAnswer>this.
      _questionnaireHistory.FindAnswer("react_significant_decline");

    const lossReactionAnswerMap = new Map();
    lossReactionAnswerMap.set("immediately_sell_at_risk_investments",-10);
    lossReactionAnswerMap.set("rapid_reallocation",0);
    lossReactionAnswerMap.set("monitor_changes_in_portfolio_without_hasty_decisions",10);
    lossReactionAnswerMap.set("maintain_investment_decline_as_opportunity",20);

    this._lossReactionScoreContribution =
      lossReactionAnswerMap.get(lossReactionAnswer.optionId);
  }

  // Also known as Q7
  private _evaluateLiquidAssetPartContribution() {

    const percentageToWithdrawAnswer = <SingleValueAnswer> this.
      _questionnaireHistory.
      FindAnswer("guaranteed_proportion_of_trusted_wealth");

    const thresholds = [10, 30, 51];
    const equivalentAnswerId = IndoScoreCalculator.
      valueToBucketIndex(thresholds, percentageToWithdrawAnswer.value);

    const liquidAssetPartAnswerMap = [20, 10, -10, -20];
    this._liquidAssetPartScoreContribution =
      liquidAssetPartAnswerMap[equivalentAnswerId];
  }
  
  private _riskProfileEquivalentQ8Index() {

    let lastRiskQuestionAnswer =
      this._questionnaireHistory.FindQuestionAnswerWithNextId("risk_finished");

    let riskQuestion = <RiskQuestion>lastRiskQuestionAnswer[0];
    let riskAnswer = <SingleOptionAnswer>lastRiskQuestionAnswer[1];
    let chosenOption = riskQuestion.getOptionForAnswer(riskAnswer);

    let investorProfileParameters =
      chosenOption.attributes.find(
        att => att[0] === "investor_profile_Parameters")[1];

    const lossAversion =
      investorProfileParameters.find(p => p[0] === "LossAversion_Value")[1];

    const thresholds = [2, 4, 6];
    return 3 - IndoScoreCalculator.valueToBucketIndex(thresholds, lossAversion);

  }

  // Also known as Q8
  private _evaluateLossAversionContribution() {

    if (this._evaluatePercentageFinancialAssetsContribution() + this._evaluatePercentageTrustedWealthContribution() < 0) {
      this._lossAversionScoreContribution = 0;
    } else {
      const scores =  [-30, 0, 20, 30];
      let score =
        scores[this._riskProfileEquivalentQ8Index()];
      this._lossAversionScoreContribution = score;
    }
  }

  private _evaluateScore() {
    this._riskScore =
      this._balanceSheetScoreContribution +
      this._percentageFinancialAssetsScoreContribution +
      this._percentageTrustedWealthScoreContribution +
      this._debtRatioScoreContribution +
      this._financialObjectiveScoreContribution +
      this._investmentHorizonScoreContribution +
      this._lossReactionScoreContribution +
      this._liquidAssetPartScoreContribution +
      this._lossAversionScoreContribution
  }

  private _evaluateRiskProfile() {
    const score = this._riskScore;
    const scores = [-10, 30, 70, 100];
    let index = IndoScoreCalculator.valueToBucketIndex(scores, score);

    if(this._riskProfileEquivalentQ8Index() === 0) {
      index = 0;
    } else if (this._riskProfileEquivalentQ8Index() === 1 ) {
      index = Math.min(index, 2);
    }

    this._riskProfile = this._riskProfiles[index];
    this._riskTitle = this._riskTitles[index];
    this._riskText = this._riskTexts[index][0];
    this._riskDetails = this._riskTexts[index][1];
  }
}
