<div *ngIf="isClientIdValid">
  <router-outlet></router-outlet>
</div>
<div *ngIf="!isClientIdValid">
  <br>
  <br>
  <br>
  <br>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10 card">
      <h1>{{ 'error' | translate }}</h1>
      <hr>
      <br>
      <p> {{ 'invalid_url' | translate }}</p>
      <br>
    </div>
    <div class="col-1"></div>
  </div>
</div>
