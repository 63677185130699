import { Component, OnInit, Input } from '@angular/core';
import { IIndoScoreCalculator } from "../i-indo-score-calculator";
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { SessionInfo } from "../../session-info";
import { QuestionnaireHistory } from "../../questionnaire/questionnaire-history";
import { AnswerPDFExporterBuilder } from "../answer-pdf-exporter/answer-pdf-exporter-builder";
import { QuestionnaireHelperMP } from "../../questionnaire/questionnaire-helper-mp";
import { QuestionnaireResults } from "../questionnaire-results";
import {NpCurrencyPipe} from 'neuroprofiler-ui-kit';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss']
})
export class DownloadPdfComponent implements OnInit {

  @Input() indoScoreCalculator: IIndoScoreCalculator;
  @Input() questionsAnswersData: any;
  @Input() questionnaireResults: QuestionnaireResults;
  @Input() readonly configuration: Map<string, string>;
  
  _filename: string = "ceso_";
  public waiting: boolean = false;

  constructor(private _translate: TranslateService, private _http: HttpClient) { }

  ngOnInit() {
    console.log(this._preparePDFMap());
  }

  private _preparePDFMap() {
    const isCFM = SessionInfo.country === "ch";
    const isFinancialProfessional = QuestionnaireHelperMP.isFinancialProfessional(this.questionnaireResults.questionnaireHistory);

    let ape = new AnswerPDFExporterBuilder().build(SessionInfo.cesoType, isCFM);
    let res = ape.createMapPDF(this.questionnaireResults, isFinancialProfessional);

    if (SessionInfo.cesoType !== "manda") {
      const riskProfileKey = this.indoScoreCalculator.riskProfile;
      res.set("risk_profile_key", riskProfileKey);
      res.set("risk_profile_translated", this._translate.instant(riskProfileKey));
    }


    let jsonObject = {};  
    res.forEach((value, key) => {  
      if (key.startsWith("wealth_composition") && key.endsWith("_value")) {
        value = this._formatAmount(value);
      }
      jsonObject[key] = value  
    });  
    return jsonObject;
  }

  private _formatAmount(f){

    try { 
      const amount = new NpCurrencyPipe().transform(
        f as number,
        this.configuration,
        {
          textual: false
        }
      );
      
      return amount.toString().replace(/[A-Za-z]+/, "");
    }
    catch {
      return f
    }

  }

  private _buildEndpointInput() {

    const augmentedQuestionsAnswersData = this.questionsAnswersData;
    let translatedRiskProfile = "";
    let translatedRiskMatrix = [];

    if (SessionInfo.cesoType !== "manda") {
      augmentedQuestionsAnswersData[augmentedQuestionsAnswersData.length - 1]["questionAnswers"]
        .push(this._deduceAndTranslateQ9());

      translatedRiskProfile = this
        ._translate
        .instant(this.indoScoreCalculator.riskProfile);

      translatedRiskMatrix = this
        .indoScoreCalculator
        .riskProfilesMatrix
        .map(arr => arr.map(s => this._translate.instant(s)));
    }


    let cesoData = {
      "language":this._translate.currentLang,
      "ceso_type":SessionInfo.cesoType,
      "country":SessionInfo.country,
      "risk_profile": translatedRiskProfile,
      "risk_profiles": translatedRiskMatrix,
      "client_id": SessionInfo.clientId,
      "luxtrust_id": SessionInfo.luxtrustId,
      "luxtrust_banker_id": SessionInfo.luxtrustBankerId,
      "question_answer_history": augmentedQuestionsAnswersData,
      "flattened_data": this._preparePDFMap()
    }

    return cesoData;
  }

  public luxtrustEnabled() {
    return SessionInfo.country === "fr";
  }

  terminateSessionBtnClick() {

    if(!this.waiting){
      this.waiting = true;
      const cesoData = this._buildEndpointInput();
      const res = this._http.post(
        environment.apiUrl + '/completed_url', cesoData, {
          observe : 'response',
          responseType: 'json'
        }).subscribe((res: any) => {
          if (res) {
              console.log(res) ; 
               window.location.href = res.body.redirect_url;
          } }
        );
    }
  }

  downloadBtnClick() {
    const cesoData = this._buildEndpointInput();
    const res = this._http.post(
      environment.apiUrl + '/ceso_pdf', cesoData,
      {
        observe : 'response',
        responseType: 'blob'
      }
    ).subscribe((res) => {
      if (res) {
        this.downloadFile(res.body, SessionInfo.clientId);
        return res; }
      }
    );
  }

  public downloadFile(blob, id) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, this._filename + id + ".pdf");
    } else {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = this._filename + id + ".pdf";
      a.click();
    }
  }

  private _deduceQ9Answer() {
    const lossAversionContribution = this
      .indoScoreCalculator
      .lossAversionScoreContribution;

    switch(lossAversionContribution) {
      case 30: {
        return "risk_of_complete_capital_loss_or_of_a_loss_greater_than_the_initial_investment_in_exchange_for_very_high_potential_returns";
      }
      case 20: {
        return "significant_risk_of_capital_loss_in_exchange_for_high_potential_returns";
      }
      case 0: {
        return "limited_risk_of_capital_loss_in_exchange_for_moderate_potential_returns";
      }
      case -30: {
        return "low_risk_of_capital_loss_in_exchange_for_low_potential_returns";
      }
      default: {
        console.log("ERROR : lossAversionContribution could not match answer");
      }

    } 
  }

  private _deduceAndTranslateQ9() {
    const answerKey = this._deduceQ9Answer();
    const answer = this._translate.instant(answerKey);

    const questionKey = "the_following_approaches_are_classified_as_balance_in_terms_of_risk_and_performance_which_do_you_think_is_most_suitable_to_you";
    const question = this._translate.instant(questionKey);

    return { question: question, answers: [answer] };
  }

}
