import { IIntermediateProgressionQuestion } from 'neuroprofiler-ui-kit';

import { TextImageOption } from '../options/text-image-option';
import { Answer } from '../answers/answer';
import {TranslateService} from '@ngx-translate/core';

export class IntermediateProgressionQuestion implements IIntermediateProgressionQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public activeOptionId: string,
    public nextItemId: string,
    public options: TextImageOption[]){}

  public getNextQuestionIds(answer: any) {
    return [this.nextItemId];
  }

  public canContinue(answer: any) {
    return true;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": ''
    };
  }

  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    return [];
  }
}
