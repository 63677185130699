<div class="np-multiple-option-question ">
  <ui-title
    *ngIf="question.titleKey"
    class="text-center d-block mb-5"
    [text]="question.titleKey"
    [color]="colors?.primaryText">
  </ui-title>

  <ui-title
    *ngIf="question.subtitleKey"
    class="text-center d-block mb-5"
    [render]="'subtitle'"
    [text]="question.subtitleKey"
    [color]="colors?.primaryText">
  </ui-title>

  <div class="row justify-content-center">
    <ng-container *ngFor="let option of question.options">
      <div class=" mb-4">
        <div class="d-flex justify-content-center align-items-center item">
          <button
            type="button"
            (click)="onUpdateResponse(option)"
            class="card card-custom mx-3 mb-2 brown-bkg"
            [class.active]="isActive(option)"
            [ngStyle]="{
              borderColor: colors?.primaryBackground,
              backgroundColor: !isActive(option) ? colors?.primaryBackground : colors?.secondaryBackground
            }">
            <p class="text-center" [ngStyle]="{color: colors?.reverseText}">{{option.titleKey | translate}}</p>
            <img *ngIf="!isActive(option)"
                 class="card-image"
                 [src]="assetsUrl + '/library/icons/' + option.activePictureAlias"/>

          </button>
        </div>
        <div
          *ngIf="option.helperKey | translate"
          class="btn-help_container">
          <button class="btn-help" (click)="openModal(option)">?</button>

        </div>

<!--        <ui-text-->
<!--          class="text-center d-block mt-2 mb-4 np-help"-->
<!--          [render]="'legend'"-->
<!--          [text]="option.helperKey"-->
<!--          [color]="colors.secondaryText">-->
<!--        </ui-text>-->
      </div>
    </ng-container>
  </div>
</div>
