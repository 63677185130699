import { ITextImageOption } from 'neuroprofiler-ui-kit';

export class TextImageOption implements ITextImageOption {

	constructor(
		public optionId: string,
		public titleKey: string,
		public helperKey: string,
		public defaultPictureAlias:string,
		public activePictureAlias:string,
		public nextItemId: string) {}

	public static FromJson(inputJson: any) {
		return new TextImageOption(
			inputJson.option_id,
			inputJson.title_key,
			inputJson.helper_key,
			inputJson.default_picture_alias,
			inputJson.active_picture_alias,
			inputJson.next_item_id)
	}
}
