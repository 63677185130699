import { IRiskQuestion } from 'neuroprofiler-ui-kit';

// import { SingleOptionAnswer } from '../answers/single-option-answer';
import { RiskOption } from "../options/risk-option";
import { Answer } from "../answers/answer";
import {TranslateService} from '@ngx-translate/core';

export class RiskQuestion implements IRiskQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public currentDepth: number,
    public maxDepth: number,
    public options: RiskOption[]
  ) {}

  public getNextQuestionIds(answer: any) {
    const res = this.options.find(o => o.optionId === answer.optionId).nextItemId;
    if (res !== '') {
      return [res];
    } else {
      return [];
    }
  }

  public getOptionForAnswer(answer: any){
    return this.options.find(o => o.optionId === answer.optionId);
  }

  public canContinue(answer: any) {
    return (this.options.map(o => o.optionId).indexOf(answer.optionId) > -1);
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": this.getOptionForAnswer(answer).binaryGamble
    };
  }

  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    return [JSON.stringify(this.getOptionForAnswer(answer).binaryGamble)];
  }
}
