import { AnswerPDFExporterCFM } from "./answer-pdf-exporter-cfm";
import { AnswerPDFExporter } from "./answer-pdf-exporter";

export class AnswerPDFExporterBuilder {

  private _ppSingleOptionQuestionAnswerIds: string[] =
    [
      "age",
      "total_wealth_interval",
      "financial_part_of_wealth",
      "trusted_wealth_as_percentage_financial_wealth",
      "annual_income",
      "annual_expenditures",
      "self_assessed_financial_knowledge",
      "self_assessed_level_of_experience",
      "frequency_market_reviews",
      "ever_used_investment_advisory_advice",
      "ever_used_discretionary_management_mandate",
      "main_financial_objective",
      "investment_horizon",
      "react_significant_decline"
    ]

  private _mpSingleOptionQuestionAnswerIds: string[] =
    [
      "balance_sheet_total",
      "capital_total",
      "turnover_total",
      "categorized_as_professional_client",
      "annual_income",
      "annual_expenditures",
      "total_wealth_interval",
      "financial_part_of_wealth", 
      "trusted_wealth_as_percentage_financial_wealth",
      "self_assessed_financial_knowledge",
      "self_assessed_level_of_experience",
      "frequency_market_reviews",
      "ever_used_investment_advisory_advice",
      "ever_used_discretionary_management_mandate",
      "main_financial_objective",
      "investment_horizon",
      "react_significant_decline"
    ]


  private _mandaSingleOptionQuestionAnswerIds: string[] = 
    [
      "self_assessed_financial_knowledge",
      "self_assessed_level_of_experience",
      "frequency_market_reviews",
      "ever_used_investment_advisory_advice",
      "ever_used_discretionary_management_mandate"
    ]

  // TODO : explore "101" behavior, "100" should be enough
  private _ppSingleValuesToOptionQuestions: {[index: string]: [string, string][]}=
    {
      "guaranteed_proportion_of_trusted_wealth": [ 
        ["[0..10[", "less_than_10_percent"],
        ["[10..30[", "between_10_30_percent"],
        ["[30..50[", "between_30_50_percent"],
        ["[50..101]", "more_than_50_percent"]
      ],
      "debt_ratio":  [ 
        ["[0..10[", "less_than_10_percent"],
        ["[10..50[", "between_10_50_percent"],
        ["[50..80[", "between_50_80_percent"],
        ["[80..101]", "more_than_80_percent"]
      ]
    }

  private _lossAversionToStringMapping: [string, string][] = [
        ["[0..2[", "risk_of_complete_capital_loss_or_of_a_loss_greater_than_the_initial_investment_in_exchange_for_very_high_potential_returns"],
        ["[2..4[", "significant_risk_of_capital_loss_in_exchange_for_high_potential_returns"],
        ["[4..6[", "limited_risk_of_capital_loss_in_exchange_for_moderate_potential_returns"],
        ["[6..10]", "low_risk_of_capital_loss_in_exchange_for_low_potential_returns"]
  ]

  private _lossAversionQuestionKey: string = 
     "the_following_approaches_are_classified_as_balance_in_terms_of_risk_and_performance_which_do_you_think_is_most_suitable_to_you";


  private _mpSingleValuesToOptionQuestions : {[index: string]: [string, string][]}=
    { 
      "guaranteed_proportion_of_trusted_wealth": [ 
        ["[0..10[", "less_than_10_percent"],
        ["[10..30[", "between_10_30_percent"],
        ["[30..50[", "between_30_50_percent"],
        ["[50..101]", "more_than_50_percent"]
      ],
      "debt_ratio":  [ 
        ["[0..10[", "less_than_10_percent"],
        ["[10..50[", "between_10_50_percent"],
        ["[50..80[", "between_50_80_percent"],
        ["[80..101]", "more_than_80_percent"]
      ]
    }


  private _assetClassesCFM: string[] =
    ["money_market_investments", 
      "insurance_products",
      "ordinary_bonds", 
      "equities", 
      "simple_investment_funds",
      "alternative_and_complex_investment_funds", 
      "structured_product_with_guarantee",
      "structured_product_without_guarantee", 
      "derivatives", 
      "commodities",
      "private_equity", 
      "accumulator_decumulator", 
      "real_estate_funds", 
      "cocos"
    ];

  private _assetClasses: string[] =
    ["money_market_investments", 
      "insurance_products",
      "ordinary_bonds", 
      "equities", 
      "simple_investment_funds",
      "alternative_and_complex_investment_funds", 
      "structured_product_with_guarantee",
      "structured_product_without_guarantee", 
      "derivatives", 
      "commodities",
      "private_equity", 
      "accumulator_decumulator", 
      "real_estate_funds"
    ];

  private _assetExperiences: string[] =
    ["past_trades_over_five_years_money_market_investments", 
     "past_trades_over_five_years_insurance_products",
     "past_trades_over_five_years_ordinary_bonds", 
     "past_trades_over_five_years_equities", 
     "past_trades_over_five_years_simple_investment_funds",
     "past_trades_over_five_years_alternative_and_complex_investment_funds", 
     "past_trades_over_five_years_structured_product_with_guarantee",
     "past_trades_over_five_years_structured_product_without_guarantee", 
     "past_trades_over_five_years_derivatives", 
     "past_trades_over_five_years_commodities",
     "past_trades_over_five_years_accumulator_decumulator",
     "past_trades_over_five_years_private_equity", 
     "past_trades_over_five_years_real_estate_funds",
     "past_losses_money_market_investments", 
     "past_losses_insurance_products",
     "past_losses_ordinary_bonds", 
     "past_losses_equities", 
     "past_losses_simple_investment_funds",
     "past_losses_alternative_and_complex_investment_funds", 
     "past_losses_structured_product_with_guarantee",
     "past_losses_structured_product_without_guarantee", 
     "past_losses_derivatives", 
     "past_losses_commodities",
     "past_losses_accumulator_decumulator",
     "past_losses_private_equity", 
     "past_losses_real_estate_funds"
    ];

  private _assetExperiencesCFM: string [] = 
    [];

  private _wealthCompositionAnswerId: string = "wealth_composition_answer";
  private _financialKnowledgeAnswerId: string = "financial_assets_knowledge";
  private _financialExperienceAnswerId: string = "financial_assets_experience";


  public constructor(){ };

  public get ppSingleOptionQuestionANswerIds(): string[] {
    return this._ppSingleOptionQuestionAnswerIds;
  }

  public get mpSingleOptionQuestionAnswerIds(): string[] {
    return this._mpSingleOptionQuestionAnswerIds;
  }

  public get mandaSingleOptionQuestionAnswerIds(): string[] {
    return this._mandaSingleOptionQuestionAnswerIds;
  }

  public get wealthCompositionAnswerId(): string {
    return this._wealthCompositionAnswerId;
  }

  public get financialKnowledgeAnswerId(): string {
    return this._financialKnowledgeAnswerId;
  }

  public get assetExperiences(): string[] {
    return this._assetExperiences;
  }

  public build(cesoType:string, isCFM: boolean): AnswerPDFExporter {

    if (isCFM) {

      switch(cesoType) {
        case 'pp':
          return new AnswerPDFExporterCFM(
            this._ppSingleOptionQuestionAnswerIds,
            this._ppSingleValuesToOptionQuestions,
            this._assetClassesCFM,
            this._assetExperiencesCFM,
            this._wealthCompositionAnswerId,
            this._financialKnowledgeAnswerId,
            this._financialExperienceAnswerId,
            this._lossAversionQuestionKey,
            this._lossAversionToStringMapping
          );

        case 'mp':
          return new AnswerPDFExporterCFM(
            this._mpSingleOptionQuestionAnswerIds,
            this._mpSingleValuesToOptionQuestions,
            this._assetClassesCFM,
            this._assetExperiencesCFM,
            this._wealthCompositionAnswerId,
            this._financialKnowledgeAnswerId,
            this._financialExperienceAnswerId,
            this._lossAversionQuestionKey,
            this._lossAversionToStringMapping
          );

        case 'manda':
          return new AnswerPDFExporterCFM(
            this._mandaSingleOptionQuestionAnswerIds,
            {},
            this._assetClassesCFM,
            this._assetExperiencesCFM,
            "",
            this._financialKnowledgeAnswerId,
            this._financialExperienceAnswerId,
            "",
            []) ;
      }

    } else {

      switch(cesoType) {
        case 'pp':
          return new AnswerPDFExporter(
            this._ppSingleOptionQuestionAnswerIds.concat(["would_you_like_to_consider_sustainable_finance_criteria"]),
            this._ppSingleValuesToOptionQuestions,
            this._assetClasses,
            this._assetExperiences,
            this._wealthCompositionAnswerId,
            this._financialKnowledgeAnswerId,
            this._financialExperienceAnswerId,
            this._lossAversionQuestionKey,
            this._lossAversionToStringMapping
          );

        case 'mp':
          return new AnswerPDFExporter(
            this._mpSingleOptionQuestionAnswerIds.concat(["would_you_like_to_consider_sustainable_finance_criteria"]),
            this._mpSingleValuesToOptionQuestions,
            this._assetClasses,
            this._assetExperiences,
            this._wealthCompositionAnswerId,
            this._financialKnowledgeAnswerId,
            this._financialExperienceAnswerId,
            this._lossAversionQuestionKey,
            this._lossAversionToStringMapping
          );

        case 'manda':
          return new AnswerPDFExporter(
            this._mandaSingleOptionQuestionAnswerIds,
            {},
            this._assetClasses,
            this._assetExperiences,
            "",
            this._financialKnowledgeAnswerId,
            this._financialExperienceAnswerId,
            "",
            []) ;

      }
    }
  }

}
