<h2>{{ question.titleKey }}</h2>
<p>{{ question.subtitleKey }}</p>

<div
	[ngStyle]="{'font-weight': isOptionSelected(option) ? 'bold' : 'normal'}" 
	(click)="updateAnswer(option)" 
	*ngFor="let option of question.options">
	<li>{{option.titleKey}}</li>
	<li>x : {{ option.binaryGamble.x}}</li>
	<li>px : {{ option.binaryGamble.px}}</li>
	<li>y : {{ option.binaryGamble.y}}</li>
</div>
