import { Component, OnInit, Input, HostBinding } from '@angular/core';

import { ITextImageOption } from '../../types/options/text-image-option';
import { ISingleOptionQuestion } from '../../types/questions/single-option-question';

import { ISingleOptionAnswer } from '../../types/answers/single-option-answer';

@Component({
  selector: 'app-indo-single-option-question-rounded',
  templateUrl: './indo-single-option-question-rounded.component.html',
  styleUrls: ['./indo-single-option-question-rounded.component.scss'],
})
export class IndoSingleOptionQuestionRoundedComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly question: ISingleOptionQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: ISingleOptionAnswer;

  constructor() {}

  ngOnInit() {}

  onUpdateResponse(option: ITextImageOption) {
    this.answer.optionId = option.optionId;
  }
}
