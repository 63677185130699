import { IMultipleValuesAnswer } from 'neuroprofiler-ui-kit';

export class MultipleValuesAnswer implements IMultipleValuesAnswer {

	public constructor (
		public id: string,
		public kind: string,
		public names: string[],
		public titleKeys: string[],
    public values: number[],
    public skipped: boolean){}

}
