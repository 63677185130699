import { Component, OnInit } from '@angular/core';
import { IIndoScoreCalculator } from "../i-indo-score-calculator";
import { IndoScoreCalculatorPP } from "../indo-score-calculator-pp";
import { IndoScoreCalculatorMP } from "../indo-score-calculator-mp";
import { LossAversionDescription } from '../loss-aversion-description';
import { QuestionnaireResults } from '../questionnaire-results';
import { QuestionnaireService } from '../../questionnaire/questionnaire-service';
import { Router } from '@angular/router';
import { SessionInfo } from "../../session-info";
import { SingleOptionQuestionDescription } from "../single-option-question-description";
import { TranslateService } from '@ngx-translate/core';
import { WinningAppetiteDescription } from "../winning-appetite-description";
import { getCurrencySymbol } from '@angular/common';
import {ConfigurationService} from '../../configuration.service';
import {QuestionsAnswersDescription} from '../questions-answers-description';

import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { IndoKnowledgeCustomQuestion } from "../../questions/indosuez-components/indo-knowledge-custom-question/indo-knowledge-custom-question";
import { IndoKnowledgeCustomAnswer } from "../../answers/indo-knowledge-custom-answer";
import { QuestionnaireItem } from "../../questionnaire/questionnaire-item";

import JSONFinancialKnowledgeDetailedQuestions from '../../../assets/static/questionnaire/financial_knowledge_detailed_questions.json'
import JSONFinancialKnowledgeDetailedQuestionsCFM from '../../../assets/static/questionnaire/financial_knowledge_detailed_questions_cfm.json'

import { QuestionnaireHistory } from "../../questionnaire/questionnaire-history";
import { IndoScoreCalculatorManda } from "../indo-score-calculator-manda";
import { QuestionnaireHelperMP } from "../../questionnaire/questionnaire-helper-mp";
import { ProgressBar } from "../../ui/progress-bar/progress-bar";

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  // colors
  colors: any;

  // configuration
  configuration: Map<string, string|boolean>;
  showQuestionList: boolean;
  questionnaireResults: QuestionnaireResults;
  indoScoreCalculator: IIndoScoreCalculator;

  financialObjectiveDescription: SingleOptionQuestionDescription;

  //manda only
  selfAssessedFinancialKnowledgeDescription: SingleOptionQuestionDescription;
  selfAssessedFinancialExperienceDescription: SingleOptionQuestionDescription;
  frequencyMarketReviewsDescription: SingleOptionQuestionDescription;

  labels = {
    headers: {
      value_function: this.translate.instant('value_function'),
      mean_value_function: this.translate.instant('mean_value_function'),
      x_equals_y: this.translate.instant('x_equals_y')
    },
    axes: {
      investissement_value: this.translate.instant('investissement_value'),
      perception_investissement_value: this.translate.instant('perception_investissement_value')
    },
    chart_description: 'value_function_description',
    chart_results: 'chart_results'
  };

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private translate: TranslateService,
    private questionnaireService: QuestionnaireService,
    private _http: HttpClient
  ) {
    if (!this.questionnaireService.questionnaire.isTerminated) {
      this.router.navigate(['']);
      return;
    }

    // Colors
    this.colors = Object.freeze({
      // Default colors set
      primaryBackground: '#736251',
      secondaryBackground: '#b7a998',
      inactiveBackground: '#F1F9FF',

      text: '#736251',
      primaryText: '#736251',
      secondaryText: '#736251',
      inactiveText: '#C7C7C7',
      reverseText: '#FFFFFF',

      // Risk test colors set
      darkWin: '#afddf2',
      lightWin: '#f1f9ff',
      loosing: '#01b4ec',
      win: '#1CB841',
      smallWin: '#117028',
      lose: '#F47373',
      smallLose: '#903a3d',
      nothing: '#303035',
      selection: '#20b860'
    });

    this.configuration = this.configurationService.configuration;
    this.questionnaireResults = this.buildQuestionnaireResults;
  }

  ngOnInit() {

    if(SessionInfo.cesoType === "mp") {
      this.indoScoreCalculator = new IndoScoreCalculatorMP(
        this.questionnaireService.history);
    } else if (SessionInfo.cesoType === "pp") {
      this.indoScoreCalculator = new IndoScoreCalculatorPP(
        this.questionnaireService.history);
    } else if (SessionInfo.cesoType === "manda") {
      this.indoScoreCalculator = new IndoScoreCalculatorManda(
        this.questionnaireService.history);
    }

    if (this.questionnaireService.history.CanFindAnswer("main_financial_objective")){
      this.financialObjectiveDescription = new SingleOptionQuestionDescription(
        this.questionnaireService.history,
        "main_financial_objective");
    }

    if (this.questionnaireService.history.CanFindAnswer("self_assessed_financial_knowledge")) {
      this.selfAssessedFinancialKnowledgeDescription = new SingleOptionQuestionDescription(this.questionnaireService.history, "self_assessed_financial_knowledge");
    }

    if (this.questionnaireService.history.CanFindAnswer("self_assessed_level_of_experience")) {
      this.selfAssessedFinancialExperienceDescription = new SingleOptionQuestionDescription(this.questionnaireService.history, "self_assessed_level_of_experience");
    }

    if (this.questionnaireService.history.CanFindAnswer("frequency_market_reviews")) {
      this.frequencyMarketReviewsDescription = new SingleOptionQuestionDescription(this.questionnaireService.history, "frequency_market_reviews");
    }

  }

  get buildQuestionnaireResults() {
    return this.questionnaireService.buildQuestionnaireResults();
  }

  get assetsUrl() {
    return this.configuration.get('assets-url');
  }

  get whiteLabel() {
    return this.configuration.get('white-label');
  }

  get lossAversion() {
    return this.questionnaireResults.lossAversion;
  }

  get riskAppetite() {
    return this.questionnaireResults.riskAppetite;
  }

  get winLossRelativeImportanceText() {
    if(this.lossAversion > 1) {
      return 'you_are_n_times_more_sensitive_to_losses_than_gains';
    } else {
      return 'you_are_n_times_less_sensitive_to_losses_than_gains';
    }
  }

  get winLossRelativeImportanceCoefficient() {
    if(this.lossAversion > 1) {
      return Math.round(this.lossAversion);
    } else {
      return Math.round(1 / this.lossAversion);
    }
  }

  get questionnaireHistory() {
    return this.questionnaireService.history;
  }

  get questionsAnswersData() {
    return new QuestionsAnswersDescription(this.questionnaireService.history, this.translate, this.configuration).toStringsByCategories()
  }

  get questionAnswerAndSectionsData() {
    if (['ch'].indexOf(SessionInfo.country) === -1) {
    const financeProfessional = QuestionnaireHelperMP.isFinancialProfessional(this.questionnaireService.history);
      return new QuestionsAnswersDescription(this._fixKnowledgeHistory(financeProfessional), 
        this.translate, 
        this.configuration).toStringsByCategories()
    } else {
      return new QuestionsAnswersDescription(this.questionnaireService.history,
        this.translate, 
        this.configuration).toStringsByCategories()
    }
  }

  toggleQuestionList() {
    this.showQuestionList = !this.showQuestionList;
  }

  get whichCESO() {
    return SessionInfo.cesoType;
  }

  private buildFakeKnowledgeQuestion(dataKey: string, data: any) {
    return new IndoKnowledgeCustomQuestion(
      'indo_knowledge_question',
      dataKey + "_title_key",
      '',
      '',
      dataKey,
      data[dataKey],
      '',
      '',
      ['true', 'false', 'do_not_know'])
  }

  private buildFakeKnowledgeAnswer(dataKey: string, data:any, proposeCorrectAnswer: boolean) {
    if (!proposeCorrectAnswer) {
      return new IndoKnowledgeCustomAnswer(
        '',
        'indo_knowledge_answer',
        dataKey,
        data[dataKey].correct_answers.map(a => 'do_not_know'),
        false)
    } else {
      return new IndoKnowledgeCustomAnswer(
        '',
        'indo_knowledge_answer',
        dataKey,
        data[dataKey].correct_answers,
        false)
    }
  }

  private buildFakeKnowledgeHistoryItem(dataKey: string, data: any, progressBar: any, proposeCorrectAnswer: boolean) {
    return new QuestionnaireItem(
      "",
      "",
      progressBar,
      [this.buildFakeKnowledgeQuestion(dataKey, data)],
      [this.buildFakeKnowledgeAnswer(dataKey, data, proposeCorrectAnswer)])
  }

  private _buildFakeKnowledgeHistoryItems(newQuestionnaireHistory, pastKnowledgeItems, proposeCorrectAnswer) {

    let knowledgeProgressBar = new ProgressBar("your_financial_knowledge",0,0,["your_financial_knowledge"],[],[],[],["your_financial_knowledge"],0);

    let financialKnowledgeDetailedQuestions;
    if (['ch'].indexOf(SessionInfo.country) > -1) {
      financialKnowledgeDetailedQuestions = JSONFinancialKnowledgeDetailedQuestionsCFM;
    } else {
      financialKnowledgeDetailedQuestions = JSONFinancialKnowledgeDetailedQuestions;
    }

    for(var key in financialKnowledgeDetailedQuestions) {
      let defaultItem = this.buildFakeKnowledgeHistoryItem(key, 
        financialKnowledgeDetailedQuestions, knowledgeProgressBar, proposeCorrectAnswer);

      let itemInHistory = pastKnowledgeItems.find(i => (<IndoKnowledgeCustomQuestion>i.questions[0]).questionsAnswersItemKey === key);

      if (itemInHistory) {
        newQuestionnaireHistory.AddItem(itemInHistory) 
      } else {
        newQuestionnaireHistory.AddItem(defaultItem)
      }
    }
  }

  private _fixKnowledgeHistory(proposeCorrectAnswer: boolean) {

    const questionnaireHistory = this.questionnaireService.history;
    let newQuestionnaireHistory = new QuestionnaireHistory();
    let pastKnowledgeItems = this.questionnaireService.history.FindQuestionnaireItemByKind("indo_knowledge_question");
    let sectionReplaced = false;

    questionnaireHistory.itemsHistory.forEach( (item) => {
      if (item.questions[0].kind != "indo_knowledge_question") {
        newQuestionnaireHistory.AddItem(item);
      } 
      if (!sectionReplaced && 
        (item.questionnaireItemId === "knowledge_finished" || item.questionnaireItemId === "knowledge_0")) {
        sectionReplaced = true;
        this._buildFakeKnowledgeHistoryItems(newQuestionnaireHistory, pastKnowledgeItems, proposeCorrectAnswer);
      } 
    });

    if (!sectionReplaced ) {
      this._buildFakeKnowledgeHistoryItems(newQuestionnaireHistory, pastKnowledgeItems, proposeCorrectAnswer);
    }

    return newQuestionnaireHistory;
  }

}
