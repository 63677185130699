import {NpCurrencyPipe} from 'neuroprofiler-ui-kit';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { IndoWealthCompositionItem } from "../indo-wealth-composition-item";

@Component({
  selector: 'app-indo-wealth-composition-item',
  templateUrl: './indo-wealth-composition-item.component.html',
  styleUrls: ['./indo-wealth-composition-item.component.scss']
})
export class IndoWealthCompositionItemComponent implements OnInit {
  @Input() item: IndoWealthCompositionItem;
  @Input() total: number;
  @Input() percentageDeclarationOnly: boolean;
  @Output() itemChange = new EventEmitter();
  @Input() readonly colors: any;
  @Input() readonly configuration: Map<string, string>;
  public percentage: number;

  constructor() { }

  ngOnInit() {
    this.percentage = 0;
  }

  get currencySymbol() {
    return this.configuration.get('currency-symbol');
  }

  changeItem(event: any,  itemType: 'value' | 'percentage'= 'value') {
    if (itemType === 'value') {
      this.item.value = this.stringToInt(event);
    }  else {
      this.item.percentage = Math.min(this.stringToInt(event),100);
    }
    this.itemChange.emit(this.item);
  }

  percentageFromValue() {
    return this.item.value / this.total;
  }

  itemValueInputValidatorCurrency(event: any) {
    const pattern = /^[ 0-9]*$/;
    if (!pattern.test(event.target.value)) {
      // prevents the value to change
      event.target.value = this.intToCurrencyString(this.item.value);
    }
  }

  itemValueInputValidatorPercentage(event: any) {
    const pattern = /^[ 0-9]*$/;
    if (!pattern.test(event.target.value)) {
      // prevents the value to change
      event.target.value = this.percentageToString(this.item.value);
    }
  }

  intToCurrencyString(f) {
    //return f.toLocaleString('en-US').replace(/,/g, ' ');
    const amount = new NpCurrencyPipe().transform(
      f as number,
      this.configuration,
      {
        textual: false
      }
    );
    return amount;
  }

  percentageToString(f) {
    return f.toLocaleString('en-US').replace(/,/g, ' ');
  }

  stringToInt(s) {
    const result = parseInt(s.replace(/[^0-9]/g, ''), 10);
    if (isNaN(result)) {
      return 0;
    } else {
      return result;
    }
  }

}
