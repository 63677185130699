<div [innerHTML]="whiteLabelStyles | styles | safeHtml"></div>

<div class="np-slider-question ">

  <ng-container *ngIf="question.referenceAmount">
    <ui-title
      class="text-center d-block mb-3 mb-md-5 mb-lg-5 "
      [text]="question.titleKey"
      [color]="colors?.primaryText">
    </ui-title>
  </ng-container>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-sm-12 col-md-6">
      <div class="d-flex flex-wrap justify-content-center slider-container  mb-4">
        <div class="slider-description pb-3">
          <p class="text-center"
             [ngStyle]="{ color: colors?.secondaryText }">
            {{ question.subtitleKey | translate }}
          </p>
        </div>
        <ngx-slider
          class="slider"
          [(value)]="answer.value"
          (valueChange)="updateChart()"
          [options]="options"
        ></ngx-slider>
        <div *ngIf="showInput" class="input_container d-flex justify-content-center">
          <input type="text" class="user_input" [value]="inputValue" (change)="changeInputValue($event)" [ngStyle]="{colors: colors?.primaryText, borderColor: colors?.primaryBackground}">
        </div>

      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="legends">
        <ul>
          <li *ngFor="let label of labels; let i = index"
              class="d-flex align-items-center"
          >
            <div class="ml-2 mb-2">
                  <span class="legend mr-2"
                        [ngStyle]="{backgroundColor: colorsValues({})[i]}">
                  </span>
              {{label | translate}}
            </div>
          </li>
        </ul>
      </div>
      <div class="pie_chart_container">
        <div class="np-pie-chart">
          <canvas width="100%" height="100%" #pieChart>
            {{ chart }}
          </canvas>
        </div>
      </div>
    </div>

  </div>

</div>
