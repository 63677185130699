import { Injectable } from '@angular/core';
import {getCurrencySymbol} from '@angular/common';
import { SessionInfo } from "./session-info";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  configuration: Map<string, any>;
  constructor() {
    this.configuration = new Map();
    this.configuration.set('assets-url', './assets/pictures');
    this.configuration.set('locale', 'fr');
    this.configuration.set('currency', SessionInfo.currency);
    this.configuration.set('currency-symbol', SessionInfo.currencySymbol);
    this.configuration.set('white-label', false);
    this.configuration.set('rk-with-histograms', true);
    this.configuration.set('rk-with-progress-bar', true);
    this.configuration.set('rk-with-mean', false);
    this.configuration.set('rk-image-prefix', 'default');
    this.configuration.set('currency-suffixes', [
      { singular: 'k', plural: 'ks' },
      { singular: 'mn', plural: 'mns' },
      { singular: 'md', plural: 'mds' }
    ]);
    this.configuration.set(
      'currency-symbol',
      getCurrencySymbol(
        this.configuration.get('currency') as string,
        'narrow',
        this.configuration.get('locale') as string
      )
    );
  }
}
