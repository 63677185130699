import {QuestionnaireHistory} from '../questionnaire/questionnaire-history';
import {TranslateService} from '@ngx-translate/core';
import {RiskQuestion} from '../questions/risk-question';
import {TextOnlyQuestion} from '../questions/text-only-question';
import {IntermediateProgressionQuestion} from '../questions/intermediate-progression-question';
// import {LogisticQuestion} from '../questions/logistic-question';
import {IQuestionAnswersData} from './question-answers-data';
import {IQuestionsAnswersData} from './questions-answers-data';
import {SingleOptionQuestion} from '../questions/single-option-question';
import {SingleOptionAnswer} from '../answers/single-option-answer';
import {MultipleOptionQuestion} from '../questions/multiple-option-question';
import {IndoKnowledgeCustomQuestion} from '../questions/indosuez-components/indo-knowledge-custom-question/indo-knowledge-custom-question';
import {IndoKnowledgeCustomAnswer} from '../answers/indo-knowledge-custom-answer';
import { NpCurrencyInterpolationPipe } from "neuroprofiler-ui-kit";
import { SessionInfo } from "../session-info";
import { QuestionnaireHelperMP } from "../questionnaire/questionnaire-helper-mp";

export class QuestionsAnswersDescription {
  specialQuestions: string[] = ['financial_assets_experience'];

  public constructor(
    private questionnaireHistory: QuestionnaireHistory,
    private translateService: TranslateService,
    public configuration: Map<string , string|boolean>
  ) {
    // TODO Fix this
    if (SessionInfo.country === "ch" && QuestionnaireHelperMP.isFinancialProfessional(questionnaireHistory)){
      this.specialQuestions = [];
    }
  }

  private _translateAndSetCurrency(inputKey: string): string {
     const translated = this.translateService.instant(inputKey, {currency : this.configuration.get('currency-symbol') });
     return new NpCurrencyInterpolationPipe().transform(translated, this.configuration)
  }

  public toStringsByCategories(): IQuestionsAnswersData[] {
    let categoriesHistoryArr: IQuestionsAnswersData[] = [];


    // Flatten all arrays
    const specialQuestionsIds = this.findSpecialQuestionsIds();
    // Only presentable ItemsHistory
    this.questionnaireHistory.itemsHistory
      .filter(this.isAnIncludedItemHistory.bind(this))
      .forEach((includedItem) => {
        categoriesHistoryArr = this.findOrCreateCategories(categoriesHistoryArr, includedItem);
        const currentCategoryIndex = categoriesHistoryArr.length - 1;
        if (specialQuestionsIds.includes(includedItem.questionnaireItemId)) {
          // START SPECIAL PARSING OF QUESTION-ANSWERS//
          const specialQuestionAnswers = this.treatSpecialQuestion(includedItem);
          specialQuestionAnswers.forEach((formattedQuestionAnswer) => {
            categoriesHistoryArr[currentCategoryIndex].questionAnswers.push(formattedQuestionAnswer);
          });
        } else {
          // START REGULAR PARSING OF QUESTION-ANSWERS//
          includedItem.questions.forEach((question: any, i) => {
            let title = '';
            if (includedItem.questionnaireItemId  === 'wealth_composition' ) {
              title = includedItem.titleKey;
            } else {
              title = question.titleKey;
            }
            if (question.kind === 'indo_knowledge_question') {
              title = 'custom-financial-knowledge.' + title;
              (question as IndoKnowledgeCustomQuestion).questionsAnswersItem.questions.forEach((q, index) => {
                const formattedQuestionAnswer: IQuestionAnswersData = {
                  question: this._translateAndSetCurrency(title) + ': ' + this._translateAndSetCurrency('custom-financial-knowledge.' + q),
                  answers: question.answerToString(
                    (includedItem.answers[0] as IndoKnowledgeCustomAnswer).answers[index],
                    this.configuration,
                    this.translateService)
                };
                categoriesHistoryArr[currentCategoryIndex].questionAnswers.push(formattedQuestionAnswer);

              });
            } else {
              const formattedQuestionAnswer: IQuestionAnswersData = {
                question: title,
                answers: question.answerToString(includedItem.answers[i], this.configuration, this.translateService)
              };
              // * adding question with answers to the last category * //
              categoriesHistoryArr[currentCategoryIndex].questionAnswers.push(formattedQuestionAnswer);
            }

          });
        }
      });
    return this._translateAll(categoriesHistoryArr);
  }

  private _translateAll(categoriesHistoryArr: IQuestionsAnswersData[] ): IQuestionsAnswersData[] {
    const translatedQuestionsAnswersData: IQuestionsAnswersData[] = categoriesHistoryArr.map((cat) => {
      const category: IQuestionsAnswersData =  {
        title: this._translateAndSetCurrency(cat.title),
        questionAnswers: cat.questionAnswers.map((questionAnswers) => {
          const formattedQuestionAnswer: IQuestionAnswersData = {
            question: this._translateAndSetCurrency(questionAnswers.question),
            answers: questionAnswers.answers.map(answer => this._translateAndSetCurrency(answer))
          };
          return formattedQuestionAnswer;
        })
      };
      return category;
    });
    return translatedQuestionsAnswersData;
  }

  private findSpecialQuestions() {
    return this.specialQuestions.map((questionType) => {
      const itemHistory = this.questionnaireHistory.itemsHistory.filter(item => item.questionnaireItemId === questionType)[0];
      const itemFirstQuestion = itemHistory.questions[0] as MultipleOptionQuestion;
      const questionsTree = {
        questionItem: itemHistory.questionnaireItemId,
        subQuestionId: [],
        dependantQuestionIds : []
      };
      const tailItemsIds = itemFirstQuestion.getNextQuestionIds(itemHistory.answers[0]);
      questionsTree.dependantQuestionIds = tailItemsIds.filter(id => id !== 'financial_services_used');
      return  questionsTree;
    });
  }
  private findSpecialQuestionsIds() {
    const specialQuestions = this.findSpecialQuestions();
    const specialQuestionsItemIds = specialQuestions.map((specialQuestion) => specialQuestion.questionItem);
    const specialDependentQuestionIds = [].concat.apply(
      [],
      specialQuestions.map((specialQuestion) => specialQuestion.dependantQuestionIds)
    ).filter(specialQuestion => specialQuestion !== 'knowledge_0');
    // Flatten all arrays
    return [...specialQuestionsItemIds,
      ...specialDependentQuestionIds
    ];
  }

  private treatSpecialQuestion(item): IQuestionAnswersData[] {
    let treatment: IQuestionAnswersData[] = [];
    switch (item.questionnaireItemId) {
      case 'financial_assets_experience':
        treatment = this.treatEverInvestedBefore(item);
        break;
    }
    return treatment;
  }

  private treatEverInvestedBefore(item): IQuestionAnswersData[] {
    const formattedQuestionsAnswerArr: IQuestionAnswersData[] = [];
    // first question
    const formattedQuestionAnswer: IQuestionAnswersData = {
      question: item.questions[0].titleKey,
      answers: item.questions[0].answerToString(item.answers[0], this.configuration, this.translateService)
    };
    formattedQuestionsAnswerArr.push(formattedQuestionAnswer);
    const tailItemsIds = (item.questions[0] as MultipleOptionQuestion)
        .getNextQuestionIds(item.answers[0])
        .filter(id => id !== 'financial_services_used');
    formattedQuestionAnswer.answers.forEach((product, i) => {
      const tailItem = this.questionnaireHistory.itemsHistory.find(itm => itm.questionnaireItemId === tailItemsIds[i]);
      if (tailItem != null ) {
      tailItem.questions.forEach((question, index) => {
        // Sub-questions for each product
        const nextFormattedQuestionAnswerChildrens: IQuestionAnswersData = {
          question: this._translateAndSetCurrency(product) + ': ' + this._translateAndSetCurrency(question.titleKey),
          answers: question.answerToString(tailItem.answers[index], this.configuration, this.translateService)
        };
        formattedQuestionsAnswerArr.push(nextFormattedQuestionAnswerChildrens);
      } );
      }
    });
    return formattedQuestionsAnswerArr;
  }

  private  isAnIncludedItemHistory(item): boolean {
    return !this.isARiskQuestion(item.questions[0])
      && !this.isATextOnlyQuestion(item.questions[0])
      && !this.isAnIntermediateProgressionQuestion(item.questions[0])
      && !this.excludedInItemIds(item.questionnaireItemId);
  }

  private  isARiskQuestion(question) {
    return (question instanceof RiskQuestion);
  }
  private  isATextOnlyQuestion(question) {
    return (question instanceof TextOnlyQuestion);
  }
  private  isAnIntermediateProgressionQuestion(question) {
    return (question instanceof IntermediateProgressionQuestion);
  }

  private excludedInItemIds(itemId) {
    return ['setup'].includes(itemId);
  }
  private findOrCreateCategories(categoriesHistoryArr, item)  {
    const category = categoriesHistoryArr.find(
      (cat)  => cat.title === 'progress-bar.' + item.progressBar.GetCurrentTextKey()
    );
    if (!category) {
      const newCategory: IQuestionsAnswersData = {
        title: 'progress-bar.' + item.progressBar.GetCurrentTextKey(),
        questionAnswers: []
      };
      categoriesHistoryArr.push(newCategory);
    }
    return categoriesHistoryArr;
  }
}
