import { QuestionnaireHistory } from "../questionnaire/questionnaire-history";
import { SingleOptionAnswer } from "../answers/single-option-answer";
import { SingleOptionQuestion } from "../questions/single-option-question";

export class SingleOptionQuestionDescription {

  private _questionTitleKey: string;
  private _chosenOptionTitleKey: string;
  private _chosenOptionPictureFilepath: string;

  public constructor(
    private _questionnaireHistory: QuestionnaireHistory,
    private _answerId: string){ 

    const qa = this._questionnaireHistory.
      FindQuestionAnswerWithAnswerId(this._answerId);

    const question = <SingleOptionQuestion> qa[0];
    const answer = <SingleOptionAnswer> qa[1];
    const option = question.getOptionForAnswer(answer);

    this._questionTitleKey = question.titleKey;
    this._chosenOptionTitleKey = option.titleKey;
    this._chosenOptionPictureFilepath = option.activePictureAlias;

  }

  get questionTitleKey() {
    return this._questionTitleKey;
  }

  get chosenOptionTitleKey() {
    return this._chosenOptionTitleKey;
  }
  
  get chosenOptionPictureFilepath() {
    return this._chosenOptionPictureFilepath;
  }

}
