import { Component, OnInit, Input, HostBinding } from '@angular/core';

import { ITextImageOption } from '../../types/options/text-image-option';
import { ISingleOptionQuestion } from '../../types/questions/single-option-question';

import { ISingleOptionAnswer } from '../../types/answers/single-option-answer';

@Component({
  selector: 'app-indo-single-option-question-square',
  templateUrl: './indo-single-option-question-square.component.html',
  styleUrls: ['./indo-single-option-question-square.component.scss'],
})
export class IndoSingleOptionQuestionSquareComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly brownBackground: boolean = true;
  @Input() readonly question: ISingleOptionQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: ISingleOptionAnswer;
  @Input() continueClick: any;
  @Input() showOptionTitle = true;
  constructor() {}

  ngOnInit() {}

  isActive(option: ITextImageOption) {
    return option.optionId == this.answer.optionId;
  }

  onUpdateResponse(option: ITextImageOption) {
    this.answer.optionId = option.optionId;
  }

  get assetsUrl() {
    return this.configuration.get('assets-url');
  }

  get isYesNoQuestion() {
    return this.question.options.length == 2
      && this.question.options[0].defaultPictureAlias.indexOf('yes_no') !== -1
  }
}
