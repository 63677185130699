<div *ngIf="asUrl">>
  <button (click)="openModal(templatenew)" class="btn indo-btn break-word">{{ buttonKey | translate }}</button>
</div>

<div *ngIf="!asUrl">
  <u>
    <a (click)="openModal(templatenew)" class="url">
      {{ buttonKey | translate }}
    </a>
  </u>
</div>

<br>

<ng-template #templatenew>
  <ng-content></ng-content>
</ng-template>
