import { BrowserModule } from '@angular/platform-browser';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';

import { UiKitModule } from 'neuroprofiler-ui-kit';

import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { AppComponent } from './app.component';

import { ProgressBarComponent } from './ui/progress-bar/progress-bar.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire/questionnaire.component';
import { QuestionsComponent } from './questions/questions/questions.component';
import { QuestionComponent } from './questions/question/question.component';

// TODO remove when it will be replace
import { RiskQuestionComponent } from './questions/risk-question/risk-question.component';
import { ClientResultsSummaryComponent } from './results/client-results-summary/client-results-summary.component';
import { AdvisorResultsSummaryComponent } from './results/advisor-results-summary/advisor-results-summary.component';
import { QuestionAnswerHistoryComponent } from './results/question-answer-history/question-answer-history.component';
import { RiskProfileDetailsComponent } from './results/risk-profile-details/risk-profile-details.component';
import { ResultsComponent } from './results/results/results.component';
import {AppRoutingModule} from './app.routing.module';
import {QuestionnaireService} from './questionnaire/questionnaire-service';
import {IndoTextOnlyQuestionComponent} from './questions/indosuez-components/text-only-question/indo-text-only-question.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IndoSingleOptionQuestionComponent} from './questions/indosuez-components/indo-single-option-question/indo-single-option-question.component';
import {IndoSingleOptionQuestionRoundedComponent} from './questions/indosuez-components/indo-single-option-question-rounded/indo-single-option-question-rounded.component';
import {IndoSingleOptionQuestionSelectComponent} from './questions/indosuez-components/indo-single-option-question-select/indo-single-option-question-select.component';
import {IndoSingleOptionQuestionSquareComponent} from './questions/indosuez-components/indo-single-option-question-square/indo-single-option-question-square.component';
import {IndoSliderQuestionComponent} from './questions/indosuez-components/indo-slider-question/indo-slider-question.component';
import {ParseIntPipe} from './questions/pipes/parse-int.pipe';
import {IndoMultipleOptionQuestionComponent} from './questions/indosuez-components/indo-multiple-option-question/indo-multiple-option-question.component';
import { IndoWealthCompositionComponent } from './questions/indosuez-components/indo-wealth-composition/indo-wealth-composition.component';
import { IndoWealthCompositionItemComponent } from './questions/indosuez-components/indo-wealth-composition/indo-wealth-composition-item/indo-wealth-composition-item.component';
import {IndoKnowledgeQuestionComponent} from './questions/indosuez-components/indo-knowledge-question/indo-knowledge-question.component';
import {ExpandableSummaryWithIconComponent} from './results/expandable-summary-with-icon/expandable-summary-with-icon.component';
import {ClientScoreComponent} from './results/client-score/client-score.component';
import {WinLossPerceptionComponent} from './results/win-loss-perception/win-loss-perception.component';
import {IndoProgressBarComponent} from './questions/indosuez-components/indo-progress-bar/indo-progress-bar.component';
import { IndoKnowledgeCustomQuestionComponent } from './questions/indosuez-components/indo-knowledge-custom-question/indo-knowledge-custom-question.component';
import { ButtonToModalComponent } from './ui/button-to-modal/button-to-modal.component';
import { DownloadPdfComponent } from './results/download-pdf/download-pdf.component';
import { ResultsMandaComponent } from './results/results-manda/results-manda.component';


@NgModule({
  declarations: [
    AppComponent,
    ProgressBarComponent,
    QuestionnaireComponent,
    QuestionsComponent,
    QuestionComponent,

    ParseIntPipe,


    // TODO remove when it will be replace
    RiskQuestionComponent,

    ClientResultsSummaryComponent,

    AdvisorResultsSummaryComponent,

    QuestionAnswerHistoryComponent,

    RiskProfileDetailsComponent,

    ResultsComponent,
    IndoTextOnlyQuestionComponent,
    IndoSingleOptionQuestionComponent,
    IndoSingleOptionQuestionRoundedComponent,
    IndoSingleOptionQuestionSelectComponent,
    IndoSingleOptionQuestionSquareComponent,
    IndoSliderQuestionComponent,
    IndoMultipleOptionQuestionComponent,
    IndoWealthCompositionComponent,
    IndoWealthCompositionItemComponent,
    IndoKnowledgeQuestionComponent,
    ClientScoreComponent,
    ExpandableSummaryWithIconComponent,
    WinLossPerceptionComponent,
    QuestionAnswerHistoryComponent,
    IndoProgressBarComponent,
    IndoKnowledgeCustomQuestionComponent,
    ButtonToModalComponent,
    DownloadPdfComponent,
    ResultsMandaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxSliderModule,
    ReactiveFormsModule,
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    UiKitModule
  ],
  providers: [TranslateService, QuestionnaireService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
		registerLocaleData(localeIt, 'it');
		registerLocaleData(localeEs, 'es');
		registerLocaleData(localeNl, 'nl');
		registerLocaleData(localeDe, 'de');
		registerLocaleData(localePt, 'pt');
		registerLocaleData(localeEn, 'en');
		registerLocaleData(localeFr, 'fr');
  }
}
