export class WinningAppetiteDescription {

  constructor(private _winningAppetiteValue: number, 
    private _winningAppetiteQuantile: number) {}

  titleKey() {
    return 'reaction_in_case_of_an_increase_in_your_portfolio';
  }

  percentageToPrint() {
    if (this._winningAppetiteQuantile > 5) {
      return 10 * (10 - this._winningAppetiteQuantile);
    } else {
      return 10 * this._winningAppetiteQuantile;
    }
  }
  
  quantileDescription() {
    if (this._winningAppetiteQuantile > 5) {
      return 'you_are_part_of_the_n_least_most_sensitive_to_gains';
    } else {
      return 'you_are_part_of_the_n_least_loss_sensitive_to_gains';
    }
  }

  details() {
    if (this._winningAppetiteQuantile > 5) {
      return 'when_the_value_of_your_investments_is_increasing_you_tend_to_wait_before_selling_in_order_to_maximize_your_profit';
    } else {
      return 'when_the_value_of_your_investments_is_increasing_you_are_satisfied_with_your_benefits_and_sell_your_assets_without_trying_to_maximize_benefits';
    }
  }

}
