import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-client-score',
  templateUrl: './client-score.component.html',
  styleUrls: ['./client-score.component.scss']
})
export class ClientScoreComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() riskProfile: string;
  @Input() riskScore: string;
  @Input() riskTitle: string;
  @Input() riskText: string;
  @Input() riskDetails: string;
  @Input() questionsAnswers: Array<Array<string>>;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  isCollapsed = true;
  constructor() { }

  ngOnInit() {
  }

}
