import {Component, OnInit, Input, HostBinding, Output, EventEmitter} from '@angular/core';

import { ITextOnlyQuestion } from '../../types/questions/text-only-question';

import { ISingleOptionAnswer } from '../../types/answers/single-option-answer';

@Component({
  selector: 'app-indo-text-only-question',
  templateUrl: './indo-text-only-question.component.html',
  styleUrls: ['./indo-text-only-question.component.scss'],
})
export class IndoTextOnlyQuestionComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly question: ITextOnlyQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: ISingleOptionAnswer;
  @Input() continueClick: any;
  constructor() {}

  ngOnInit() {}

}
