import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-results-manda',
  templateUrl: './results-manda.component.html',
  styleUrls: ['./results-manda.component.scss']
})
export class ResultsMandaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
