import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { SessionInfo } from "./session-info";
import { TranslateService } from '@ngx-translate/core';
import { environment } from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  private _isClientIdValid : boolean = false;

  constructor(
    private _http: HttpClient,
    public translate: TranslateService) {

    SessionInfo.Start();
    this.checkClientIdValid(SessionInfo.clientId, SessionInfo.luxtrustId);

    const languages = ["it", "es", "nl", "de", "pt", "en", "fr"];
    this.translate.addLangs(languages);
    this.translate.setDefaultLang(languages[0]);
    if (languages.indexOf(SessionInfo.language) > -1) {
      this.translate.use(SessionInfo.language);
    } else {
      this.translate.use('fr');
    }

  }

  // TODO : restore this
  public get isClientIdValid(): boolean {
    return true;
    //return this._isClientIdValid;
  }

  onLanguageChanged(input) {
    this.translate.use(input);
  }

  checkClientIdValid(clientId: string, luxtrustId: string): boolean {
    const request = this._http.post(
      environment.apiUrl + "/valid_client_id",
      {
        "client_id":clientId,
        "luxtrust_id":luxtrustId
      },
      {
        observe : 'response',
      }
    )
      .subscribe(res => {console.log(res);
        this._isClientIdValid = res.body["valid"]});

    this._isClientIdValid = request["valid"];
    return this._isClientIdValid;

  }
}
