<ng-container [ngSwitch]="question.kind">
  <!-- display: 'text_only_question' -->
  <app-indo-text-only-question
    *ngSwitchCase="'text_only_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    ([answer])="answer"
    [continueClick]="continueClick"
    [@enterTrigger]="'fadeIn'"
  >
  </app-indo-text-only-question>

  <!-- display: 'single_option_question' -->
  <app-indo-single-option-question
    *ngSwitchCase="'single_option_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [brownBackground]="!whiteBackground(question)"
    [showOptionTitle]="['yes', 'no'].
        indexOf(singleOptionQuestion.options[0].titleKey) === -1"
    [continueClick]="continueClick"
    [(answer)]="answer">
  </app-indo-single-option-question>

  <!-- display: 'knowledge_question' -->
  <app-indo-knowledge-question
    *ngSwitchCase="'knowledge_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </app-indo-knowledge-question>

    <!-- display: 'single_value_question' -->
  <ui-single-value-question
    *ngSwitchCase="'single_value_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </ui-single-value-question>

  <!-- display: 'multiple_option_question' -->
  <app-indo-multiple-option-question
    *ngSwitchCase="'multiple_option_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </app-indo-multiple-option-question>

  <!-- display: 'multiple_values_question' -->
  <ui-multiple-values-question
    *ngSwitchCase="'multiple_values_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </ui-multiple-values-question>

  <!-- display: 'slider_question' -->
  <app-indo-slider-question
    *ngSwitchCase="'slider_question'"
    [question]="question"
    [configuration]="configuration"
    [valueLegend]="ratioLegendKey | translate"
    [totalLegend]="complementLegendKey | translate"
    [colors]="colors"
    [(answer)]="answer">
  </app-indo-slider-question>

  <!-- display: 'intermediate_progression_question -->
  <ui-intermediate-progression-question
    *ngSwitchCase="'intermediate_progression_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </ui-intermediate-progression-question>

  <!-- display: 'ordered_option_and_text_question' -->
  <ui-ordered-option-and-text-question
    *ngSwitchCase="'ordered_option_and_text_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </ui-ordered-option-and-text-question>

  <!-- display: 'optional_values_question' -->
  <ui-optional-values-question
    *ngSwitchCase="'optional_values_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </ui-optional-values-question>

  <!-- display: 'multiple_option_and_text_question' -->
  <ui-multiple-option-and-text-question
    *ngSwitchCase="'multiple_option_and_text_question'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </ui-multiple-option-and-text-question>

  <!-- display: 'risk_question' -->
  <ui-risk-test
    *ngSwitchCase="'risk_question'"
    [design]="orderedRiskDesigns[currentDepth][0]"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors">
  </ui-risk-test>

  <app-indo-wealth-composition
    *ngSwitchCase="'wealth_composition_indo_question'"
    [question]="question"
    [(answer)]="answer"
    [configuration]="configuration"
    [colors]="colors">
    </app-indo-wealth-composition>

  <app-indo-knowledge-custom-question
    *ngSwitchCase="'indo_knowledge_question'"
    [question]="question"
    [(answer)]="answer"
    [configuration]="configuration"
    [colors]="colors">
    </app-indo-knowledge-custom-question>

</ng-container>
