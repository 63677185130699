import { Component, OnInit, Input, HostBinding } from '@angular/core';

import { ITextImageOption } from '../../types/options/text-image-option';
import { ISingleOptionQuestion } from '../../types/questions/single-option-question';

import { ISingleOptionAnswer } from '../../types/answers/single-option-answer';
@Component({
  selector: 'app-indo-single-option-question-select',
  templateUrl: './indo-single-option-question-select.component.html',
  styleUrls: ['./indo-single-option-question-select.component.scss'],
})
export class IndoSingleOptionQuestionSelectComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly question: ISingleOptionQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: ISingleOptionAnswer;
  selectedOption: ITextImageOption;

  constructor() {}

  ngOnInit() {
    let selectedOption = this.question.options[0];
    if (this.answer.optionId) {
      selectedOption = this.question.options.find((option: ITextImageOption) => {
        return option.optionId === this.answer.optionId
      });
    }
    this.selectedOption = selectedOption;
  }

  isSelectedOption(titleKey: string, selectedOption: string) {
    return titleKey == selectedOption;
  }

  onOptionChanged(option: ITextImageOption) {
    this.answer.optionId = option.optionId;
  }
}
