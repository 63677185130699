export class IndoWealthCompositionItem {

  constructor(
    public id: string,
    public labelKey: string,
    public detailKey: string,
    public value: number,
    public percentage: number,
    public isFinancial: boolean) {}

  public static FromJSON(input: any) {
    return new IndoWealthCompositionItem(
      input.id,
      input.label_key,
      input.detail_key,
      input.value,
      input.percentage,
      input.is_financial);
  }
}

