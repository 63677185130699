import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import { WealthCompositionIndoQuestion } from '../../wealth-composition-indo-question';
import { WealthCompositionIndoAnswer } from '../../../answers/wealth-composition-indo-answer';
import {Chart} from 'chart.js';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-indo-wealth-composition',
  templateUrl: './indo-wealth-composition.component.html',
  styleUrls: ['./indo-wealth-composition.component.scss']
})
export class IndoWealthCompositionComponent implements OnInit {

  @Input() answer: WealthCompositionIndoAnswer;
  @Input() readonly question: WealthCompositionIndoQuestion;
  @Input() readonly colors: any;
  @Input() readonly configuration: Map<string, string>;
  @ViewChild('pieChart', { static: true }) private chartRef: ElementRef;
  chart: any;
  chartColors = ['rgb(96, 74, 99)', 'rgb(161, 0, 78)', 'rgb(116, 99, 81)', 'rgb(183, 169, 150)', 'rgb(0, 0, 0)', 'rgb(196, 200, 205)', 'rgb(229, 229, 229)', 'rgb(100, 100, 100)'];
  translateService: TranslateService;
  constructor(Ts: TranslateService) {
    this.translateService = Ts;
  }

  ngOnInit() {
    this.buildPieChart(this.valuesForPieChart(), this.labels(), this.chartColors);
  }

  get currencySymbol() {
    return this.configuration.get('currency-symbol');
  }

  get locale() {
    return this.configuration.get('locale');
  }

  get mockTemplateTest() {
    return this.configuration;
    //return {
    //  lang: this.locale,
    //  currency: this.configuration.get('currency-symbol'),
    //  currency_code: this.configuration.get('currency')
    //};
  }

  changeMode() {
    this.answer.resetValuesAndPercentages();
    this.updateChart(true);
  }

  wealthChange(event, itemIndex) {
    this.answer.wealthCompositionItems[itemIndex] = event;
    this.updateChart();
  }

  updateChart(rebuild: boolean = false) {
    if (rebuild) {
      this.chart.destroy();
      this.buildPieChart(this.valuesForPieChart(), this.labels(), this.chartColors);
    }
    if (this.chart) {
      this.chart.data.datasets[0].data = this.valuesForPieChart();
      this.chart.data.labels = this.labels();
      if (this.answer.percentageDeclarationOnly) {
        this.chart.data.labels.push(this.translateService.instant('remaining_wealth'));
      }
      this.chart.update();
    }
  }

  labels() {
      if (this.answer.percentageDeclarationOnly) {
        return [...this.answer.wealthCompositionItems.map(x => x.labelKey), this.translateService.instant('remaining_wealth')];
      } else{
        return this.answer.wealthCompositionItems.map(x => x.labelKey);
      }
  }

  valuesForPieChart() {
    if (!this.answer.percentageDeclarationOnly) {
      return this.answer.wealthCompositionItems.map(x => x.value, 10);
    } else {
      const percentages = this.answer.wealthCompositionItems.map(x => x.percentage);
      let remainingWealth = 0;
      if(this.answer.evaluateTotalPercentage() > 0) {
        remainingWealth = 100 - percentages.reduce((a, b) => a + b, 0);
      }
      return [...percentages, remainingWealth];
    }
  }


  evaluateTotalWealth() {
    return this.answer.evaluateTotalWealth();
  }

  evaluateTotalPercentage() {
    return this.answer.evaluateTotalPercentage();
  }

  percentageColor() {
    if(this.evaluateTotalPercentage() === 100) {
      return this.colors.primaryText;
    } else {
      return this.colors.lose;
    }
  }

  currencyColor() {
    if(this.evaluateTotalWealth() !== 0) {
      return this.colors.primaryText;
    } else {
      return this.colors.lose;
    }
  }


  showLegend() {
    return (this.answer.evaluateTotalWealth() > 0) || (this.answer.evaluateTotalPercentage() > 0)
  }

  chartHovered(event: any) {}

  canTriggerUpdate() {
    return this.evaluateTotalWealth() > 0;
  }

  buildPieChart(data, labels, colors) {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            data,
            borderColor: '#FFF',
            backgroundColor: colors,
            fill: false,
          },
        ],
        labels,
      },
      options: {
        maintainAspectRatio: false,
        elements: {
          arc: { borderWidth: 2 },
        },
        title: { display: false },
        tooltips: {enabled: false },
        legend: {
          display: false,
          position: 'bottom',
          onClick: (e, legendItem) =>  false,
          labels : {
            fontSize : 14,
            fontColor : '#736251'
          }

        },
        scales: {
          xAxes: [{ display: false }],
          yAxes: [{ display: false }],
        },
      },
    });
  }

}
