<div class="np-knowledge-question-custom">
  <ui-title
    *ngIf="question.titleKey"
    class="d-block text-center mb-5"
    [size]="'large'"
    [text]="'custom-financial-knowledge.' + question.titleKey"
    [color]="colors?.primaryText">
  </ui-title>
</div>

<div *ngFor="let subQuestion of question.subQuestions; let j = index">
  <ui-title
    class="d-block text-center mb-4 mt-2"
    [text]="'custom-financial-knowledge.' + subQuestion"
    [color]="colors?.primaryText">
  </ui-title>

  <br>

  <div class="row justify-content-center">
    <div
      *ngFor="let option of shuffleOptions(j); let i = index"
      class="col-12 col-md-4 mb-4">

      <div class="card brown-bkg d-flex align-items-center mx-md-2 p-2 clickable"
           [class.active]="isActive(j, option)"
           (click)="onSelectOption(j, option)">
        <div style="overflow: hidden;">
          <p class="m-0 text-center">
            {{"custom-financial-knowledge." + option | translate }}
          </p>
        </div>
      </div>

      <br>
      <br>
      <br>
    </div>
  </div>
</div>
