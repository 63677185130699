import { Component, OnInit, Input, HostBinding } from '@angular/core';

import { ITextImageOption } from '../../types/options/text-image-option';
import { IMultipleOptionQuestion } from '../../types/questions/multiple-option-question';

import { IMultipleOptionAnswer } from '../../types/answers/multiple-option-answer';
import {ModalService} from 'neuroprofiler-ui-kit';

@Component({
  selector: 'app-indo-multiple-option-question',
  templateUrl: './indo-multiple-option-question.component.html',
  styleUrls: ['./indo-multiple-option-question.component.scss'],
})
export class IndoMultipleOptionQuestionComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly question: IMultipleOptionQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: IMultipleOptionAnswer;
  private selectedOptions: Array<string> = [];
  public _ms: any;

  constructor(ms: ModalService) {
    this._ms = ms;
  }

  ngOnInit() {
    this.selectedOptions = this.answer.optionIds;
  }

  isActive(option: ITextImageOption) {
    return this.isSelected(this.selectedOptions, option);
  }

  onUpdateResponse(option: ITextImageOption) {
    let selectedOptions = this.copy(this.selectedOptions);

    // Max scenario
    const currentSize = selectedOptions.length;
    const reachMaxLimit = currentSize == this.question.maxSelectedItems;

    if (this.isSelected(selectedOptions, option)) {
      selectedOptions = this.unsetSelected(selectedOptions, option);
    } else {
      if (!reachMaxLimit) {
        selectedOptions = this.setSelected(selectedOptions, option);
      }
    }

    selectedOptions.sort();
    this.selectedOptions = selectedOptions;
    this.answer.optionIds = this.selectedOptions;
  }

  private reachMaxLimit(value: number, limit: number) {
    return value == limit;
  }

  private isSelected(selectedOptions: Array<string>, option: ITextImageOption) {
    return (
      selectedOptions.filter((el: string) => {
        return option.optionId === el;
      }).length == 1
    );
  }

  private setSelected(selectedOptions: Array<string>, option: ITextImageOption) {
    return selectedOptions.concat([option.optionId]);
  }

  private unsetSelected(
    selectedOptions: Array<string>,
    option: ITextImageOption
  ) {
    return selectedOptions.filter((el: string) => {
      return option.optionId !== el;
    });
  }

  private copy(array: Array<string>) {
    return array.slice();
  }

  openModal(option: any) {
    const content = new Map();
    content.set('titleKey', option.titleKey);
    content.set('textKey', option.helperKey);
    content.set('nextKey', 'close');
    content.set('action', () => {});
    this._ms.open(content);
  }

  get assetsUrl() {
    return this.configuration.get('assets-url');
  }
}
