<div class="np-single-option-question-select">
  <ui-title
    class="text-center d-block mb-3"
    [text]="question.titleKey"
    [color]="colors?.primaryText">
  </ui-title>

  <ui-text
    class="text-center d-block mb-3"
    [render]="'legend'"
    [text]="question.subtitleKey"
    [color]="colors?.secondaryText">
  </ui-text>

  <div class="d-flex justify-content-center">
    <ng-select
      class="np-select-option w-50"
      [searchable]="false"
      [clearable]="false"
      [items]="question.options"
      [(ngModel)]="selectedOption"
      (change)="onOptionChanged($event)"
      [compareWith]="isSelectedOption"
      required>
      <ng-template ng-label-tmp ng-option-tmp let-opt="item">
        <div class="d-flex">
          <ui-rounded-image
            *ngIf="!!opt.defaultPictureAlias"
            class="d-block ml-2 mr-2 option-img"
            [active]="false"
            [defaultImage]="opt.defaultPictureAlias"
            [activeImage]="opt.activePictureAlias"
            [configuration]="configuration"
          >
          </ui-rounded-image>

          <ui-text
            class="option-title"
            [render]="'legend'"
            [weight]="'bold'"
            [text]="opt.titleKey"
            [color]="colors?.primaryText">
          </ui-text>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
