import { IMultipleOptionQuestion } from 'neuroprofiler-ui-kit';
import { TextImageOption } from '../options/text-image-option';
import { Answer } from '../answers/answer';
import {TranslateService} from '@ngx-translate/core';

export class MultipleOptionQuestion implements IMultipleOptionQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public nextItemId: string,
    public minSelectedItems: number,
    public maxSelectedItems: number,
    public options: TextImageOption[]
  ) {}

  public getNextQuestionIds(answer: any) {
    let selectedItemIds = answer.optionIds.sort().map(a => this.options.find(o => o.optionId === a).nextItemId).filter(oid => oid.length > 0);
    if (this.nextItemId != "") {
      selectedItemIds.push(this.nextItemId);
    }
    return selectedItemIds;
  }

  public getOptionsForAnswer(answer: any) {
    return answer.optionIds.sort().map(oid => this.options.find(o => o.optionId === oid));
  }

  public canContinue(answer: any) {
    return true;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": this.getOptionsForAnswer(answer).map(o => o.titleKey)
    };
  }

  public answerToString(answer, configuration, translate: TranslateService) {
    return this.getOptionsForAnswer(answer).map(o => o.titleKey);
  }
}
