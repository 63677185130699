import { IOrderedOptionAndTextQuestion } from 'neuroprofiler-ui-kit';

import { TextImageOption } from '../options/text-image-option';
import { MultipleOptionAndTextQuestion } from './multiple-option-and-text-question';
import {TranslateService} from '@ngx-translate/core';
import {MultipleOptionAndTextAnswer} from '../answers/multiple-option-and-text-answer';

export class OrderedOptionAndTextQuestion
  extends MultipleOptionAndTextQuestion
  implements IOrderedOptionAndTextQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public nextItemId: string,
    public minSelectedItems: number,
    public maxSelectedItems: number,
    public canSpecify: boolean[],
    public options: TextImageOption[]){
    super(kind, titleKey, subtitleKey, helperKey, nextItemId, minSelectedItems,
      maxSelectedItems, canSpecify, options)
  }

  public answerToString(answer, configuration, translate: TranslateService): string[] {
    return (answer as MultipleOptionAndTextAnswer).optionIdsAndText.map((x, i) => {
      return (i + 1) + '. ' + translate.instant(x[1]);
    });

  }
}
