import { IRiskOption } from 'neuroprofiler-ui-kit';

import { BinaryGamble } from './binary-gamble';

export class RiskOption implements IRiskOption {

	constructor(
		public optionId: string,
		public titleKey: string,
		public helperKey: string,
		public binaryGamble:any,
		public nextItemId: string,
    public attributes: [string, [string, number][]][]) {}

	public static FromJson(inputJson: any){
		return new RiskOption(
			inputJson.option_id,
			inputJson.title_key,
			inputJson.helper_key,
			BinaryGamble.FromJson(inputJson.binary_gamble),
      inputJson.next_item_id,
      inputJson.attributes)
	}

	public static FromJsonAndScale(inputJson, factor){

    let binaryGamble = BinaryGamble.FromJson(inputJson.binary_gamble);
    binaryGamble.Scale(factor);
    
    return new RiskOption(
			inputJson.option_id,
			inputJson.title_key,
			inputJson.helper_key,
			binaryGamble,
      inputJson.next_item_id,
      inputJson.attributes)
	}
}
