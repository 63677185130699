import { AnswerPDFExporter } from "./answer-pdf-exporter";
import { QuestionnaireHistory } from "../../questionnaire/questionnaire-history";
import { QuestionnaireResults } from "../questionnaire-results";

export class AnswerPDFExporterCFM extends AnswerPDFExporter {

  public constructor(
    protected _singleOptionsAnswerIds: string[],
    protected _singleValuesToIntervalId: {[index: string]: [string, string][]},
    protected _assetClasses: string[],
    protected _assetExperiences: string[],
    protected _wealthCompositionAnswerId: string,
    protected _financialKnowledgeAnswerId: string,
    protected _financialExperienceAnswserId: string,
    protected _lossAversionQuestionKey: string,
    protected _lossAversionToStringMapping: [string, string][] ) {
    super(_singleOptionsAnswerIds, 
      _singleValuesToIntervalId, 
      _assetClasses,
      _assetExperiences,
      _wealthCompositionAnswerId, 
      _financialKnowledgeAnswerId,
      _financialExperienceAnswserId,
      _lossAversionQuestionKey,
      _lossAversionToStringMapping);
  }; 

  public createMapPDF(questionnaireResults: QuestionnaireResults, 
                   financialProfessional: boolean) {

    const questionnaireHistory = questionnaireResults.questionnaireHistory;

    return new Map([
      ...this._buildSingleOptionRecord(questionnaireHistory,
                                       this._singleOptionsAnswerIds),
      ...this._buildSingleValueRecord(questionnaireHistory),
      ...this._buildPortfolioRecord(questionnaireHistory),
      ...this._buildAssetListRecord(questionnaireHistory, 
                                    this._financialKnowledgeAnswerId,
                                    financialProfessional),
      ...this._buildAssetListRecord(questionnaireHistory, 
                                    this._financialExperienceAnswserId,
                                    financialProfessional),
      ...this._buildLossAversionRecord(questionnaireResults)
    ]);
  }

}
