<div
  *ngIf="!isTerminated()">
  <div
    class="np-page container-fluid">
    <!-- with-background-color -->
    <header>
      <nav class="navbar np-navbar">
        <a class="navbar-brand" href="#">
          <img
            class='np-logo'
            src="{{ assetsUrl }}/logo.jpg">
        </a>
      </nav>

      <!-- Modal -->
      <ui-modal [colors]="colors"></ui-modal>
      <div class="row d-flex justify-content-center indo-progress">
        <div class="col-sm-12 col-md-6 d-flex justify-content-center">
          <app-indo-progress-bar
            [current]="questionsCount() - 1"
            [total]="questionnaire.currentItem.progressBar.maxNQuestions"
            [sectionName]="'progress-bar.'+questionnaire.currentItem.progressBar.GetCurrentTextKey() | translate"
            [colors]="colors"
          ></app-indo-progress-bar>
        </div>
      </div>

      <div
        class="w-100 d-flex navigation" *ngIf="canPrevious">
        <ui-back
          [ngClass]="canPrevious ? 'visible': 'invisible' "
          [text]="'back'"
          [colors]="colors"
          (action)="previousBtnClick()">
        </ui-back>
      </div>

    </header>

    <!-- question -->
    <section class="quiz d-flex flex-column">
      <div [ngSwitch]="kind"
        class="question-container pl-1 pr-1 pl-md-0 pr-md-0 mx-auto
          d-flex flex-column justify-content-center align-items-center limited-width">

        <app-questions
          [titleKey]="questionnaire.currentItem.titleKey"
          [(questions)]="questionnaire.currentItem.questions"
          [(answers)]="questionnaire.currentItem.answers"
          [configuration]="configuration"
          [continueClick]="continue"
          [colors]="colors"
          [questionnaireHistory]="questionnaireHistory"
          [@enterTrigger]="'fadeIn'"
        >
        </app-questions>

        <!-- FIXME use of `*ngIf` -->
        <div
          class="w-100 d-flex navigation justify-content-center"
          *ngIf="kind !== 'risk_question' && kind !== 'text_only_question'"
          [class.invisible]="kind == 'risk_question' || kind == 'text_only_question'">

          <button class="btn indo-btn"
                  *ngIf="canSkip()"
                  [ngStyle]="{backgroundColor: colors?.primaryBackground}"
                  (click)="skipBtnClick()">
            {{ 'skip' | translate }}
          </button>

          <button class="btn indo-btn"
                  [ngStyle]="{backgroundColor: colors?.primaryBackground}"
                  [disabled]="!canContinue"
                  (click)="continueBtnClick()">
            {{ 'continue' | translate }}
          </button>


        </div>

        <!--
        <div
          class="w-100 d-flex navigation helperKey" >
          <ui-back
            [ngClass]="'visible'"
            [text]="'skip'"
            [colors]="colors"
            (action)="questionnaireService.replayJson()">
          </ui-back>
        </div>
        -->

        <!-- Help text -->
        <ng-container *ngIf="helperKey">
          <ui-help-me
            [text]="helperKey"
            [configuration]="configuration"
            [colors]="colors">
          </ui-help-me>
        </ng-container>

      </div>
    </section>

  </div>
</div>
