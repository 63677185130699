<div>
  <div>
    <ui-title
      class="d-block text-center mb-5"
      [render]="'title'"
      [size]="'large'"
      [text]="title"
      [color]="colors?.primaryText"
    >
    </ui-title>
    <ui-title
      class="d-block text-center font-italic font-weight-normal mb-4"
      [render]="'subtitle'"
      [size]="'large'"
      [text]="subtitle"
      [color]="colors?.primaryText"
    >
    </ui-title>

  </div>
  <div class="d-flex justify-content-end w-100 mb-4">
    <div class="w-50 d-flex justify-content-center align-items-center">
      <h3 class="py-1 px-5 m-0 d-inline" [ngStyle]="{backgroundColor: colors?.primaryBackground, color: colors?.reverseText}">
        {{riskProfile | translate}} 
      </h3>
    </div>

    <div class="w-25 d-flex justify-content-end align-items-center">
      <button class="btn-help" (click)="isCollapsed = !isCollapsed">?</button>
    </div>
  </div>

  <ui-title
    class="d-block text-center font-weight-normal mx-5 mb-3"
    [render]="'subtitle'"
    [size]="'normal'"
    [text]="riskTitle"
    [color]="colors?.primaryText"
  >
  </ui-title>
  <ui-text
    class="d-block text-center font-weight-normal mb-2"
    [render]="'text'"
    [text]="riskText"
    [color]="colors?.primaryText"
  ></ui-text>
  <ui-text
    class="d-block text-center font-weight-normal mb-2"
    [render]="'text'"
    [text]="riskDetails"
    [color]="colors?.primaryText"
  ></ui-text>

  <div class="collapsable" [class.collapse]="isCollapsed">
    <div class="row">
      <div class="col-sm-10">
        <ui-text
          class="d-block text-center font-weight-normal mb-2"
          [render]="'text'"
          weight="bold"
          [text]="'question_key'"
          [color]="colors?.primaryText"
        ></ui-text>
      </div>
      <div class="col-sm-2">
        <ui-text
          class="d-block text-center"
          [render]="'text'"
          weight="bold"
          [text]="'scoring_key'"
          [color]="colors?.primaryText"
        ></ui-text>
      </div>
    </div>
    <div *ngFor="let questionAnswer of questionsAnswers">
      <div class="row">
        <div class="col-sm-10">
          <ui-text
            class="d-block text-center"
            [render]="'text'"
            [text]="questionAnswer[0]"
            [color]="colors?.primaryText"
          ></ui-text>
        </div>
        <div class="col-sm-2">
          <p
            [ngStyle]="{color:colors?.primaryText}"
          >{{questionAnswer[1]}}</p>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>
