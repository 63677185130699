import {Component, OnInit, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {IKnowledgeQuestion} from '../../types/questions/knowledge-question';
import {IKnowledgeAnswer} from '../../types/answers/knowledge-answer';
import {ITextImageOption} from '../../types/options/text-image-option';

@Component({
  selector: 'app-indo-knowledge-question',
  templateUrl: './indo-knowledge-question.component.html',
  styleUrls: ['./indo-knowledge-question.component.scss']
})
export class IndoKnowledgeQuestionComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly question: IKnowledgeQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: IKnowledgeAnswer;
  private selected: Map<string, any>;
  confidenceChoices: Array<string>;

  constructor() {
    this.confidenceChoices = ['sad', 'normal', 'happy'];
    this.selected = new Map();
    this.selected.set('option', null);
    this.selected.set('confidence', null);
  }

  ngOnInit() {}

  isActive(option: ITextImageOption) {
    const selectedOption = this.selectedOption;
    return selectedOption && (option.optionId === selectedOption.optionId)
  }

  onSelectOption(option: ITextImageOption) {
    this.selected.set('option', option);
    this.answer.optionId = this.selectedOption.optionId;
    this.answer.confidenceLevel = 1;
  }

  get selectedOption(): ITextImageOption | null {
    const option: ITextImageOption | null = this.selected.get('option');
    return option;
  }

}
