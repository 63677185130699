import {Component, Input, OnInit} from '@angular/core';
import { IQuestionAnswersData } from "../question-answers-data";

@Component({
  selector: 'app-question-answer-history',
  templateUrl: './question-answer-history.component.html',
  styleUrls: ['./question-answer-history.component.scss']
})
export class QuestionAnswerHistoryComponent implements OnInit {
  @Input() questionsAnswers: any[];
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  constructor() { }

  ngOnInit() {
    console.log(this.questionsAnswers);
  }

  get locale() {
    return this.configuration.get('locale');
  }

  get mockTemplateTest() {
    return {
      lang: this.locale,
      currency: this.configuration.get('currency-symbol'),
      currency_code: this.configuration.get('currency')
    };
  }
}
