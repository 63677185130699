import { IKnowledgeQuestion } from 'neuroprofiler-ui-kit';
import { TextImageOption } from '../options/text-image-option';
import { SingleOptionQuestion } from './single-option-question';
import { Answer } from '../answers/answer';
import {TranslateService} from '@ngx-translate/core';

export class KnowledgeQuestion
  extends SingleOptionQuestion
  implements IKnowledgeQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public options: TextImageOption[]
  ) {
    super(kind, titleKey, subtitleKey, helperKey, 'square', options);
  }
  public getOptionsForAnswer(answer: any) {
    return answer.optionIds.map(oid => this.options.find(o => o.optionId === oid));
  }

  public canContinue(answer: any) {
    const optionSelected = (this.options.map(o => o.optionId).indexOf(answer.optionId) > -1);
    const confidenceSelected = answer.confidenceLevel > -1;
    return optionSelected && confidenceSelected;
  }

  public answerToString(answer, configuration, translate: TranslateService) {
    return this.getOptionsForAnswer(answer).map(o => o.titleKey);
  }
}
