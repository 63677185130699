<div class="np-single-option-question-square">
  <ui-title
    class="text-center d-block mb-3 mb-md-5 mb-lg-5 "
    [text]="question.titleKey"
    [color]="colors?.primaryText">
  </ui-title>
  <ui-text
    class="text-center d-block mb-3"
    [render]="'legend'"
    [text]="question.subtitleKey"
    [color]="colors?.secondaryText">
  </ui-text>
  <div class="icons">
    <div class="row justify-content-center px-4">
      <ng-container *ngFor="let option of question.options">
        <div class="col-6 col-md-auto card border-0 unapply"
             [class.col-lg-3]="question.options.length === 2"
             [class.col-md-3]="question.options.length === 2"
        >

          <div
            class="card_block h-100 px-4 mb-3 mb-md-5 mb-lg-5 clickable"
            [class.clickable]="question.options.length != 2"
            [class.brown-bkg]="brownBackground"
            [class.selected]="isActive(option)"
            (click)="onUpdateResponse(option)">
            <ng-container *ngIf="option.activePictureAlias.length > 0">
              <img
                [src]="assetsUrl + '/library/icons/' + (isActive(option)  ? option.defaultPictureAlias : option.activePictureAlias)"
                class="card-img-top"
                [class.medium-small-img]="isYesNoQuestion"
                [class.medium-img]="question.options.length != 2">
              <ng-container *ngIf="showOptionTitle">
                <div class="">
                  <hr>
                  <p class="option_text card-text mb-2"
                     [class.limited-text]="question.options.length > 4"
                     [ngStyle]="{color: (brownBackground ||isActive(option) ) ? colors?.reverseText : colors?.primaryText}" [innerHTML]="option.titleKey | translate: {currency: configuration.get('currency-symbol') } | npCurrencyInterpolation: configuration"></p>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="option.activePictureAlias.length == 0">
              <div class="card-body">
                <ng-container *ngIf="question.options.length != 2">
                  <br>
                  <b>
                    <h3 class="option_text card-title" [innerHTML]="option.titleKey | translate | npCurrencyInterpolation: configuration"></h3>
                  </b>
                </ng-container>
                <ng-container *ngIf="question.options.length == 2">
                  <p class="option_text card-text"> {{ option.titleKey | translate | npCurrencyInterpolation: configuration }} </p>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</div>
