import { IKnowledgeAnswer } from 'neuroprofiler-ui-kit';

export class KnowledgeAnswer implements IKnowledgeAnswer {

	public constructor(
		public id: string,
		public kind: string,
		public optionId: string,
    public confidenceLevel: number,
    public skipped: boolean){}

}
