import {Component, OnInit, OnDestroy } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import {Router} from '@angular/router';

// FIXME try to avoid service workaround
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WorkaroundValidationService, HelpService } from 'neuroprofiler-ui-kit';
import { SingleOptionAnswer } from 'src/app/answers/single-option-answer';
// import { QuestionnaireItem } from "../questionnaire-item";
import { AnswerPrefiller } from '../../answers/answer-prefiller';
import { SessionInfo } from '../../session-info';
import { Question } from "../../questions/question";
import { QuestionnaireResults } from '../../results/questionnaire-results';
import {QuestionnaireService} from '../questionnaire-service';
import {QuestionnaireHistory} from '../questionnaire-history';
import {Questionnaire} from '../questionnaire';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ConfigurationService} from '../../configuration.service';
import { QuestionnaireHelperMP } from "../questionnaire-helper-mp";
import { Answer } from "../../answers/answer";
import { QuestionAnswerMappingPrefiller } from "../question-answer-mapping-prefiller";


@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  animations: [
    trigger('enterTrigger', [
      state('fadeIn', style({
        opacity: '1',
      })),
      transition('void => *', [style({opacity: '0'}), animate('500ms')])
    ])
  ]
})
export class QuestionnaireComponent implements OnInit, OnDestroy {

  private $destroy: Subject<boolean> = new Subject<boolean>(); // FIXME

  // colors
  colors: any;

  // configuration
  configuration: Map<string, string|boolean>;

  private answerPrefiller: AnswerPrefiller;

  constructor(
    private configurationService: ConfigurationService,
    public questionnaireService: QuestionnaireService,
    private _workaroundValidationService: WorkaroundValidationService,
    private _hs: HelpService,
    private translate: TranslateService,
    private router: Router
  ) {
    // Colors
    this.colors = Object.freeze({
      // Default colors set
      primaryBackground: '#736251',
      secondaryBackground: '#b7a998',
      inactiveBackground: '#F1F9FF',

      text: '#736251',
      primaryText: '#736251',
      secondaryText: '#736251',
      inactiveText: '#C7C7C7',
      reverseText: '#FFFFFF',

      // Risk test colors set
      darkWin: '#736251',
      lightWin: '#d6c8b6',
      loosing: '#01b4ec',
      win: '#1CB841',
      smallWin: '#117028',
      lose: '#F47373',
      smallLose: '#903a3d',
      nothing: '#303035',
      selection: '#20b860'
    });

    // Configuration
    this.configuration = this.configurationService.configuration;

    // @see https://angular.io/api/common/getCurrencySymbol


    // FIXME try to avoid service workaround
    //this._rs.subscription.pipe(takeUntil(this.$destroy)).subscribe(
    //  (newState) => {
    //    if (newState.value) {
    //      if (this.questionnaire.currentItem.questions[0].kind === 'risk_question') {
    //        const answer = new SingleOptionAnswer(
    //          this.questionnaire.currentItem.answers[0].id,
    //          this.questionnaire.currentItem.answers[0].kind,
    //          newState.value.optionId
    //        );
    //        this.questionnaire.currentItem.answers[0] = answer;

    //        this.continueBtnClick();
    //      }
    //    }
    //  },
    //  (error) => {
    //    console.error('[rs] Error', error);
    //  },
    //  () => {
    //    console.info('[rs] no more event');
    //  }
    //);
    this._workaroundValidationService.subscription
      .pipe(takeUntil(this.$destroy))
      .subscribe((newState) => {
        if (newState.value) {
          if (this.questionnaire.currentItem.questions[0].kind === 'risk_question') {
            console.log(this.questionnaire.currentItem);
            const answer = new SingleOptionAnswer(
              this.questionnaire.currentItem.answers[0].id,
              this.questionnaire.currentItem.answers[0].kind,
              newState.value.optionId,
              this.questionnaire.currentItem.answers[0].skipped,
            );
            this.questionnaire.currentItem.answers[0] = answer;

            this.continueBtnClick();
          }
          // prevent to receive old value when doing "back"
          this._workaroundValidationService.next(null);
        }
      });
  }
  
  ngOnInit() {
    this.questionnaireHistory.AddItem(this.questionnaire.currentItem);

    this.answerPrefiller = new AnswerPrefiller();

    this.answerPrefiller.Add(
      new SingleOptionAnswer('language_answer', 'single_option_answer', SessionInfo.language, false));

    
    //const questionAnswerMappingPrefiller = new QuestionAnswerMappingPrefiller()
    //questionAnswerMappingPrefiller.populatePrefiller(
    //  SessionInfo.cesoType,
    //  new Map(),
    //  this.answerPrefiller);

    //console.log(this.answerPrefiller);

    this.prefillAnswers();
  }

  get questionnaire(): Questionnaire {
    return this.questionnaireService.questionnaire;
  }

  get questionnaireHistory(): QuestionnaireHistory {
    return this.questionnaireService.history;
  }

  get kind() {
    return this.questionnaire.currentItem.questions[0].kind;
  }

  get helperKey() {
    const q = this.questionnaire.currentItem.questions.find((question: Question) => {
      return !!question.helperKey
    })
    return q ? q.helperKey : false;
  }

  get canContinue() {
    return this.questionnaire.canContinue(this.questionnaire.currentItem.answers);
  }

  get canPrevious() {
    return this.questionnaireHistory.Length() > 1;
  }

  get assetsUrl() {
    return this.configuration.get('assets-url');
  }

  get whiteLabel() {
    return this.configuration.get('white-label');
  }

  prefillAnswers() {
    this.questionnaire.currentItem.answers =
      this.questionnaire.currentItem.answers.map(
        a => this.answerPrefiller.UpdateAnswerIfPossible(a));

  }

  exectuteCallback(answer: any) {
    if (answer.id === 'language_answer') {
      this.translate.use(answer.optionId);
      this.configuration.set('locale',  answer.optionId);
      SessionInfo.language = answer.optionId;

      this.configuration.set(
        'currency-symbol',
        getCurrencySymbol(
          this.configuration.get('currency') as string,
          'narrow',
          this.configuration.get('locale') as string
        )
      );
    }
    if (answer.id === 'wealth_composition_answer') {

      let financialPartOfWealthOptionId = "";
      let financialPartOfWealth = answer.evaluateFinancialWealthPercentage();
      if(financialPartOfWealth >= 0) {
        financialPartOfWealthOptionId = "less_than_10_percent";
      }
      if(financialPartOfWealth >= 10) {
        financialPartOfWealthOptionId = "between_10_50_percent";
      } 
      if(financialPartOfWealth >= 50) {
        financialPartOfWealthOptionId = "between_50_80_percent";
      }
      if(financialPartOfWealth > 80) {
        financialPartOfWealthOptionId = "more_than_80_percent";
      }

      this.answerPrefiller.Add(
        new SingleOptionAnswer("financial_part_of_wealth",
         "single_option_answer",financialPartOfWealthOptionId, false)
      )

      // we can deduce the total wealth as a sum of the components
      if(!(answer.percentageDeclarationOnly)) {
        let totalWealthOptionId = "less_than_1m";
        let totalWealth = answer.evaluateTotalWealth();
        if(totalWealth >= 1E6) {
          totalWealthOptionId = "between_1m_and_5m";
        }
        if(totalWealth >= 5 * 1E6) {
          totalWealthOptionId = "between_5m_and_10m";
        }
        if(totalWealth > 1E7) {
          totalWealthOptionId = "more_than_10m";
        }

        this.answerPrefiller.Add(
          new SingleOptionAnswer("total_wealth_interval",
           "single_option_answer",totalWealthOptionId, false)
        )
      }
    }
    if (answer.id === 'turnover_total') {
      if(QuestionnaireHelperMP.suggestFinancialProfessional(this.questionnaireHistory)) {
        this.answerPrefiller.Add(
          new SingleOptionAnswer('categorized_as_professional_client', 'single_option_answer', 'yes', false));
      } else {
        this.answerPrefiller.Add(
          new SingleOptionAnswer('categorized_as_professional_client', 'single_option_answer', 'no', false));
      }
    }
  }

  get continue() {
    return this.continueBtnClick.bind(this);
  }

  private _setAnswersAsSkipped() {
    this.questionnaire.currentItem.answers.map(
      (answer: Answer) => (answer.skipped = true)
    );
  }

  canSkip() {
    return SessionInfo.country === "ch" && this.questionnaire.currentItem.questionnaireItemId === "wealth_composition";
  }

  skipBtnClick() {
    this._setAnswersAsSkipped();
    this.continueBtnClick();
  }

  continueBtnClick() {
    //console.info('[continueBtnClick] answers', this.questionnaire.currentItem.answers);

    const answers = this.questionnaire.currentItem.answers;
    this.questionnaire.nextItem(answers, this.questionnaireHistory);

    answers.map(a => this.exectuteCallback(a));
    this.prefillAnswers();

    //TODO: temporary fix, refactor questionnaire + history as single object
    if (!this.questionnaire.isTerminated) {
      this.questionnaireHistory.AddItem(this.questionnaire.currentItem);
    }

    // force close sidebar
    this._hs.toggle();

    // force top
    this.goToTop();
    //console.log(this.questionnaire.currentItem.questions);
    //console.info('[continueBtnClick] questionnaire', this.questionnaire);
    //console.info('[continueBtnClick] questionnaireHistory', this.questionnaireHistory);
    if (this.questionnaire.isTerminated) {
      this.router.navigate(['results']);
    }
  }


  previousBtnClick() {
    this.questionnaireHistory.Pop(); // gets rid of the current item
    this.replayHistory();

    // force close sidebar
    this._hs.toggle();

    // force top
    this.goToTop();

    //console.info('[previousBtnClick] questionnaireHistory', this.questionnaireHistory);
  }


  replayHistory() {
    this.questionnaireService.replayHistory();
  }

  questionsCount() {
    return this.questionnaireHistory.Length();
  }


  isTerminated() {
    return this.questionnaire.isTerminated;
  }

  buildQuestionnaireResults() {
    return new QuestionnaireResults(this.questionnaireHistory);
  }

  private goToTop() {
    window.scrollTo(0, 0);
  }

  // FIXME try to avoid service workaround
  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.unsubscribe();
  }

}
