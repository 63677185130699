import {TranslateService} from '@ngx-translate/core';

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;


  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

// FIXME
const cloneJson = (json: any) => JSON.parse(JSON.stringify(json));

const _canContinue = (subQuestions: Array<string>, options: Array<string>) => {
  return (answer: any) => {
    const answers = cloneJson(answer.answers);
    if (answers.length !== subQuestions.length) return false;
    return answer.answers.every((answer: string) => {
      return options.indexOf(answer) !== -1;
    });
  }
}

import { IIndoKnowledgeCustomQuestion } from '../../types/questions/indo-knowledge-custom-question';
import { IndoKnowledgeCustomAnswer } from '../../../answers/indo-knowledge-custom-answer';
import { Answer } from "../../../answers/answer";

export class IndoKnowledgeCustomQuestion implements IIndoKnowledgeCustomQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public questionsAnswersItemKey: string,
    public questionsAnswersItem: any,
    public correctOption: any,
    public wrongOption: any,
    public options: Array<string>
  ) {}

  public canContinue = _canContinue(this.subQuestions, this.options);

  public getNextQuestionIds(answer: any): Array<string> {
    if (arraysEqual(answer.answers, this.questionsAnswersItem.correct_answers)) {
      if (this.correctOption.next_question_id !== '') {
        return [this.correctOption.next_question_id];
      } else {
        return [];
      }
    } else {
      if (this.wrongOption.next_question_id !== '') {
        return [this.wrongOption.next_question_id];
      } else {
        return [];
      }
    }
  }

  get subQuestions() {
    return this.questionsAnswersItem.questions;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "subQuestions": this.subQuestions,
      "answers": (answer as IndoKnowledgeCustomAnswer).answers
    };
  }

  public answerToString(answer, configuration, translate: TranslateService) {
    return ['custom-financial-knowledge.' + answer];
  }

}
