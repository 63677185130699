import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-indo-progress-bar',
  templateUrl: './indo-progress-bar.component.html',
  styleUrls: ['./indo-progress-bar.component.scss']
})
export class IndoProgressBarComponent implements OnInit {
  @Input() numberOfBullets = 10;
  @Input() current: number;
  @Input() total: number;
  @Input() sectionName: string;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  constructor() { }

  ngOnInit() {
  }

  currentPercentage() {
    return Math.round((this.current * 100) / this.total);
  }

  bulletPercent(index) {
    return (index * 100) / this.numberOfBullets;
  }

  get pastBulletsNumber(): number {
    return Math.max(0, Math.round(this.currentPercentage() * this.numberOfBullets / 100) - 1);
  }

  get futureBulletsNumber() {
    return this.numberOfBullets - this.pastBulletsNumber - 1;
  }

  arrayOfBullets(bulletsNumber) {
    return new Array(bulletsNumber);
  }

}
