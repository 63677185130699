import { SessionInfo } from "../session-info";

import miscQuestionnairePPJson from
'../../assets/static/questionnaire/misc_questionnaire_pp.json';

import miscQuestionnairePPCFMJson from
'../../assets/static/questionnaire/misc_questionnaire_pp_cfm.json';

import miscQuestionnaireMPJson from
'../../assets/static/questionnaire/misc_questionnaire_mp.json';

import miscQuestionnaireMandaJson from 
'../../assets/static/questionnaire/misc_questionnaire_manda.json';

import knowledgeQuestionnaireJson from
'../../assets/static/questionnaire/knowledge_questionnaire.json';

import knowledgeQuestionnaireCFMJson from
'../../assets/static/questionnaire/knowledge_questionnaire_cfm.json';

import riskQuestionnaireJson from
'../../assets/static/questionnaire/risk_questionnaire.json';

import investementObjectivePPJson from
'../../assets/static/questionnaire/investment_objectives_pp.json';

import investementObjectiveMPJson from
'../../assets/static/questionnaire/investment_objectives_mp.json'

import assetExperienceJson from
'../../assets/static/questionnaire/asset_experience.json';

import assetExperienceCFMJson from
'../../assets/static/questionnaire/asset_experience_cfm.json';

import categorizedAsProfessionalClient from 
'../../assets/static/questionnaire/categorized_as_professional_client.json';

import categorizedAsProfessionalClientCFM from 
'../../assets/static/questionnaire/categorized_as_professional_client_cfm.json';

import beginPPJson from 
'../../assets/static/questionnaire/begin_pp.json';

import beginMPJson from 
'../../assets/static/questionnaire/begin_mp.json';

import beginMandaJson from 
'../../assets/static/questionnaire/begin_manda.json';

import beginPPCFMJson from 
'../../assets/static/questionnaire/begin_pp_cfm.json';

import beginMPCFMJson from 
'../../assets/static/questionnaire/begin_mp_cfm.json';

import beginMandaCFMJson from 
'../../assets/static/questionnaire/begin_manda_cfm.json';

import revenuesExpensesJson from 
'../../assets/static/questionnaire/revenu_expenses.json';

import revenuesExpensesCFMJson from 
'../../assets/static/questionnaire/revenu_expenses_cfm.json';

import financialsMPJson from
'../../assets/static/questionnaire/financials_mp.json';

import financialsMPCFMJson from
'../../assets/static/questionnaire/financials_mp_cfm.json';

import esgMPPP from
'../../assets/static/questionnaire/esg_mp_pp.json';

import esgManda from
'../../assets/static/questionnaire/esg_manda.json';


export class QuestionnaireBuilder {

  constructor() {}

  public build() {
    
    const specialFk = ['ch', 'mc'].indexOf(SessionInfo.country) !== -1;

    if (specialFk){

      if (SessionInfo.cesoType === "mp") {
        return this._buildMPCFM();
      } else if (SessionInfo.cesoType === "pp") {
        return this._buildPPCFM();
      } else if (SessionInfo.cesoType === "manda") {
        return this._buildMandaCFM()
      }
    } else {
      if (SessionInfo.cesoType === "mp") {
        return this._buildMPIndo();
      } else if (SessionInfo.cesoType === "pp") {
        return this._buildPPIndo();
      } else if (SessionInfo.cesoType === "manda") {
        return this._buildMandaIndo()
      }

    }
  }

  private _buildMPIndo() {
    return [].concat.apply([], [
      miscQuestionnaireMPJson,
      beginMPJson,
      categorizedAsProfessionalClient,
      financialsMPJson,
      assetExperienceJson,
      knowledgeQuestionnaireJson,
      riskQuestionnaireJson,
      investementObjectiveMPJson,
      esgMPPP
    ]);
  }

  private _buildPPIndo() {
    return [].concat.apply([], [
      miscQuestionnairePPJson,
      beginPPJson,
      revenuesExpensesJson,
      categorizedAsProfessionalClient,
      assetExperienceJson,
      knowledgeQuestionnaireJson,
      riskQuestionnaireJson,
      investementObjectivePPJson,
      esgMPPP
    ]);
  }

  private _buildMandaIndo() {
    return [].concat.apply([], [
      miscQuestionnaireMandaJson,
      beginMandaJson,
      categorizedAsProfessionalClient,
      assetExperienceJson,
      knowledgeQuestionnaireJson
    ]);

  }

  private _buildMPCFM() {
    return [].concat.apply([], [
      miscQuestionnaireMPJson,
      beginMPCFMJson,
      financialsMPCFMJson,
      categorizedAsProfessionalClientCFM,
      assetExperienceCFMJson,
      knowledgeQuestionnaireCFMJson,
      riskQuestionnaireJson,
      investementObjectiveMPJson
    ]);
  }

  private _buildPPCFM() {
    return [].concat.apply([], [
      miscQuestionnairePPCFMJson,
      beginPPCFMJson,
      revenuesExpensesCFMJson,
      categorizedAsProfessionalClientCFM,
      assetExperienceCFMJson,
      knowledgeQuestionnaireCFMJson,
      riskQuestionnaireJson,
      investementObjectivePPJson
    ]);
  }

  private _buildMandaCFM() {
    return [].concat.apply([], [
      miscQuestionnaireMandaJson,
      beginMandaCFMJson,
      categorizedAsProfessionalClientCFM,
      assetExperienceCFMJson,
      knowledgeQuestionnaireCFMJson
    ]);

  }
}
