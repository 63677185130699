import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseInt',
})
export class ParseIntPipe implements PipeTransform {
  transform(value: string, _args?: any): any {
    return parseInt(
      (value.replace(/\s/g, '').match(/\d/g) || [0]).join(''),
      10
    );
  }
}
