<div class="np-single-option-question-rounded">
  <ui-title
    class="text-center d-block mb-3"
    [text]="question.titleKey"
    [color]="colors?.primaryText">
  </ui-title>

  <ui-text
    class="text-center d-block mb-3"
    [render]="'legend'"
    [text]="question.subtitleKey"
    [color]="colors?.secondaryText">
  </ui-text>

  <div class="row justify-content-center">
    <ng-container *ngFor="let option of question.options">
      <div class="col-md-2"
        [ngClass]="question.options.length == 4 ? 'col-3' : 'col-4'">
        <div class="d-flex justify-content-center align-items-center item">
          <button
            type="button"
            (click)="onUpdateResponse(option)"
            class="btn option"
            [class.active]="option.optionId == answer.optionId">
            <ui-rounded-image
              class="image mx-auto"
              [active]="option.optionId == answer.optionId"
              [defaultImage]="option.defaultPictureAlias"
              [activeImage]="option.activePictureAlias"
              [configuration]="configuration">
            </ui-rounded-image>
            <ui-text
              class="text-center text"
              [render]="'legend'"
              [text]="option.titleKey"
              [color]="colors?.secondaryText">
            </ui-text>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
