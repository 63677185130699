import financialKnowledgeDetailedQuestions from '../../../assets/static/questionnaire/financial_knowledge_detailed_questions.json'
import { IndoKnowledgeCustomAnswer } from "../../answers/indo-knowledge-custom-answer"; 
import { IndoKnowledgeCustomQuestion } from "../../questions/indosuez-components/indo-knowledge-custom-question/indo-knowledge-custom-question";
import { MultipleOptionAnswer } from "../../answers/multiple-option-answer";
import { QuestionnaireHistory } from "../../questionnaire/questionnaire-history";
import { QuestionnaireResults } from "../questionnaire-results";
import { SingleOptionAnswer } from "../../answers/single-option-answer";
import { SingleValueAnswer } from "../../answers/single-value-answer";
import { WealthCompositionIndoAnswer } from "../../answers/wealth-composition-indo-answer";

export class AnswerPDFExporter {

  protected _checkboxEmptyCode: string = "Off";
  protected _checkboxFilledCode: string = "On";
  protected _qaSplitter: string = "-";

  public constructor(
    protected _singleOptionsAnswerIds: string[],
    protected _singleValuesToIntervalId: {[index: string]: [string, string][]},
    protected _assetClasses: string[],
    protected _assetExperiences: string[],
    protected _wealthCompositionAnswerId: string,
    protected _financialKnowledgeAnswerId: string,
    protected _financialExperienceAnswserId: string,
    protected _lossAversionQuestionKey: string,
    protected _lossAversionToStringMapping: [string, string][] ) {}; 

  public createMapPDF(questionnaireResults: QuestionnaireResults,
                   financialProfessional: boolean) {
      
    const questionnaireHistory = questionnaireResults.questionnaireHistory;

    return new Map([
      ...this._buildSingleOptionRecord(questionnaireHistory,
                                       this._singleOptionsAnswerIds),
      ...this._buildSingleValueRecord(questionnaireHistory),
      ...this._buildPortfolioRecord(questionnaireHistory),
      ...this._buildAssetExperienceRecord(questionnaireHistory,
                                          this._assetExperiences,
                                          "no"),
      ...this._buildLossAversionRecord(questionnaireResults),
      ...this._buildFinancialKnowledgeRecord(questionnaireHistory,
                                            financialProfessional)
    ]);
  }

  public createMap(questionnaireResults: QuestionnaireResults,
                   financialProfessional: boolean) {

     const pdfMap = this.createMapPDF(questionnaireResults, financialProfessional);
     const dummy = [this._checkboxFilledCode];

     let res = new Map();
     pdfMap.forEach((v, k) => {
       if(dummy.indexOf(v) > -1) {
         let newKey = k.split(this._qaSplitter)[0];
         let newValue = k.split(this._qaSplitter)[1];
         res.set(newKey, newValue);
       } else {
         res.set(k, v);
       }
     })
     return res;
   }

  private _createDefaultFinancialKnowledgeAnswer(key: string,
    data: any,
    proposeCorrectAnswer: boolean) {

    let res = new Map();
    for(const [i, q] of data[key].questions.entries()){
      const a = proposeCorrectAnswer ? data[key].correct_answers[i] : "do_not_know";
      res.set(key + "_" + q + this._qaSplitter + a, this._checkboxFilledCode);
    }
    return res;
  }

  private _createFinancialKnowledgeAnswer(key: string,
    data: any,
    answer: IndoKnowledgeCustomAnswer) {
    
    let res = new Map();
    for(const [i, q] of data[key].questions.entries()){
      const a = answer.answers[i];
      res.set(key + "_" +  q + this._qaSplitter + a, this._checkboxFilledCode);
    }
    return res;
  }

  protected _buildFinancialKnowledgeRecord(
    questionnaireHistory: QuestionnaireHistory,
    proposeCorrectAnswer: boolean) {
    
    let res = new Map();
    for(var key in financialKnowledgeDetailedQuestions){

      let itemInHistory = questionnaireHistory.itemsHistory.find(i =>
        (<IndoKnowledgeCustomQuestion>i.questions[0])
        .questionsAnswersItemKey === key);

      let answerData;
      if (itemInHistory) {
        answerData = this._createFinancialKnowledgeAnswer(key,
          financialKnowledgeDetailedQuestions,
          <IndoKnowledgeCustomAnswer> itemInHistory.answers[0]);
      } else {
        answerData = this._createDefaultFinancialKnowledgeAnswer(key,
          financialKnowledgeDetailedQuestions,
          proposeCorrectAnswer);
      }
      res = new Map([...res, ...answerData]);
    }
    return res
  }

  protected _singleOptionAnswerToKey(
    questionnaireHistory: QuestionnaireHistory,
    answerId: string) : string {
      const answer = <SingleOptionAnswer> 
        questionnaireHistory.FindAnswer(answerId);
      return answerId + this._qaSplitter + answer.optionId
  }

  protected _singleOptionAnswerToKeyWithDefault(
    questionnaireHistory: QuestionnaireHistory,
    answerId: string,
    defaultOptionId: string) : string {
      const answer = <SingleOptionAnswer> 
        questionnaireHistory.FindAnswer(answerId);
      if (answer) {
        return answerId + this._qaSplitter + answer.optionId
      } else {
        return answerId + this._qaSplitter + defaultOptionId
      }
  }

  protected _buildAssetExperienceRecord(questionnaireHistory: QuestionnaireHistory,
                                        singleOptionsAnswerIds: string[],
                                        defaultOptionId: string) {
    const res = new Map();
    singleOptionsAnswerIds.forEach(aid => {
      const key = this._singleOptionAnswerToKeyWithDefault(
                          questionnaireHistory,
                          aid, 
                          defaultOptionId)
      res.set(key, this._checkboxFilledCode);
    });
    return res
  }

  protected _buildSingleOptionRecord(
    questionnaireHistory: QuestionnaireHistory,
    singleOptionsAnswerIds: string[]) {

    const res = new Map();
    singleOptionsAnswerIds.forEach(aid => {
      const key = this._singleOptionAnswerToKey(
                          questionnaireHistory,
                          aid)
      res.set(key, this._checkboxFilledCode);
    });
    return res
  }

  protected _buildPortfolioRecord(
    questionnaireHistory: QuestionnaireHistory) {

      const res = new Map();

      if(this._wealthCompositionAnswerId !== "") {
        const answer = <WealthCompositionIndoAnswer> 
          questionnaireHistory.FindAnswer(this._wealthCompositionAnswerId);

        answer.wealthCompositionItems.forEach(wi => {
          res.set(this._wealthCompositionAnswerId + "_" +
                  wi.id + "_value", " - ");
          res.set(this._wealthCompositionAnswerId + "_" +
                  wi.id + "_percentage", " - ");
          }
        );

        res.set(this._wealthCompositionAnswerId +
                "_total_value", " - ");
        res.set(this._wealthCompositionAnswerId +
                "_total_percentage", " - ");
    
        if (!answer.skipped) {
          
          answer.wealthCompositionItems.forEach(wi => {
          res.set(this._wealthCompositionAnswerId + "_" +
                  wi.id + "_value", wi.value.toString());
          res.set(this._wealthCompositionAnswerId + "_" +
                  wi.id + "_percentage", wi.percentage.toString());
            }
          );

          res.set(this._wealthCompositionAnswerId +
                  "_total_value", answer.evaluateTotalWealth().toString());
          res.set(this._wealthCompositionAnswerId +
                  "_total_percentage", answer.evaluateTotalPercentage().toString());
        }

        res.set(this._wealthCompositionAnswerId + "_as_percentage",
                answer.evaluateTotalPercentage() == 100);
      }

      return res;
    }

  protected _buildAssetListRecord(
    questionnaireHistory: QuestionnaireHistory,
    answerId: string,
    financialProfessional: boolean) {

    const answer = <MultipleOptionAnswer> 
      questionnaireHistory.FindAnswer(answerId);

    var res = new Map();

    if (financialProfessional) { 
      this._assetClasses.forEach(ac => {
        res.set(answerId + "_" + ac + this._qaSplitter + "yes", this._checkboxFilledCode);
      });
    } else {
      this._assetClasses.forEach(ac => {
        let key = 
          answer.optionIds.indexOf(ac) > -1 ? 
          answerId + "_" + ac + this._qaSplitter + "yes" :
          answerId + "_" + ac + this._qaSplitter + "no";
        res.set(key, this._checkboxFilledCode);
      });
    }
    return res;
  }

  protected _buildSingleValueRecord(
    questionnaireHistory: QuestionnaireHistory) {

    const res = new Map();
    const singleValuesToIntervalId = new Map(Object.entries(this._singleValuesToIntervalId));

    singleValuesToIntervalId.forEach((intervals : [string, string][], answerId: string) => {
      const key = answerId + this._qaSplitter +
        this._singleValueAnswerToKey(questionnaireHistory,
                                     answerId,
                                     intervals[answerId])
      res.set(key, this._checkboxFilledCode);
    });
    return res
  }

  protected _singleValueAnswerToKey(
    questionnaireHistory: QuestionnaireHistory,
    answerId: string,
    intervalToIdMapping: {[index: string]: [string, string][]}) {

    const answer = <SingleValueAnswer>
      questionnaireHistory.FindAnswer(answerId);

    return this._findCorrespondingKey(this._singleValuesToIntervalId[answerId], answer.value);
  }

  protected _buildLossAversionRecord(questionnaireResults: QuestionnaireResults) {

    let res = new Map();

    if(this._lossAversionQuestionKey !== "") {
      const lossAversion = questionnaireResults.lossAversion;
      const answerKey = this._findCorrespondingKey(this._lossAversionToStringMapping,
                                                   lossAversion);
      const mapKey = this._lossAversionQuestionKey + this._qaSplitter +
        answerKey;
      res.set(mapKey, this._checkboxFilledCode);
    }
    return res;
  }

  protected _findCorrespondingKey(pairedArrays: [string, string][], value: number) {
    const i = pairedArrays.findIndex(p => this._inInterval(p[0], value));
    return pairedArrays[i][1];
  }

  protected _inInterval(interval: string, value: number) {

    const bounds = interval.slice(1,-1).split('..').map(Number);
    const lowerBound = bounds[0];
    const upperBound = bounds[1];
    let greater: boolean = true;
    let lower: boolean = true;

    if (interval[0] === "[") {
      greater = value >= lowerBound;
    } else {
      greater = value > lowerBound;
    }

    if (interval[1] === "]") {
      lower = value <= upperBound;
    } else {
      lower = value < upperBound;
    }

    return lower && greater;
  }

}
