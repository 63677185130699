import { IOrderedOptionAndTextAnswer } from 'neuroprofiler-ui-kit';
import { MultipleOptionAndTextAnswer } from "./multiple-option-and-text-answer";

export class OrderedOptionAndTextAnswer
  extends MultipleOptionAndTextAnswer
  implements IOrderedOptionAndTextAnswer  {

	public constructor(
		public id: string,
		public kind: string,
    public optionIdsAndText: [string, string][],
    public skipped: boolean){
    super(id, kind, optionIdsAndText, skipped)
  }

}
