export class LossAversionDescription {

  constructor(private _lossAversionValue: number, 
    private _lossAversionQuantile: number) {}

  titleKey() {
    return 'reactions_in_case_of_decrease_in_your_portfolio';
  }

  percentageToPrint() {
    if (this._lossAversionQuantile > 5) {
      return 10 * (10 - this._lossAversionQuantile);
    } else {
      return 10 * this._lossAversionQuantile;
    }
  }

  quantileDescription() {
    if (this._lossAversionQuantile > 5) {
      return 'you_are_part_of_the_n_least_loss_tolerant';
    } else {
      return 'you_are_part_of_the_n_least_most_tolerant';
    }
  }

  details() {
    if (this._lossAversionQuantile > 5) {
      return 'when_the_value_of_your_investment_is_declining_you_prefer_to_wait_for_a_potential_future_increase_rather_than_selling_at_a_loss_you_are_watching_closely_the_evolution_of_your_portfolio';
    } else {
      return 'when_the_value_of_your_investment_is_declining_you_remain_calm_and_are_not_afraid_of_selling_at_a_loss';
    }
  }

}
