import { Component, OnInit, Input } from '@angular/core';
import { IndoKnowledgeCustomQuestion } from "./indo-knowledge-custom-question";

@Component({
  selector: 'app-indo-knowledge-custom-question',
  templateUrl: './indo-knowledge-custom-question.component.html',
  styleUrls: ['./indo-knowledge-custom-question.component.scss']
})
export class IndoKnowledgeCustomQuestionComponent implements OnInit {

  @Input() question: IndoKnowledgeCustomQuestion;
  @Input() answer: any;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  shuffleOptions: (index: number) => Array<string>;

  constructor() { }

  ngOnInit() {
    this.answer.answers = this.question.subQuestions.map(s => "");
    this.shuffleOptions = this.shuffle(this.question.options);
  }

  isActive(index: number, option: string) {
    return this.answer.answers[index] === option;
  }

  onSelectOption(index: number, option: string) {
    this.answer.answers[index] = option;
  }

  private shuffle = (array: Array<any>) => {
    return (index: number) => {
      return array.slice().sort(() => (index * 1.5) - 0.5)
    };
  }
}
