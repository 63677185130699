<button class="btn indo-btn force-color-purple"
  *ngIf="luxtrustEnabled()"
  (click)="terminateSessionBtnClick()">
  {{ 'terminate_session' | translate }}
  <div *ngIf="waiting" class="spinner-border" role="status">
      <span class="sr-only">...</span>
    </div>
</button>

<br>
<!-- *ngIf="!luxtrustEnabled()" -->
<button class="btn indo-btn"
  (click)="downloadBtnClick()">
  {{ 'download_pdf' | translate }}
</button>
