import { Component, HostBinding, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Question } from '../question';
import { QuestionnaireHistory } from '../../questionnaire/questionnaire-history';

import {animate, state, style, transition, trigger} from '@angular/animations';
import {SingleOptionQuestion} from '../single-option-question';
import { RiskQuestion } from '../risk-question';
import { SliderQuestion  } from '../slider-question';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  animations: [
    trigger('enterTrigger', [
      state('fadeIn', style({
        opacity: '1',
      })),
      transition('void => *', [style({opacity: '0'}), animate('500ms')])
    ])
  ]
})

export class QuestionComponent implements OnInit {

  @HostBinding('class') classes = 'w-100 d-block pb-2 pb-md-4 pb-lg-4';
  @Input() question: Question;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: any;
  @Input() questionnaireHistory: QuestionnaireHistory;
  @Input() continueClick: any;
  // [allowedDesigns]="[['companies'],['planes'],['cars'],['literary_description'],['bowl'],['coffers'],['cakes']]"
  public orderedRiskDesigns = [
    ['literary_description'],
    ['companies'],
    ['houses'],
    ['envelopes'],
    ['coffers'],
    ['bowl'],
    ['literary_description']
  ];
  private _whiteBackgroundTitleKeys: any[] = [
    'annual_expenditures_title_key',
    'mp_annual_expenditures_title_key',
    'turnover_total_title_key',
    'discretionary_management_mandate_title_key',
    'mp_self_assessed_level_of_experience_title_key',
    'self_assessed_level_of_experience_title_key'
  ];

  constructor() { }

  ngOnInit() {
  }

  public whiteBackground(question: any) : boolean {
    return this._whiteBackgroundTitleKeys.indexOf(question.titleKey) > -1;
  }
  get singleOptionQuestion() {
    return this.question as SingleOptionQuestion;
  }

  get currentDepth() {
    const question = this.question.kind === 'risk_question' ? <RiskQuestion>this.question : null;
    return question.currentDepth || 0;
  }

	get ratioLegendKey() {
		return this.sliderAttr('ratioLegendKey');
 	}

	get complementLegendKey() {
		return this.sliderAttr('complementLegendKey');
 	}

	private sliderAttr(attribute) {
		const question = this.question.kind === 'slider_question' ? <SliderQuestion>this.question : null;
	 	return question[attribute] || "";
 	}
}
