<div class="my-0 my-md-5 my-lg-5">
  <div class="row">

    <div class="col-sm-5 fixed-height">
      <ui-title
        class="d-block text-center mb-5 pt-2"
        [render]="'title'"
        [size]="'large'"
        [text]="name"
        [color]="colors?.primaryText"
      >
      </ui-title>
    </div>

    <div class="col-sm-1 fixed-height">
      <div *ngIf="imagePath">
        <img class="miniature" [attr.src]="assetsUrl + imagePath">
      </div>
    </div>

    <div class="col-sm-5 fixed-height">
      <ui-text
        class="d-block text-center pt-2"
        [render]="'text'"
        [size]="'medium'"
        [text]="detailsText"
        [color]="colors?.primaryText"
      ></ui-text>
    </div>

    <div class="col-sm-1 fixed-height d-flex justify-content-center align-items-center">
      <div *ngIf="collapsable">
          <button class="btn-help" (click)="collapsed = !collapsed" role="button">
            <div [ngClass]="{rotative: true, rotated: collapsed}">
              +
            </div>
          </button>
      </div>
    </div>

  </div>

  <div *ngIf="collapsable">
    <div *ngIf="collapsed">
      <ng-content></ng-content>
    </div>
  </div>

</div>
