<div
  class="np-text-only-question">
 <div class="row">
   <div class="col-1"></div>
   <div class="col-10 card">
     <div class="row">
       <div class="col-1">
       </div>
       <div class="col-10">
         <div class="mt-3 mb-5">
           <ui-title
             *ngIf="question.titleKey"
             class="text-justify"
             [render]="'head-title'"
             [text]="question.titleKey"
             [color]="colors?.secondaryText">
           </ui-title>
           <ui-text
             *ngIf="question.subtitleKey"
             class="text-justify d-block mb-4"
             [size]="'x-large'"
             [weight]="'semi-bold'"
             [text]="question.subtitleKey"
             [color]="colors?.secondaryText">
           </ui-text>
           <p
             class="text-justify"
             [innerHTML]="question.textKey | translate"
             [ngStyle]="{color: colors?.primaryText }"
           ></p>
         </div>

         <button class="btn indo-btn"
                 [ngStyle]="{backgroundColor: colors?.primaryBackground}"
                 (click)="continueClick()">{{ question.continueKey | translate }}</button>
       </div>
       <div class="col-1">
       </div>
     </div>
   </div>

   <div  class="col-1"></div>
 </div>


</div>
