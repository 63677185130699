import { ISingleValueAnswer } from 'neuroprofiler-ui-kit';

export class SingleValueAnswer implements ISingleValueAnswer {

	public constructor (
		public id: string,
		public kind: string,
		public value: number,
    public skipped: boolean){}

}
