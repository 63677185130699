<div *ngFor="let section of questionsAnswers; let i = index">
  <div *ngFor="let item of section.questionAnswers; let i = index"
    class="text-left"
    >

    <b class="d-block"
       [ngStyle]="{color: colors?.primaryText}"
       [innerHtml]="item.question">
    </b>
    <div *ngFor="let answer of item.answers">
      <p [ngStyle]="{color: colors?.primaryText}">
      <span
       class="d-block"
       [innerHtml]="answer">
      </span>
      </p>
    </div>

  </div>
</div>
