import { IIndoScoreCalculator } from "./i-indo-score-calculator";
import { QuestionnaireHistory } from "../questionnaire/questionnaire-history";
import { RiskQuestion } from "../questions/risk-question";
import { SingleOptionAnswer } from "../answers/single-option-answer";
import { SingleValueAnswer } from "../answers/single-value-answer";
import { WealthCompositionIndoAnswer } from "../answers/wealth-composition-indo-answer";
import { IndoScoreCalculator } from "./indo-score-calculator";

export class IndoScoreCalculatorManda extends IndoScoreCalculator implements IIndoScoreCalculator {

  constructor(private _questionnaireHistory: QuestionnaireHistory){
    super();

  }
  
  public getScoreContributionsAsPairsArray() {
    return [];
  }

  public get lossAversionScoreContribution() {
    return 0;
  }

}
