import { IMultipleOptionAndTextQuestion } from 'neuroprofiler-ui-kit';

import { TextImageOption } from '../options/text-image-option';
import { Answer } from '../answers/answer';
import { MultipleOptionAndTextAnswer } from '../answers/multiple-option-and-text-answer';
import {TranslateService} from '@ngx-translate/core';

export class MultipleOptionAndTextQuestion implements IMultipleOptionAndTextQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public nextItemId: string,
    public minSelectedItems: number,
    public maxSelectedItems: number,
    public canSpecify: boolean[],
    public options: TextImageOption[]
  ) {}


  public getNextQuestionIds(answer: any) {
    return [this.nextItemId];
  }

  public canContinue(answer: any) {
    const castedAnswer = answer as MultipleOptionAndTextAnswer;
    return (castedAnswer.optionIdsAndText.length >= this.minSelectedItems) &&
      (castedAnswer.optionIdsAndText.length <= this.maxSelectedItems);
  }


  public formatQuestionAndAnswer(answer: Answer) {
    let castedAnswer = answer as MultipleOptionAndTextAnswer;
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": castedAnswer.optionIdsAndText.map(x => x[1])
    };
  }
  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    return (answer as MultipleOptionAndTextAnswer).optionIdsAndText.map(x => x[1]);
  }
}
