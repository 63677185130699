import { IMultipleOptionAnswer } from 'neuroprofiler-ui-kit';

export class MultipleOptionAnswer implements IMultipleOptionAnswer {

	public constructor(
		public id: string,
		public kind: string,
    public optionIds: string[], 
    public skipped: boolean){}

}
