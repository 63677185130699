import { QuestionnaireHistory } from "../questionnaire/questionnaire-history";
import { RiskQuestion } from "../questions/risk-question";
import { SessionInfo } from "../session-info";
import { SingleOptionAnswer } from "../answers/single-option-answer";
import { SingleOptionQuestion } from "../questions/single-option-question";
import { TranslateService } from "@ngx-translate/core/lib/translate.service";

export class QuestionnaireResults {


  private _lossAversion: number;
  private _riskAppetite: number;
  private _lossAversionQuantile: number;
  private _riskAppetiteQuantile: number;
  private _annualVolatility: number;
  private _riskyAssetRatio: number;
  private _investmentHorizonTitleKey: string;

  get lossAversion() : number {
    return this._lossAversion;
  }

  get riskAppetite() : number {
    return this._riskAppetite;
  }

  get lossAversionQuantile() : number {
    return this._lossAversionQuantile;
  }

  get riskAppetiteQuantile() : number {
    return this._riskAppetiteQuantile;
  }

  get annualVolatility() : number {
    return this._annualVolatility;
  }

  get riskProfileKey(): string {
    if (this.annualVolatility < 0.02) {
      return 'very_conservatif';
    } else if (this.annualVolatility < 0.05) {
      return 'conservatif';
    } else if (this.annualVolatility < 0.10) {
      return 'moderate';
    } else if (this.annualVolatility < 0.15) {
      return 'dynamic';
    } else {
      return 'very_dynamic';
    }
  }

  get riskyAssetRatio() : number {
    return this._riskyAssetRatio;
  }

  get investmentHorizonTitleKey() : string {
    return this._investmentHorizonTitleKey;
  }

  public constructor(public questionnaireHistory : QuestionnaireHistory) {
    if (questionnaireHistory.CanFindAnswer("investment_horizon")) {
      this._buildRiskProfile(questionnaireHistory);
    }
  }

  private _buildRiskProfile(questionnaireHistory: QuestionnaireHistory) {

    let investmentDescriptionItem =
      this.questionnaireHistory.FindQuestionnaireItemById("investment_horizon");
    let investmentHorizonQuestion =
      <SingleOptionQuestion> (investmentDescriptionItem.questions[0]);
    let investmentHorizonAnswer =
      <SingleOptionAnswer>investmentDescriptionItem.answers[0];
    let investmentHorizonOption = 
      investmentHorizonQuestion.getOptionForAnswer(investmentHorizonAnswer);
    let investmentHorizonTitleKey = investmentHorizonOption.titleKey;

    const titleToDurationMap = new Map();

    titleToDurationMap.set("1year","0.5");
    titleToDurationMap.set("1_3years","1.");
    titleToDurationMap.set("3_5years","3.");
    titleToDurationMap.set("5_years_or_more","5.");

    let lastRiskQuestionAnswer =
      this.questionnaireHistory.FindQuestionAnswerWithNextId("risk_finished");

    let riskQuestion = <RiskQuestion>lastRiskQuestionAnswer[0];
    let riskAnswer = <SingleOptionAnswer>lastRiskQuestionAnswer[1];
    let chosenOption = riskQuestion.getOptionForAnswer(riskAnswer);

    let investorProfileParameters =
      chosenOption.attributes.find(
        att => att[0] === "investor_profile_Parameters")[1];

    let investorProfileAnnualVolatilities = 
      chosenOption.attributes.find(
        att => att[0] === "investor_profile_AssetAllocationAnnualVolatility")[1];

    this._annualVolatility = 
      Math.round(
      investorProfileAnnualVolatilities.find(
        av => av[0] === titleToDurationMap.get(investmentHorizonTitleKey)
      )[1] * 1000 ) / 1000;

    let investorProfileRiskyAssetRatios = 
      chosenOption.attributes.find(
        att => att[0] === "investor_profile_AssetAllocation")[1];

    this._riskyAssetRatio =
      Math.round(
      investorProfileRiskyAssetRatios.find(
        av => av[0] === titleToDurationMap.get(investmentHorizonTitleKey) + "_Equity"
      )[1] * 1000 ) / 1000;

    this._lossAversion = 
      Math.round(investorProfileParameters.find(p => p[0] === "LossAversion_Value")[1] * 1000) / 1000;

    this._riskAppetite = 
      Math.round(investorProfileParameters.find(p => p[0] === "RiskAppetite_Value")[1] * 1000) / 1000;
  
    this._lossAversionQuantile = 
      Math.round(investorProfileParameters.find(p => p[0] === "LossAversion_Quantile")[1] * 10) / 10;

    this._riskAppetiteQuantile = 
      Math.round(investorProfileParameters.find(p => p[0] === "RiskAppetite_Quantile")[1] * 10) / 10;
  

  }

  public detailedQuestionsAndAnswers() {
    return this.questionnaireHistory.FlattenedQuestionAnswer().map(qa =>
      qa[0].formatQuestionAndAnswer(qa[1]));
  }

  public toJSON() {
    return {
      "clientId": SessionInfo.clientId,
      "investmentHorizon": this._investmentHorizonTitleKey,
      "lossAversion": this._lossAversion, 
      "winningAppetite": this._riskAppetite, 
      "lossAversionQuantile": this._lossAversionQuantile, 
      "winningAppetiteQuantile": this._riskAppetiteQuantile, 
      "safeAssetProportionInAllocation": 1 - this._riskyAssetRatio,
      "idealVolatility": this._annualVolatility, 
      "lossAversionDetails": "TODO",
      "winningAppetiteDetails":  "TODO",
      "history": this.detailedQuestionsAndAnswers()
    }
  }

}
