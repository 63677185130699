<div class="w-100 d-flex flex-column justify-content-center align-items-center">
  <ul class="bullets-list w-100 d-flex justify-content-between">
    <li class="bullet past" *ngFor="let pastBullet of arrayOfBullets(pastBulletsNumber)"></li>
    <li class="bullet current"></li>
    <li class="bullet" *ngFor="let futureBullet of arrayOfBullets(futureBulletsNumber)"></li>
  </ul>
  <div>
    <p class="light-color-font" [ngStyle]="{color: colors?.secondaryText}">{{sectionName}} ({{currentPercentage()}}%)</p>
  </div>
</div>
