<div *ngIf="whichCESO !== 'manda'" class="np-page container-fluid" [class.with-background]="false">
  <header>
    <nav class="navbar np-navbar">
      <a class="navbar-brand" href="#">
        <img
                              class='np-logo'
                              src="{{ assetsUrl }}/logo.jpg">
      </a>
    </nav>
  </header>
  <div class="row d-flex justify-content-center">
    <div class="col-11 card p-3">
      <app-client-score
         [title]="'investor_profile' | translate"
         [subtitle]="'this_profile_relates_to_investment_at_indosuez' | translate"
         [riskProfile]="indoScoreCalculator.riskProfile | translate"
         [riskScore]="indoScoreCalculator.riskScore"
         [riskTitle]="indoScoreCalculator.riskTitle | translate"
         [riskText]="indoScoreCalculator.riskText | translate"
         [riskDetails]="indoScoreCalculator.riskDetails | translate"
         [questionsAnswers]="indoScoreCalculator.getScoreContributionsAsPairsArray()"
         [colors]="colors"
         ></app-client-score>

        <app-button-to-modal [buttonKey]="'see_different_profiles'">

          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-8">
              <br>
              <h2>{{ 'risk_profiles' | translate }}</h2>	
              <br>
              <div *ngFor="let riskTitle of indoScoreCalculator.riskTitles; let i = index">
                <hr>
                <p>{{indoScoreCalculator.riskProfiles[i] | translate | uppercase}}</p>
                <p><b>{{riskTitle | translate }}</b></p>
                <p>{{indoScoreCalculator.riskTexts[i][0] | translate }}</p>
                <p>{{indoScoreCalculator.riskTexts[i][1] | translate }}</p>
                <br>
              </div>

            </div>
            <div class="col-md-2"></div>

          </div>


        </app-button-to-modal>

        <app-download-pdf
                 [indoScoreCalculator]="indoScoreCalculator"
                 [questionsAnswersData]="questionAnswerAndSectionsData"
                 [questionnaireResults]="questionnaireResults"
                 [configuration]="configuration">
        </app-download-pdf>

        <hr class="bighr">

        <app-expandable-summary-with-icon
            [name]="financialObjectiveDescription.questionTitleKey"
            [imagePath]="'/library/icons/' + financialObjectiveDescription.chosenOptionPictureFilepath.replace('white','black')"
            [detailsText]="financialObjectiveDescription.chosenOptionTitleKey"
            [collapsable]="false"
            [configuration]="configuration"
            [colors]="colors"
            >
        </app-expandable-summary-with-icon>

            <app-expandable-summary-with-icon
            [name]="'your_answers'"
            [imagePath]="'/library/icons/collapse_pictograms/question-mark-black.svg'"
            [detailsText]="''"
            [collapsable]="true"
            [configuration]="configuration"
            [colors]="colors"
            >
            <app-question-answer-history
            [questionsAnswers]="questionAnswerAndSectionsData"
            [configuration]="configuration"
            [colors]="colors"
            ></app-question-answer-history>
            </app-expandable-summary-with-icon>
    </div>
  </div>
</div>


<div *ngIf="whichCESO === 'manda'" class="np-page container-fluid" [class.with-background]="false">

  <header>
    <nav class="navbar np-navbar">
      <a class="navbar-brand" href="#">
        <img
                              class='np-logo'
                              src="{{ assetsUrl }}/logo.jpg">
      </a>
    </nav>
  </header>
  <div class="row d-flex justify-content-center">
    <div class="col-11 card p-3">
    
      <ui-title
      class="d-block text-center mb-5"
      [render]="'title'"
      [size]="'large'"
      [text]="'manda_results_title' | translate"
      [color]="colors?.primaryText"
    >
    </ui-title>


  <app-download-pdf
           [indoScoreCalculator]="indoScoreCalculator"
           [questionsAnswersData]="questionAnswerAndSectionsData"
           [questionnaireResults]="questionnaireResults">
  </app-download-pdf>

  <hr class="bighr">

  <app-expandable-summary-with-icon
                              [name]="selfAssessedFinancialKnowledgeDescription.questionTitleKey"
                              [imagePath]="'/library/icons/' + selfAssessedFinancialKnowledgeDescription.chosenOptionPictureFilepath"
                              [detailsText]="selfAssessedFinancialKnowledgeDescription.chosenOptionTitleKey"
                              [collapsable]="false"
                              [configuration]="configuration"
                              [colors]="colors"
                              >
  </app-expandable-summary-with-icon>


    <app-expandable-summary-with-icon
                              [name]="selfAssessedFinancialExperienceDescription.questionTitleKey"
                              [imagePath]="'/library/icons/' + selfAssessedFinancialExperienceDescription.chosenOptionPictureFilepath"
                              [detailsText]="selfAssessedFinancialExperienceDescription.chosenOptionTitleKey"
                              [collapsable]="false"
                              [configuration]="configuration"
                              [colors]="colors"
                              >
    </app-expandable-summary-with-icon>

      <app-expandable-summary-with-icon
                              [name]="frequencyMarketReviewsDescription.questionTitleKey"
                              [imagePath]="'/library/icons/' + frequencyMarketReviewsDescription.chosenOptionPictureFilepath"
                              [detailsText]="frequencyMarketReviewsDescription.chosenOptionTitleKey"
                              [collapsable]="false"
                              [configuration]="configuration"
                              [colors]="colors"
                              >
      </app-expandable-summary-with-icon>

        <app-expandable-summary-with-icon
                              [name]="'your_answers'"
                              [imagePath]="'/library/icons/collapse_pictograms/question-mark-black.svg'"
                              [detailsText]="''"
                              [collapsable]="true"
                              [configuration]="configuration"
                              [colors]="colors"
                              >
                              <app-question-answer-history
                              [questionsAnswers]="questionAnswerAndSectionsData"
                              [configuration]="configuration"
                              [colors]="colors"
                              ></app-question-answer-history>
        </app-expandable-summary-with-icon>
</div>
</div>
</div>
