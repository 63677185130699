import { IBinaryGamble } from 'neuroprofiler-ui-kit';

export class BinaryGamble implements IBinaryGamble {

	constructor(public x: number,
		public px : number,
		public y : number,
		public py: number){}

  public Scale(factor : number) {
    this.x = this.x * factor;
    this.y = this.y * factor;
  }

	public static FromJson(inputJson: any){
		return new BinaryGamble(
			inputJson.x,
			inputJson.px,
			inputJson.y,
			inputJson.py)
	}

}
