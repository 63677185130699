import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { Question } from '../question';
import { QuestionnaireHistory } from "../../questionnaire/questionnaire-history";
import { ModalService } from 'neuroprofiler-ui-kit';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() titleKey: string;
  @Input() questions: Question[];
  @Input() answers: any[];
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() questionnaireHistory: QuestionnaireHistory;
  @Input() continueClick: any;
  constructor(private _ms: ModalService) { }

  ngOnInit() {
  }

  openModal() {
    const content = new Map();
    content.set('titleKey', 'modal_title');
    content.set('textKey', 'modal_content');
    content.set('nextKey', 'next');
    content.set('action', () => {}); // FIXME
    this._ms.open(content);
  }

}
