import { ISliderQuestion } from 'neuroprofiler-ui-kit';
import { Answer } from "../answers/answer";
import { SingleValueAnswer } from "../answers/single-value-answer";
import {TranslateService} from '@ngx-translate/core';

export class SliderQuestion implements ISliderQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public ratioLegendKey: string,
    public complementLegendKey: string,
    public nextItemId: string,
    public referenceAmount: number | string
  ) {}

  public getNextQuestionIds(answer: any) {
    return [this.nextItemId];
  }

  public canContinue(answer: any) {
    // should introduce min & max constraints here
    return true;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": (answer as SingleValueAnswer).value
    };
  }
  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    return [(answer as SingleValueAnswer).value + ' %'];
  }
}
