import { ISingleOptionQuestion, Layout } from 'neuroprofiler-ui-kit';
import { TextImageOption } from '../options/text-image-option';
import { Answer } from '../answers/answer';
import {TranslateService} from '@ngx-translate/core';

export class SingleOptionQuestion implements ISingleOptionQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public layout: Layout,
    public options: TextImageOption[]
  ) {}

  public getNextQuestionIds(answer: any) {
    const res = this.options.find(o => o.optionId === answer.optionId).nextItemId;
    if (res !== '') {
      return [res];
    } else {
      return [];
    }
  }

  public getOptionForAnswer(answer: any) {
    return this.options.find(o => o.optionId === answer.optionId);
  }

  public canContinue(answer: any) {
    return (this.options.map(o => o.optionId).indexOf(answer.optionId) > -1);
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": this.getOptionForAnswer(answer).titleKey
    };
  }
  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    return [this.getOptionForAnswer(answer).titleKey];
  }
}
