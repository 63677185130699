<ng-container *ngIf="titleKey">
  <ui-title
    class="text-center d-block mb-5"
    [render]="'subtitle'"
    [text]="titleKey"
    [color]="colors.secondaryText">
  </ui-title>
</ng-container>

<!-- FIXME -->
<ng-container *ngIf="false">
  <ui-button
    class="d-block text-center"
    [text]="'modal_open'"
    [auxiliary]="true"
    [colors]="colors"
    (action)="openModal()">
  </ui-button>
</ng-container>

<ng-container *ngFor="let question of questions; let i=index">
  <app-question
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answers[i]"
    [continueClick]="continueClick"
    [questionnaireHistory]="questionnaireHistory">
  </app-question>
</ng-container>
