<div class="np-knowledge-question">
  <ui-title
    class="d-block text-center mb-4"
    [text]="question.titleKey"
    [color]="colors?.primaryText">
  </ui-title>

  <ui-text
    class="d-block text-center mb-4"
    *ngIf="question.subtitleKey"
    [text]="question.subtitleKey"
    [color]="colors?.primaryText">
  </ui-text>
</div>
<br>

<div class="row">
    <div *ngFor="let option of question.options; let i = index"
         class="col-12 col-md-6 mb-4">

        <div class="card brown-bkg d-flex align-items-center mx-md-5 p-2 clickable"
             [class.active]="isActive(option)"
             (click)="onSelectOption(option)">
            <div style="overflow: hidden;">
                <p class="m-0 text-center"> {{ option.titleKey | translate }} </p>
            </div>
        </div>

    </div>
</div>
