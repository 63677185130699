import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
	selector: 'app-button-to-modal',
	templateUrl: './button-to-modal.component.html',
	styleUrls: ['./button-to-modal.component.scss']
})
export class ButtonToModalComponent implements OnInit {

	@Input() buttonKey: string;
  @Input() asUrl: boolean = false;
	@ViewChild(Component, {static:true}) helperInput: any;
	modalRef: BsModalRef;
	constructor(private modalService: BsModalService) {}

	openModal(template: any) {
		this.modalRef = this.modalService.show(template);
	}

	ngOnInit() {
	}

}
