import { IndoWealthCompositionItem } from "../questions/indosuez-components/indo-wealth-composition/indo-wealth-composition-item";

export class WealthCompositionIndoAnswer {

  constructor(
		public id: string,
		public kind: string,
    public percentageDeclarationOnly: boolean,
    public wealthCompositionItems: IndoWealthCompositionItem[],
    public skipped: boolean){} 

  public resetValuesAndPercentages() {
    for (const wealthCompositionItem of this.wealthCompositionItems) {
      wealthCompositionItem.percentage = 0;
      wealthCompositionItem.value = 0;
    }
  }

  public evaluateTotalWealth() {
    if(this.percentageDeclarationOnly) {
      return 0;
    } else {
      let sum = 0;
      for (const wealthCompositionItem of this.wealthCompositionItems) {
        sum = sum + wealthCompositionItem.value;
      }
        return sum;
    }
  }

  public evaluateTotalPercentage() {
    let sum = 0;
    for (const wealthCompositionItem of this.wealthCompositionItems) {
      sum = sum + wealthCompositionItem.percentage;
    }
    return sum;
  }

  public evaluateTotalFinancialWealth() {
    if(this.percentageDeclarationOnly) {
      return -1;
    } else {
      let sum = 0;
      for (const wealthCompositionItem of this.wealthCompositionItems) {
        if(wealthCompositionItem.isFinancial) {
          sum = sum + wealthCompositionItem.value;
        }
      }
      return sum;
    }
  }

  public evaluateFinancialWealthPercentage() {
    if(this.percentageDeclarationOnly) {
      let sum = 0;
      for (const wealthCompositionItem of this.wealthCompositionItems) {
        if(wealthCompositionItem.isFinancial) {
          sum = sum + wealthCompositionItem.percentage;
        }
      }
      return sum;
    } else {
      if (this.evaluateTotalWealth() === 0) {
        return -1;
      }
      else {
        return 100 * this.evaluateTotalFinancialWealth() / this.evaluateTotalWealth();
      }
    }

  }

}
