import { Component, OnInit, Input, HostBinding } from '@angular/core';

import { ISingleOptionQuestion } from '../../types/questions/single-option-question';
import { ISingleOptionAnswer } from '../../types/answers/single-option-answer';

@Component({
  selector: 'app-indo-single-option-question',
  templateUrl: './indo-single-option-question.component.html',
  styleUrls: ['./indo-single-option-question.component.scss'],
})
export class IndoSingleOptionQuestionComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly question: ISingleOptionQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: ISingleOptionAnswer;
  @Input() readonly brownBackground: boolean = true;
  @Input() showOptionTitle = true;
  @Input() continueClick: any;
  constructor() {}

  ngOnInit() {}
}
