import { Answer } from '../answers/answer';
import { QuestionnaireHelperMP } from "./questionnaire-helper-mp";
import { QuestionnaireHistory } from './questionnaire-history';
import { QuestionnaireItem } from './questionnaire-item';
import { SessionInfo } from "../session-info";

export class Questionnaire {

  public currentItem: QuestionnaireItem;
  public pendingItemsIds: Array<string>;
  public isTerminated: boolean;

  public constructor(private questionnaireJson) {
    this.currentItem = QuestionnaireItem.Factory(this.questionnaireJson[0], new QuestionnaireHistory() );
    this.pendingItemsIds = new Array()
    this.isTerminated = false;
  }

  public nextItem(answers : any[], history: QuestionnaireHistory) {
    if (!this.canContinue(answers)) { return; }

    this.pendingItemsIds = this.pendingItemsIds.concat(this.currentItem.questions[0].getNextQuestionIds(answers[0]));
    this._skipFinancialKnowledgeIfFinanceProfessional(history);

    const nextItemId = this.pendingItemsIds.shift();
    const currentItemJson = this.questionnaireJson.find(item => item.questionnaire_item_id == nextItemId);

    if (currentItemJson) {
      this.currentItem = QuestionnaireItem.Factory(currentItemJson, history);
    } else {
      this.isTerminated = true;
    }
  }

  public canContinue(answers) : boolean  {
    const res = this.currentItem.questions.every((q, i) => {
      return q.canContinue(answers[i])
    });
    return res;
  }

  // Indosuez specificity...
  private _skipFinancialKnowledgeIfFinanceProfessional(history: QuestionnaireHistory) {
    let idToSkip = "financial_assets_knowledge";
    if (SessionInfo.country === 'ch') {
      idToSkip = "financial_assets_experience";
    }
    if (QuestionnaireHelperMP.isFinancialProfessional(history)){
      this.pendingItemsIds = this.pendingItemsIds.map(id => id === idToSkip ? "knowledge_finished" : id)
    }
  }

}
