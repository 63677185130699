import { WealthCompositionIndoAnswer } from '../answers/wealth-composition-indo-answer';
import {Answer} from '../answers/answer';
import {TranslateService} from '@ngx-translate/core';
import {NpCurrencyPipe} from 'neuroprofiler-ui-kit';
import {MultipleValuesAnswer} from '../answers/multiple-values-answer';

export class WealthCompositionIndoQuestion {

	constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public nextItemIdIfValues: number,
    public nextItemIdIfPercentages: number) {}

  public getNextQuestionIds(answer: any) {
    const castedAnswer = answer as WealthCompositionIndoAnswer;
    if (castedAnswer.percentageDeclarationOnly) {
      return [this.nextItemIdIfPercentages];
    } else {
      return [this.nextItemIdIfValues];
    }
	}

	public canContinue(answer: any) {
    if (answer.skipped) { return true; }

    const castedAnswer = answer as WealthCompositionIndoAnswer;
    if (castedAnswer.percentageDeclarationOnly) {
      return castedAnswer.evaluateTotalPercentage() === 100;
    } else {
      return castedAnswer.evaluateTotalWealth() > 0;
    }
	}

  public formatQuestionAndAnswer(answer: any) {
    const castedAnswer = answer as WealthCompositionIndoAnswer;
    return {
      kind: this.kind,
      question: this.titleKey,
      declarationInPercentage: castedAnswer.percentageDeclarationOnly,
      wealthCompositionItems: castedAnswer.wealthCompositionItems
    };
  }

  public answerToString(answer: Answer, configuration, translate: TranslateService) : string[] {
    const castedAnswer = answer as WealthCompositionIndoAnswer;

    if (castedAnswer.skipped) {
      return [translate.instant('did_not_reply')]; 
    } else {
      if (castedAnswer.percentageDeclarationOnly) {
        const formattedAnswer = castedAnswer.wealthCompositionItems.map((n, i) => [n.labelKey, n.percentage]);
        return formattedAnswer.map((KeyAndValue) => {
          let answersAsString = translate.instant(KeyAndValue[0] +  '') + ' : ';
          const amount = KeyAndValue[1] + '%'
          answersAsString += amount;
          return answersAsString;
        });

      } else {
        const formattedAnswer = castedAnswer.wealthCompositionItems.map((n, i) => [n.labelKey, n.value]);
        return formattedAnswer.map((KeyAndValue) => {
          let answersAsString = translate.instant(KeyAndValue[0] +  '') + ' : ';
          const amount = new NpCurrencyPipe().transform(
            (KeyAndValue[1] || 0) as number,
            configuration, 
            {
              textual: true
            }
          );
          answersAsString += amount;
          return answersAsString;
        });
      }
    }
  }

}

