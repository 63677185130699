import { Answer } from "./answer";

export class AnswerPrefiller {

  prefills: Map<string, Answer>;

  public constructor() {
    this.prefills = new Map();
  }

  public Add(answer: Answer) {
    this.prefills.set(answer.id, answer);
  }

  public UpdateAnswerIfPossible(answer: Answer) {
    if(this.prefills.has(answer.id)) {
      return this.prefills.get(answer.id);
    } else {
      return answer;
    }
  }
}
