import { Component, OnInit, Input, HostBinding} from '@angular/core';
import { QuestionnaireResults } from "../questionnaire-results";
import { LossAversionDescription } from "../loss-aversion-description";
import { WinningAppetiteDescription } from "../winning-appetite-description";

@Component({
  selector: 'app-client-results-summary',
  templateUrl: './client-results-summary.component.html',
  styleUrls: ['./client-results-summary.component.scss']
})
export class ClientResultsSummaryComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() questionnaireResults: QuestionnaireResults;
  lossAversionDescription : LossAversionDescription;
  winningAppetiteDescription: WinningAppetiteDescription;

  constructor() { }

  ngOnInit() {
    this.lossAversionDescription = new LossAversionDescription(
      this.questionnaireResults.lossAversion,
      this.questionnaireResults.lossAversionQuantile);

    this.winningAppetiteDescription = new WinningAppetiteDescription(
      this.questionnaireResults.riskAppetite,
      this.questionnaireResults.riskAppetiteQuantile);

  }

  preparePost() {
   return this.questionnaireResults.toJSON();
  }


}
