export class IndoScoreCalculator {

  public _riskProfiles : string[] = ['revenu', 'careful', 'balanced', 'growth', 'dynamic']

  public _riskTitles : string[] = [ "capital_conservation",
    "capital_preservation",
    "regular_capital_growth",
    "steady_capital_growth",
    "important_capital_growth"]

  public _riskTexts : string[][]= [
      ['seeking_capital_preservation_description', 'capital_at_low_risk'],
      ['seeking_limited_return_description', 'capital_at_limited_risk'],
      ['seeking_capital_mid_term_growth_description', 'capital_at_moderate_risk'],
      ['seeking_capital_mid_long_term_growth_description', 'capital_at_high_risk'],
      ['seeking_capital_long_term_growth_description', 'capital_at_very_high_risk']];

  public _riskScore: number;
  public _riskProfile: string;
  public _riskTitle : string;
  public _riskText: string;
  public _riskDetails: string;

  constructor(){}

  public get riskTitles() {
    return this._riskTitles;
  }

  public get riskTexts() {
    return this._riskTexts;
  }

  public get riskProfiles() {
    return this._riskProfiles;
  }

  public get riskProfilesMatrix() {
    let res = [];
    res[0] = ["column_investor_profile", "column_objective", "column_description", "column_capital_at_risk"];
    for(var i = 0; i < this._riskTitles.length; i++) {
      res[i+1] = [this._riskProfiles[i], this._riskTitles[i], 
                this._riskTexts[i][0], this._riskTexts[i][1]];
    }
    return res;
  }

  public get riskScore() {
    return this._riskScore;
  }

  public get riskProfile() {
    return this._riskProfile;
  }

  public get riskTitle() {
    return this._riskTitle;
  }

  public get riskText() {
    return this._riskText;
  }

	public get riskDetails() {
		return this._riskDetails;
  }

  static valueToBucketIndex(thresholds, value) {
    let index = 0;
    for (const threshold of thresholds) {
      if (value < threshold) {
        return index;
      } else {
        index += 1;
      }
    }
    return index;
  }


}
