import { IIndoKnowledgeCustomAnswer } from '../questions/types/answers/indo-knowledge-custom-answer';

export class IndoKnowledgeCustomAnswer implements IIndoKnowledgeCustomAnswer {
	public constructor(
    public id: string,
    public kind: string,
    public question_id: string,
    public answers: Array<string>,
    public skipped: boolean){}
}
