<div class="row d-flex flex-sm-column flex-lg-row justify-content-center align-items-center">

    <div class="col-sm-10 col-lg-6 card mb-4 mx-3 pt-3">

      <!--
      <div class="d-flex justify-content-center align-items-center mb-4">
        <input type="checkbox"
               class="mr-2"
               [ngStyle]="{color: colors?.primaryText}"
               [(ngModel)]="answer.percentageDeclarationOnly"
               (ngModelChange)="changeMode()"/>
        <span [ngStyle]="{color: colors?.primaryText}">
          {{ "i_prefer_to_submit_percentages_only" | translate }}
        </span>

      </div>
      -->

      <div class="w-100" *ngFor="let eltLocal of answer.wealthCompositionItems; let i = index">

        <hr *ngIf="i>0">

          <app-indo-wealth-composition-item
            [colors]="colors"
            [configuration]="configuration"
            [(item)]="answer.wealthCompositionItems[i]"
            (itemChange)="wealthChange($event, i)"
            [total]="evaluateTotalWealth()"
            [percentageDeclarationOnly]="answer.percentageDeclarationOnly">
          </app-indo-wealth-composition-item>

      </div>
      <hr>
      <div class="row" *ngIf="!answer.percentageDeclarationOnly">
        <div class="col-sm-7">
          <b [ngStyle]="{color: colors?.primaryText}">{{ 'total_wealth' | translate}}</b>
        </div>
        <div class="col-sm-3"
             [ngStyle]="{color: currencyColor()}"
        >
          {{evaluateTotalWealth() | npCurrency:mockTemplateTest }}
        </div>
        <div class="col-sm-2"></div>
      </div>

      <div class="row" *ngIf="answer.percentageDeclarationOnly">
        <div class="col-sm-7">
          <b [ngStyle]="{color: colors?.primaryText}">{{ 'total_wealth' | translate}}</b>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-2"
             [ngStyle]="{'color': percentageColor()}"
        >
          {{evaluateTotalPercentage() / 100 | percent }}
        </div>
      </div>

      <br>

    </div>


    <div class="col-10 col-lg-5 mb-sm-5 d-flex flex-column justify-content-center">

      <div class="pie_chart_container">
        <div class="np-pie-chart">
          <canvas width="100%" height="100%" #pieChart>
            {{ chart }}
          </canvas>
        </div>
      </div>

      <div *ngIf="showLegend()" class="legends">
        <ul>
          <li *ngFor="let  label of labels(); let i = index"
              class="d-flex align-items-center"
          >
            <div class="ml-2 mb-2"><span class="legend mr-2"[ngStyle]="{backgroundColor: chartColors[i]}"></span>{{label | translate}}</div>
          </li>
        </ul>
      </div>

    </div>
</div>
