import {IOptionalValuesQuestion, NpCurrencyPipe} from 'neuroprofiler-ui-kit';
import { Answer } from "../answers/answer";
import { MultipleValuesAnswer } from "../answers/multiple-values-answer";
import {TranslateService} from '@ngx-translate/core';

export class OptionalValuesQuestion implements IOptionalValuesQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public nextItemId: string,
    public names: string[],
    public titleKeys: string[],
    public values: number[],
    public placeholderValue: number,
    public minValue: number,
    public maxValue: number
  ) {}

  public getNextQuestionIds(answer: any) {
    return [this.nextItemId];
  }

  public canContinue(answer: any) {
    return true;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    const castedAnswer = answer as MultipleValuesAnswer;
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": castedAnswer.titleKeys.map((n, i) => [n, castedAnswer.values[i]])
    };
  }

  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    const formattedAnswer = this.formatQuestionAndAnswer(answer);
    return formattedAnswer.answers.map((KeyAndValue) => {
      let answersAsString = translate.instant(KeyAndValue[0] as string) + ' : ';
      let amount;
      if (KeyAndValue[1] === null) {
        amount = 'none';
      } else {
        amount = new NpCurrencyPipe().transform(
          KeyAndValue[1] as number,
          configuration,
          //          {
          //            lang: configuration.get('locale'),
          //            currency: configuration.get('currency-symbol'),
          //            currency_code: configuration.get('currency')
          //          },
          {
            textual: true
          }
        );
      }
      answersAsString += amount;
      return KeyAndValue[1] !== null ? answersAsString : '';
    });
  }
}
