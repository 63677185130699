import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ViewEncapsulation, ViewChild, ElementRef,
} from '@angular/core';
import { formatPercent } from '@angular/common';

import { Options } from '@angular-slider/ngx-slider';

import { ISliderQuestion } from '../../types/questions/slider-question';

import { ISingleValueAnswer } from '../../types/answers/single-value-answer';

import { ParseIntPipe } from '../../pipes/parse-int.pipe';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-indo-slider-question',
  templateUrl: './indo-slider-question.component.html',
  styleUrls: ['./indo-slider-question.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IndoSliderQuestionComponent implements OnInit {
  @HostBinding('class') classes = 'w-100';
  @Input() readonly question: ISliderQuestion;
  @Input() readonly configuration: Map<string, string>;
  @Input() readonly colors: any;
  @Input() answer: ISingleValueAnswer;
  @Input() totalLegend: string;
  @Input() valueLegend: string;
  @Input() showInput: false;
  @ViewChild('pieChart', { static: true }) private chartRef: ElementRef;

  options: Options;
  chart: any;
  constructor() {}

  ngOnInit() {
    this.options = {
      step: 1,
      floor: 0,
      ceil: 100,
      showSelectionBar: true,
      hideLimitLabels: true,
      animateOnMove: true,
      getPointerColor: (_value: number): string => {
        return this.colors.reverseText;
      },
      getSelectionBarColor: (_value: number): string => {
        return this.colors.primaryBackground;
      },
      translate: (value: number): string => {
        return [
          '<b class=\'current-percentage\'>',
          formatPercent(value / 100, this.locale, '1.0-0'),
          '</b>',
        ].join('');
      },
    };

    this.load(this.datas, this.colors);
  }

  get locale() {
    return this.configuration.get('locale');
  }

  get mockTemplateTest() {
    return {
      lang: this.locale,
      currency: this.configuration.get('currency-symbol'),
      currency_code: this.configuration.get('currency'),
    };
  }

  get inputValue() {
    return ((this.referenceAmount * this.answer.value) / 100) + '';
  }

  changeInputValue(e: Event) {
    const value  = this.parseInt((e.target as HTMLInputElement).value);
    this.answer.value = (value *  100) / this.referenceAmount;
  }

  get referenceAmount() {
    const referenceAmount = this.question.referenceAmount;
    const isNumber = (typeof referenceAmount === 'number');
    return isNumber ? referenceAmount : this.parseInt(referenceAmount as string);
  }

  get whiteLabelStyles() {
    return {
      '.np-slider-question .current-percentage': {
        'color': this.colors.primaryText + ' !important'
      },
      '.np-slider-question .slider-container .ngx-slider .ngx-slider-full-bar .ngx-slider-bar': {
        'background-color': this.colors.primaryBackground
      }
    };
  }

  private parseInt(value: string) {
    return new ParseIntPipe().transform(value);
  }

  get datas() {
    return [this.answer.value, 100 - this.answer.value];
  }

  private load(data: Array<number>, colors: any) {
    this.buildPieChart(data, this.colorsValues(colors));
  }

  colorsValues(colors: any) {
    // const keys = [
    //   'primaryBackground',
    //   'secondaryBackground',
    //   'primaryText',
    //   'secondaryText'
    // ];
    // return Object
    //   .keys(colors)
    //   .filter(el => keys.indexOf(el) !== -1)
    //   .map((colorName) => {
    //     return colors[colorName];
    //   });
    return ['rgb(96, 74, 99)', 'rgb(161, 0, 78)'];
  }


  updateChart() {
    if (this.chart) {
      this.chart.data.datasets[0].data = this.datas;
      this.chart.update();
    }
  }

  get labels() {
    return [this.valueLegend, this.totalLegend]
  }

  private buildPieChart(data: Array<number>, colors: any) {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            data,
            borderColor: '#FFF',
            backgroundColor: colors,
            fill: false,
          },
        ],
        labels: this.labels,
      },
      options: {
        maintainAspectRatio: false,
        elements: {
          arc: { borderWidth: 2 },
        },
        title: { display: false },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItems, d) => {
              return d.labels[tooltipItems.index] + ': ' + Math.round(d.datasets[0].data[tooltipItems.index] as number) + ' %';
            }
          },
          enabled: true,
        },
        scales: {
          xAxes: [{ display: false }],
          yAxes: [{ display: false }],
        },
      },
    });
  }
}
