import { Answer } from '../answers/answer';
import {TranslateService} from '@ngx-translate/core';

export class TextOnlyQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public defaultPictureAlias: string,
    public activePictureAlias: string,
    public helperKey: string,
    public textKey: string,
    public continueKey: string,
    public nextItemId: string
  ) {}

  public getNextQuestionIds(answer: any) {
    return [this.nextItemId];
  }

  public canContinue(answer: any) {
    return true;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      kind: this.kind,
      question: this.titleKey,
      answers: this.continueKey
    };
  }

  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    return [this.continueKey];
  }

}
