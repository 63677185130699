import { ProgressBar } from '../ui/progress-bar/progress-bar';

// questions
import { Question } from '../questions/question';
import { IntermediateProgressionQuestion } from '../questions/intermediate-progression-question';
import { KnowledgeQuestion } from '../questions/knowledge-question';
import { MultipleOptionAndTextQuestion } from '../questions/multiple-option-and-text-question';
import { MultipleOptionQuestion } from '../questions/multiple-option-question';
import { MultipleValuesQuestion } from '../questions/multiple-values-question';
import { OptionalValuesQuestion } from '../questions/optional-values-question';
import { OrderedOptionAndTextQuestion } from '../questions/ordered-option-and-text-question';
import { SingleOptionQuestion } from '../questions/single-option-question';
import { SingleValueQuestion } from '../questions/single-value-question';
import { SliderQuestion } from '../questions/slider-question';
import { TextOnlyQuestion } from '../questions/text-only-question';

import { RiskQuestion } from "../questions/risk-question";

// answers
import { Answer } from '../answers/answer';
import { KnowledgeAnswer } from '../answers/knowledge-answer';
import { MultipleOptionAndTextAnswer } from '../answers/multiple-option-and-text-answer';
import { MultipleOptionAnswer } from '../answers/multiple-option-answer';
import { MultipleValuesAnswer } from '../answers/multiple-values-answer';
import { OrderedOptionAndTextAnswer } from '../answers/ordered-option-and-text-answer';

import { SingleOptionAnswer } from '../answers/single-option-answer';
import { SingleValueAnswer } from '../answers/single-value-answer';

// options
import { RiskOption } from '../options/risk-option';
import { TextImageOption } from '../options/text-image-option';

// TODO remove ??
// import { QuestionnaireSectionInfo } from './questionnaire-section-info';
import { QuestionnaireHistory } from "./questionnaire-history";
import progressBarConfigs from './progressBarConfig.json';
import financialKnowledgeDetailedQuestions from '../../assets/static/questionnaire/financial_knowledge_detailed_questions.json'
import financialKnowledgeDetailedQuestionsCFM from '../../assets/static/questionnaire/financial_knowledge_detailed_questions_cfm.json'
import { SessionInfo } from "../session-info";
import { WealthCompositionIndoAnswer } from "../answers/wealth-composition-indo-answer";
import { IndoWealthCompositionItem } from "../questions/indosuez-components/indo-wealth-composition/indo-wealth-composition-item";
import { WealthCompositionIndoQuestion } from "../questions/wealth-composition-indo-question";

import { IndoKnowledgeCustomQuestion } from "../questions/indosuez-components/indo-knowledge-custom-question/indo-knowledge-custom-question";
import { IndoKnowledgeCustomAnswer } from "../answers/indo-knowledge-custom-answer";

export class QuestionnaireItem {

	public constructor(
		public questionnaireItemId: string,
		public titleKey: string,
		public progressBar: ProgressBar,
		public questions: Question[],
		public answers: Answer[]){}


	public static AnswerFactory(inputJson: any) : Answer {
    let answer: Answer = null;
    const answerKind = inputJson.kind;

    if (QuestionnaireItem.isSingleOptionAnswer(answerKind)) {
      answer = QuestionnaireItem.toSingleOptionAnswer(inputJson);
    } else if (QuestionnaireItem.isKnowledgeAnswer(answerKind)) {
      answer = QuestionnaireItem.toKnowledgeAnswer(inputJson);
    } else if (QuestionnaireItem.isSingleValueAnswer(answerKind)) {
      answer = QuestionnaireItem.toSingleValueAnswer(inputJson);
    } else if (QuestionnaireItem.isMultipleOptionAnswer(answerKind)) {
      answer = QuestionnaireItem.toMultipleOptionAnswer(inputJson);
    } else if (QuestionnaireItem.isMultipleValuesAnswer(answerKind)) {
      answer = QuestionnaireItem.toMultipleValuesAnswer(inputJson);
    } else if (QuestionnaireItem.isMultipleOptionAndTextAnswer(answerKind)) {
      answer = QuestionnaireItem.toMultipleOptionAndTextAnswer(inputJson);
    } else if (QuestionnaireItem.isOrderedOptionAndTextAnswer(answerKind)) {
      answer = QuestionnaireItem.toOrderedOptionAndTextAnswer(inputJson);
    } else if (QuestionnaireItem.isWealthCompositionIndoAnswer(answerKind)) {
      answer = QuestionnaireItem.toWealthCompositionIndoAnswer(inputJson);
    } else if (QuestionnaireItem.isIndoKnowledgeCustomAnswer(answerKind)) {
      answer = QuestionnaireItem.toIndoKnowledgeCustomAnswer(inputJson);
    }
    return Object.seal(answer);
	}

	private static QuestionFactory(inputJson: any, questionnaireHistory : QuestionnaireHistory) : Question {
		let questionKind = inputJson.kind;
    let question: Question;

    if (QuestionnaireItem.isTextOnlyQuestion(questionKind)) {
      question = QuestionnaireItem.toTextOnlyQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isSingleOptionQuestion(questionKind)) {
      question = QuestionnaireItem.toSingleOptionQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isKnowledgeQuestion(questionKind)) {
      question = QuestionnaireItem.toKnowledgeQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isSingleValueQuestion(questionKind)) {
      question = QuestionnaireItem.toSingleValueQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isMultipleOptionQuestion(questionKind)) {
      question = QuestionnaireItem.toMultipleOptionQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isMultipleValuesQuestion(questionKind)) {
      question = QuestionnaireItem.toMultipleValuesQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isSliderQuestion(questionKind)) {
      question = QuestionnaireItem.toSliderQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isIntermediateProgressionQuestion(questionKind)) {
      question = QuestionnaireItem.toIntermediateProgressionQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isOrderedOptionAndTextQuestion(questionKind)) {
      question = QuestionnaireItem.toOrderedOptionAndTextQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isOptionalValuesQuestion(questionKind)) {
      question = QuestionnaireItem.toOptionalValuesQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isMultipleOptionAndTextQuestion(questionKind)) {
      question = QuestionnaireItem.toMultipleOptionAndTextQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isRiskQuestion(questionKind)) {
      question = QuestionnaireItem.toRiskQuestion(
        inputJson, questionnaireHistory
      );
    } else if (QuestionnaireItem.isWealthCompositionIndoQuestion(questionKind)) {
      question = QuestionnaireItem.toWealthCompositionIndoQuestion(
        inputJson
      );
    } else if (QuestionnaireItem.isIndoKnowledgeCustomQuestion(questionKind)) {
      question = QuestionnaireItem.toIndoKnowledgeCustomQuestion(
        inputJson
      );
    }
    return Object.freeze(question);
	}

	public static Factory(inputJson: any, questionnaireHistory : QuestionnaireHistory) {
    let progressBarConfig = null;
    if (SessionInfo.country === "ch") {
      progressBarConfig = progressBarConfigs["ch"][SessionInfo.cesoType];
    } else {
      progressBarConfig = progressBarConfigs["other"][SessionInfo.cesoType];
    }
		let progressBar = new ProgressBar(
      progressBarConfig.section_key,
      progressBarConfig.section_key_index,
      progressBarConfig.percentage_in_section,
      progressBarConfig.section_keys,
      progressBarConfig.inactives_pictures,
      progressBarConfig.active_pictures,
      progressBarConfig.completed_pictures,
      progressBarConfig.text_keys,
      progressBarConfig.max_n_questions
    );
    
    progressBar.Update(inputJson.section_info.section_key,
      inputJson.section_info.section_position,
      inputJson.section_info.remaining_items
    );

		let res = new QuestionnaireItem(
			inputJson.questionnaire_item_id,
			inputJson.title_key,
			progressBar,
			inputJson.questions.map((json: any) => {
        return QuestionnaireItem.QuestionFactory(json, questionnaireHistory)
      }),
			inputJson.answers.map(QuestionnaireItem.AnswerFactory)
    );
    return res;
	}

  /* START - "text-only-question" */
  private static isTextOnlyQuestion(type: string) {
    return type === 'text_only_question';
  }

  private static toTextOnlyQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    // https://neuroprofiler.slack.com/archives/C0151LCD2F7/p1596550248034500?thread_ts=1596549702.031300&cid=C0151LCD2F7
    return new TextOnlyQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.default_picture_alias,
      question.active_picture_alias,
      question.helper_key,
      question.text_key,
      question.continue_key,
      question.next_item_id
    );
  }
  /* END - "text-only-question" */

  /* START - 'single-option-question' */
  private static isSingleOptionQuestion(type: string) {
    return type.startsWith('single_option_question');
  }

  private static toSingleOptionQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    let options = question.options;
    return new SingleOptionQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.layout,
      options.map(TextImageOption.FromJson)
    );
  }
  /* END - 'single-option-question' */

  /* START - 'knowledge-question' */
  private static isKnowledgeQuestion(type: string) {
    return type === 'knowledge_question';
  }

  private static toKnowledgeQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    const [last, ...others] = this.reverse(question.options);
    return new KnowledgeQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      this.shuffle(
        this.reverse(others).map(TextImageOption.FromJson)
      ).concat([TextImageOption.FromJson(last)])
    );
  }
  private static reverse(array: Array<any>) { return array.slice().reverse(); }
  /* END - 'knowledge-question' */

  /* START - 'single-value-question' */
  private static isSingleValueQuestion(type: string) {
    return type === 'single_value_question';
  }

  private static toSingleValueQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    return new SingleValueQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.next_item_id,
      question.placeholder_value,
      question.min_value,
      question.max_value
    );
  }
  /* END - 'single-value-question' */

  /* START - 'multiple-option-question' */
  private static isMultipleOptionQuestion(type: string) {
    return type === 'multiple_option_question';
  }

  private static toMultipleOptionQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    return new MultipleOptionQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.next_item_id,
      question.min_selected_items,
      question.max_selected_items,
      question.options.map(TextImageOption.FromJson)
    );
  }
  /* END - 'multiple-option-question' */

  /* START - 'multiple-values-question' */
  private static isMultipleValuesQuestion(type: string) {
    return type === 'multiple_values_question';
  }

  private static toMultipleValuesQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    return new MultipleValuesQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.default_picture_alias,
      question.active_picture_alias,
      question.helper_key,
      question.next_item_id,
      question.placeholder_value,
      question.min_value,
      question.max_value
    );
  }
  /* END - 'multiple-values-question' */

  /* START - 'slider-question' */
  private static isSliderQuestion(type: string) {
    return type === 'slider_question';
  }

  private static toSliderQuestion(question: any, questionnaireHistory : QuestionnaireHistory) {
    return new SliderQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.ratio_legend_key,
      question.complement_legend_key,
      question.next_item_id,
      questionnaireHistory.FindValueForAnswer(question.reference_amount)
    );
  }
  /* END - 'slider-question' */

  /* START - 'intermediate-progression-question' */
  private static isIntermediateProgressionQuestion(type: string) {
    return type === 'intermediate_progression_question';
  }

  private static toIntermediateProgressionQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    return new IntermediateProgressionQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.active_option_id,
      question.next_item_id,
      question.options.map(TextImageOption.FromJson)
    );
  }
  /* END - 'intermediate-progression-question' */

  /* START - 'ordered-option-and-text-question' */
  private static isOrderedOptionAndTextQuestion(type: string) {
    return type === 'ordered_option_and_text_question';
  }

  private static toOrderedOptionAndTextQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    return new OrderedOptionAndTextQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.next_item_id,
      question.min_selected_items,
      question.max_selected_items,
      question.can_specify,
      question.options.map(TextImageOption.FromJson)
    );
  }
  /* END - 'ordered-option-and-text-question' */

  /* START - 'optional-values-question' */
  private static isOptionalValuesQuestion(type: string) {
    return type === 'optional_values_question';
  }

  private static toOptionalValuesQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    return new OptionalValuesQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.next_item_id,
      question.names,
      question.title_keys,
      question.values,
      question.placeholder_value,
      question.min_value,
      question.max_value,
    );
  }
  /* END - 'optional-values-question' */

  /* START - 'multiple-option-and-text-question' */
  private static isMultipleOptionAndTextQuestion(type: string) {
    return type === 'multiple_option_and_text_question';
  }

  private static toMultipleOptionAndTextQuestion(question: any, _questionnaireHistory : QuestionnaireHistory) {
    return new MultipleOptionAndTextQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.next_item_id,
      question.min_selected_items,
      question.max_selected_items,
      question.can_specify,
      question.options.map(TextImageOption.FromJson)
    );
  }
  /* END - 'multiple-option-and-text-question' */

  /* START - 'risk_question' */
  private static isRiskQuestion(type: string) {
    return type === 'risk_question';
  }

  private static toRiskQuestion(question: any, questionnaireHistory : QuestionnaireHistory) {
    const trustedAmount = questionnaireHistory.FindValueForAnswer("trusted_amount_answer");
    return new RiskQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.current_depth,
      question.max_depth,
      question.options.map(ro => RiskOption.FromJsonAndScale(ro, trustedAmount / 100))
    );
  }
  /* END - 'risk_question' */

  /* START - 'wealth_composition_indo_question' */
  private static isWealthCompositionIndoQuestion(type: string) {
    return type === 'wealth_composition_indo_question';
  }

  private static toWealthCompositionIndoQuestion(question: any) {
    return new WealthCompositionIndoQuestion(
      question.kind,
      question.title,
      question.subtitle,
      question.helper,
      question.next_item_id_if_values,
      question.next_item_id_if_percentages);
  }
  /* END - 'risk_question' */

  /* START - "indo-knowledge-custom-question" */
  private static isIndoKnowledgeCustomQuestion(type: string) {
    return type === 'indo_knowledge_question';
  }

  private static toIndoKnowledgeCustomQuestion(question: any) {

    let detailedQuestions;
    if (['ch'].indexOf(SessionInfo.country) > -1) {
      detailedQuestions = financialKnowledgeDetailedQuestionsCFM;
    } else {
      detailedQuestions = financialKnowledgeDetailedQuestions;
    }

    return new IndoKnowledgeCustomQuestion(
      question.kind,
      question.title_key,
      question.subtitle_key,
      question.helper_key,
      question.questions_answers_item_id,
      detailedQuestions[question.questions_answers_item_id],
      question.correct_option,
      question.wrong_option,
      ['true', 'false', 'do_not_know']
    );
  }
  /* END - "indo-knowledge-custom-question" */


  /* START - "single_option_answer" */
  private static isSingleOptionAnswer(type: string) {
    return type === 'single_option_answer';
  }

  private static toSingleOptionAnswer(answer: any) {
    return new SingleOptionAnswer(answer.id, answer.kind, answer.option_id, false);
  }
  /* END - "single_option_answer" */

  /* START - "knowledge_answer" */
  private static isKnowledgeAnswer(type: string) {
    return type === 'knowledge_answer';
  }

  private static toKnowledgeAnswer(answer: any) {
    return new KnowledgeAnswer(
      answer.id,
      answer.kind,
      answer.option_id,
      answer.confidence_level,
      false
    );
  }
  /* END - "knowledge_answer" */

  /* START - "single_value_answer" */
  private static isSingleValueAnswer(type: string) {
    return type === 'single_value_answer';
  }

  private static toSingleValueAnswer(answer: any) {
    return new SingleValueAnswer(answer.id, answer.kind, answer.value, false);
  }
  /* END - "single_value_answer" */

  /* START - "multiple_option_answer" */
  private static isMultipleOptionAnswer(type: string) {
    return type === 'multiple_option_answer';
  }

  private static toMultipleOptionAnswer(answer: any) {
    return new MultipleOptionAnswer(answer.id, answer.kind, answer.option_ids.slice(), false);
  }
  /* END - "multiple_option_answer" */

  /* START - 'multiple_values_answer' */
  private static isMultipleValuesAnswer(type: string) {
    return type === 'multiple_values_answer';
  }

  private static toMultipleValuesAnswer(answer: any) {
    return new MultipleValuesAnswer(
      answer.id,
      answer.kind,
      answer.names.slice(),
      answer.title_keys.slice(),
      answer.values.slice(),
      false
    );
  }
  /* END - 'multiple_values_answer' */

  /* START - 'multiple_option_and_text_answer' */
  private static isMultipleOptionAndTextAnswer(type: string) {
    return type === 'multiple_option_and_text_answer';
  }

  private static toMultipleOptionAndTextAnswer(answer: any) {
    return new MultipleOptionAndTextAnswer(
      answer.id,
      answer.kind,
      answer.option_ids_and_text.slice(),
      false
    );
  }
  /* END - 'multiple_option_and_text_answer' */

  /* START - 'ordered_option_and_text_answer' */
  private static isOrderedOptionAndTextAnswer(type: string) {
    return type === 'ordered_option_and_text_answer';
  }

  private static toOrderedOptionAndTextAnswer(answer: any) {
    return new OrderedOptionAndTextAnswer(
      answer.id,
      answer.kind,
      answer.option_ids_and_text,
      false
    );
  }
  /* END - 'ordered_option_and_text_answer' */

  /* START - 'wealth_composition_indo_answer' */
  private static isWealthCompositionIndoAnswer(type: string) {
    return type === "wealth_composition_indo_answer";
  }

  private static toWealthCompositionIndoAnswer(answer: any) {
    // console.log(answer);
    const wealthCompositionItems =
      answer.wealth_composition_items.map(IndoWealthCompositionItem.FromJSON);
    return new WealthCompositionIndoAnswer(
      answer.id,
      answer.kind,
      answer.percentage_declaration_only,
      wealthCompositionItems,
      false)
  }
  /* END */

  /* START - 'wealth_composition_indo_answer' */
  private static isIndoKnowledgeCustomAnswer(type: string) {
    return type === "indo_knowledge_answer";
  }

  private static toIndoKnowledgeCustomAnswer(answer: any) {
    return new IndoKnowledgeCustomAnswer(
      answer.id,
      answer.kind,
      answer.question_id,
      answer.answers,
      false
    );
  }
  /* END */


  private static shuffle(array: Array<any>) {
    return array.sort(() => Math.random() - 0.5)
  };
}
