import { QuestionnaireHistory } from "./questionnaire-history";
import { SingleOptionAnswer } from "../answers/single-option-answer";

export class QuestionnaireHelperMP {

  public static suggestFinancialProfessional(questionnaireHistory: QuestionnaireHistory) {

    if(questionnaireHistory.CanFindAnswer('balance_sheet_total') &&
       questionnaireHistory.CanFindAnswer('capital_total') &&
       questionnaireHistory.CanFindAnswer('turnover_total') ){

      const balanceSheetTotalAnswer = <SingleOptionAnswer>questionnaireHistory.FindAnswer('balance_sheet_total');
      const capitalTotalAnswer = <SingleOptionAnswer>questionnaireHistory.FindAnswer('capital_total');
      const turnoverTotalAnswer = <SingleOptionAnswer>questionnaireHistory.FindAnswer('turnover_total');

      const conditions = new Array();
      conditions.push(balanceSheetTotalAnswer.optionId === "more_than_20m");
      conditions.push(capitalTotalAnswer.optionId === "more_than_2m");
      conditions.push(turnoverTotalAnswer.optionId === "more_than_40m");

      const conditionsAsIntegers = conditions.map(c => c ? 1 : 0).reduce((sum, current) => sum + current, 0);
      return conditionsAsIntegers > 1;
    } else {
      return false;
    }
  }

  public static isFinancialProfessional(questionnaireHistory: QuestionnaireHistory) {

    const answerId = 'categorized_as_professional_client';

    if(questionnaireHistory.CanFindAnswer(answerId)){
      const categorizedAsProfessionalClientAnswer = 
        <SingleOptionAnswer> questionnaireHistory.FindAnswer(answerId);
      return categorizedAsProfessionalClientAnswer.optionId === "yes";
    } else {
      return false;
    }
  }

}
