import { Answer } from "../answers/answer";
import {IMultipleValuesQuestion, NpCurrencyPipe} from 'neuroprofiler-ui-kit';
import { MultipleValuesAnswer } from "../answers/multiple-values-answer";
import {TranslateService} from '@ngx-translate/core';

export class MultipleValuesQuestion implements IMultipleValuesQuestion {


  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public defaultPictureAlias: string,
    public activePictureAlias: string,
    public helperKey: string,
    public nextItemId: string,
    public placeholderValue: number,
    public minValue: number,
    public maxValue: number
  ) {}

  public getNextQuestionIds(answer: any) {
    return [this.nextItemId];
  }

  public canContinue(answer: any) {
    // should introduce min & max constraints here
    return true;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    const castedAnswer = answer as MultipleValuesAnswer;
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": castedAnswer.titleKeys.map((n, i) => [n, castedAnswer.values[i]])
    };
  }

  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    const formattedAnswer = this.formatQuestionAndAnswer(answer);
    return formattedAnswer.answers.map((KeyAndValue) => {
      let answersAsString = translate.instant(KeyAndValue[0] + '_title') + ' : ';
      const amount = new NpCurrencyPipe().transform(
        (KeyAndValue[1] || 0) as number,
        configuration, 
        //{
        //  lang: configuration.get('locale'),
        //  currency: configuration.get('currency-symbol'),
        //  currency_code: configuration.get('currency')
        //},
        {
          textual: true
        }
      );
      // console.log(amount);
      answersAsString += amount;
      return answersAsString;
    });
  }
}
