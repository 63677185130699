import { IMultipleOptionAndTextAnswer } from 'neuroprofiler-ui-kit';

export class MultipleOptionAndTextAnswer implements IMultipleOptionAndTextAnswer {

	public constructor(
		public id: string,
		public kind: string,
    public optionIdsAndText: [string, string][],
    public skipped: boolean){}

  public OptionIds() {
    return this.optionIdsAndText.map(ot => ot[0]);
  }

}
