<div class="d-flex">
  <div class="w-100 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center align-items-md-start"
       *ngIf="!percentageDeclarationOnly">
      <div class="w-50">
          <p class="label">
              <b [ngStyle]="{color: colors?.primaryText}">{{ item.labelKey | translate }} </b>
              <span [ngStyle]="{color: colors?.primaryText}" [innerHTML]="item.detailKey | translate "> </span>
          </p>
      </div>

      <div class="w-30">
          <p [ngStyle]="{color: colors?.primaryText}">
            <input
             type="text"
             maxlength=15
             size=15
             class="wealth_input"
             [ngStyle]="{color: colors?.primaryText}"
             (input)="itemValueInputValidatorCurrency($event)"
             [ngModel]="intToCurrencyString(item.value)"
             (ngModelChange)="changeItem($event)">
          </p>
      </div>

      <div class="w-20">
          <p [ngStyle]="{color: colors?.primaryText}">
              {{ percentageFromValue() | percent : '1.0' }}
          </p>
      </div>

  </div>

  <div class="w-100 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center align-items-md-start"
       *ngIf="percentageDeclarationOnly">
      <div class="w-50">
          <p class="label">
          <b [ngStyle]="{color: colors?.primaryText}">{{ item.labelKey | translate  }}</b>
          <span [ngStyle]="{color: colors?.primaryText}"
                [innerHTML]="item.detailKey | translate">
          </span>
          </p>
      </div>

      <div class="w-50 d-flex justify-content-center justify-content-md-end">
          <p [ngStyle]="{color: colors?.primaryText}">
            <input
             type="text"
             maxlength=3
             size=3
             class="wealth_input percent"
             [ngStyle]="{color: colors?.primaryText}"
             (input)="itemValueInputValidatorPercentage($event)"
             [ngModel]="percentageToString(item.percentage)"
             (ngModelChange)="changeItem($event, 'percentage')">%
          </p>
      </div>

  </div>
</div>
