import {ISingleValueQuestion, NpCurrencyPipe} from 'neuroprofiler-ui-kit';
import { Answer } from "../answers/answer";
import { SingleValueAnswer } from "../answers/single-value-answer";
import {TranslateService} from '@ngx-translate/core';

export class SingleValueQuestion implements ISingleValueQuestion {

  public constructor(
    public kind: string,
    public titleKey: string,
    public subtitleKey: string,
    public helperKey: string,
    public nextItemId: string,
    public placeholderValue: number,
    public minValue: number,
    public maxValue: number
  ) {}

  public getNextQuestionIds(answer: any) {
    return [this.nextItemId];
  }

  public canContinue(answer: any) {
    const castedAnswer = answer as SingleValueAnswer;
    return castedAnswer.value >= this.minValue && castedAnswer.value <= this.maxValue;
  }

  public formatQuestionAndAnswer(answer: Answer) {
    return {
      "kind": this.kind,
      "question": this.titleKey,
      "answers": (answer as SingleValueAnswer).value
    };
  }

  public answerToString(answer: Answer, configuration, translate: TranslateService) {
    const val = (answer as SingleValueAnswer).value;
    const amount = new NpCurrencyPipe().transform(
      val as number,
      configuration,
      //      {
      //        lang: configuration.get('locale'),
      //        currency: configuration.get('currency-symbol'),
      //        currency_code: configuration.get('currency')
      //      },
      {
        textual: true
      }
    );
    return [amount];
  }
}
