<ng-container [ngSwitch]="question.layout">
  <!-- layout: 'square' -->
  <app-indo-single-option-question-square
    *ngSwitchCase="'square'"
    [question]="question"
    [colors]="colors"
    [brownBackground]="brownBackground"
    [continueClick]="continueClick"
    [configuration]="configuration"
    [showOptionTitle]="showOptionTitle"
    [(answer)]="answer">
  </app-indo-single-option-question-square>

  <!-- layout: 'rounded' -->
  <app-indo-single-option-question-rounded
    *ngSwitchCase="'rounded'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </app-indo-single-option-question-rounded>

  <!-- layout: 'select' -->
  <app-indo-single-option-question-select
    *ngSwitchCase="'select'"
    [question]="question"
    [configuration]="configuration"
    [colors]="colors"
    [(answer)]="answer">
  </app-indo-single-option-question-select>
</ng-container>
